import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Popconfirm, Table, Upload } from "antd";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { token } from "../../../utils/authentication";
import { useTranslation } from "react-i18next";

const _init = {
  name: "",
  slug: "",
  image: false,
  description: "",
  logo: false,
  sortOrder: "",
};

const CourseCategory = () => {
  const {t} = useTranslation();
  const [categoryData, setCategoryData] = useState(_init);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const baseImageUrl = `${process.env.REACT_APP_ASSETS_FRONTEND_URL}/`; // Your base URL
  const [loader, setLoader] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/course-category/list-for-dropdown`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setData(response.data.courseCategorys);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: t("sl_no"),
      dataIndex: "sortOrder",
      key: "sortOrder",
      width: 100,
      sorter: (a, b) => a.sortOrder - b.sortOrder,
      defaultSortOrder: "ascend",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${record.logo}`}
            alt={record.name}
            style={{ maxWidth: "50px", maxHeight: "50px", marginRight: "10px" }}
          />
          {text}
        </div>
      ),
    },
    {
      title: t("slug"),
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: t("image"),
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <img
          src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${text}`}
          alt={record.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      ),
    },
    {
      title: t("action"),
      key: "action",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{
              backgroundColor: "#C6A34F",
              color: "#fff",
              textAlign: "center",
            }}
            onClick={() => handleEdit(record)}
          >
            {t("edit")}
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this course category?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button
              type="danger"
              style={{
                backgroundColor: "#E23821",
                marginLeft: "10px",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {t("delete")}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    setIsEditing(true);
    setEditRecord(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/course-category/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then(() => {
        // Handle success
        console.log("Deleted Course Category with ID:", id);

        // Refresh the data to remove the deleted course category
        fetchData();
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting Course Category:", error);
      });
  };

  const handleCreateClick = () => {
    setIsModalVisible(true);
  };

  const handleSave = async (values, action) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("slug", values.slug);
    formData.append("description", values.description);
    formData.append("sortOrder", values.sortOrder);
    if (selectedLogo) {
      formData.append("logo", selectedLogo);
    }
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    if (isEditing) {
      formData.append("id", values._id);
      await axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/course-category/update`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then(async (res) => {
          await setIsEditing(false);
          await fetchData();
          await setSelectedImage(false);
          await setSelectedLogo(false);
          await action.resetForm();
          await setLoader(false);
          await setIsModalVisible(false);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/course-category/create`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then(async (res) => {
          await setIsEditing(false);
          await fetchData();
          await setSelectedImage(false);
          await setSelectedLogo(false);
          await action.resetForm();
          await handleModalCancel();
          await setLoader(false);
          await setIsModalVisible(false);
        });
    }
    await setLoader(false);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("upload")}
      </div>
    </div>
  );

  // Validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    slug: Yup.string().required("slug is required"),
    sortOrder: Yup.number()
      .min(1, "minimum value 1")
      .required("sortOrder is required"),
    description: Yup.string().required("description is required"),
    logo: Yup.mixed().test("required", "logo is required", function (value) {
      const hasId = Boolean(this.parent._id); // Check if _id is present
      return hasId || (hasId === false && !!value); // Require 'logo' if no _id
    }),
    image: Yup.mixed().test("required", "image is required", function (value) {
      const hasId = Boolean(this.parent._id); // Check if _id is present
      return hasId || (hasId === false && !!value); // Require 'image' if no _id
    }),
  });

  const handleModalCancel = () => {
    setIsEditing(false);
    setEditRecord({});
    setCategoryData(_init);
    setSelectedImage(false);
    setSelectedLogo(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (editRecord && Object.keys(editRecord).length > 0 && isEditing) {
      setCategoryData(editRecord);
    } else {
      setCategoryData(_init);
    }
  }, [editRecord, isEditing]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        <Button
          style={{
            backgroundColor: "#C6A34F",
          }}
          type="primary"
          onClick={handleCreateClick}
        >
          {t("create_course_category")}
        </Button>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="_id"
      />
      <Modal
        title={isEditing ? t("update_course_category") : t("create_course_category")}
        visible={isModalVisible}
        // onOk={false}
        footer={null}
        onCancel={handleModalCancel}
      >
        <Formik
          initialValues={categoryData}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, action) => {
            handleSave(values, action);
          }}
        >
          {({ values, setFieldValue, errors, touched, handleSubmit }) => (
            <Form>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("name")} : </label>
                    </div>
                    <div className="col-9">
                      <Input
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("slug")} :</label>
                    </div>
                    <div className="col-9">
                      <Input
                        onChange={(e) => {
                          setFieldValue("slug", e.target.value);
                        }}
                        value={values.slug}
                      />
                      {errors.slug && touched.slug && (
                        <div className="text-danger">{errors.slug}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("sort_order")}: </label>
                    </div>
                    <div className="col-9">
                      <Input
                        onChange={(e) => {
                          setFieldValue("sortOrder", e.target.value);
                        }}
                        type="number"
                        value={values.sortOrder}
                      />
                      {errors.sortOrder && touched.sortOrder && (
                        <div className="text-danger">{errors.sortOrder}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("description")} : </label>
                    </div>
                    <div className="col-9">
                      <Input.TextArea
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        value={values.description}
                      />
                      {errors.description && touched.description && (
                        <div className="text-danger">{errors.description}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("logo")}</label>
                    </div>
                    <div className="col-9">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={(e) => {
                          setSelectedLogo(e.file.originFileObj);
                          setFieldValue("logo", e.file.originFileObj);
                        }}
                      >
                        {selectedLogo ? (
                          <img
                            src={URL.createObjectURL(selectedLogo)}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : values.logo ? (
                          <img
                            src={`${baseImageUrl}${values.logo}`}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      {errors.logo && touched.logo && (
                        <div className="text-danger">{errors.logo}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("image")}</label>
                    </div>
                    <div className="col-9">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={(e) => {
                          setSelectedImage(e.file.originFileObj);
                          setFieldValue("image", e.file.originFileObj);
                        }}
                      >
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : values.image ? (
                          <img
                            src={`${baseImageUrl}${values.image}`}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      {errors.image && touched.image && (
                        <div className="text-danger">{errors.image}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Add more fields as needed */}
              <div className="modal-footer border-0 px-0 mx-0">
                <Button
                  type="primary"
                  htmlType="button"
                  disabled={loader}
                  onClick={handleSubmit}
                >
                  {isEditing ? t("update") : t("submit")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default CourseCategory;
