import { Checkbox, DatePicker, Input, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { decryptData, encryptData } from "../../../utils/secure-stoarage";
import { Form, Formik } from "formik";
import moment from "moment";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const _init = {
  // courseId: "",
  // courseTitle: "",
  name: "",
  email: "",
  phone: "",
  // amount: "",
  // transactionId: "",
  // month: "",
  dob: "",
  nationality: "",
  address: "",
  postcode: "",
  emmergencyNumber: "",
  gender: "",
  currentlyWorking: undefined,
  experienceInHospitality: undefined,
  workPermitInUK: undefined,
  criminalRecord: undefined,
  courseInterest: undefined,
};

const StudentForm = () => {
  const [courseInfo, setCourseInfo] = useState();
  const [nationalities, setNationalities] = useState([]);
  const navigate = useNavigate();

  function generateTimestampBased6DigitNumber() {
    const timestamp = new Date().getTime();
    const uniqueNumber = timestamp % 1000000;
    return uniqueNumber;
  }

  const getNationalities = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commons/nationality`)
      .then((res) => {
        if (res.data.statusCode == 200) {
          const nationality = res.data?.nationalities;
          const data = nationality?.map((item) => {
            return {
              label: item?.nationality,
              value: item?.nationality,
            };
          });

          setNationalities(data);
        }
      });
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("email is required"),
    phone: Yup.string().required("phone is required"),
    dob: Yup.string().required("dob is required"),
    nationality: Yup.string().required("nationality is required"),
    address: Yup.string().required("address is required"),
    postcode: Yup.string().required("postcode is required"),
    emmergencyNumber: Yup.string().required("emmergency number is required"),
    gender: Yup.string().required("gender is required"),
    currentlyWorking: Yup.boolean().required("required field"),
    experienceInHospitality: Yup.boolean().required("required field"),
    workPermitInUK: Yup.boolean().required("required field"),
    criminalRecord: Yup.boolean().required("required field"),
    courseInterest: Yup.boolean().required("required field"),
  });

  const getDiscountValueFromTotal = (total, type, value) => {
    let amount = 0;
    if (type == "amount") {
      amount = Math.round(Number(total) - Number(value));
    } else {
      amount = Math.round(total - (total * value) / 100);
    }

    return amount;
  };

  const handleRegister = async (values) => {
    // if (
    //   Object.keys(values).some((key) => typeof key == "boolean" && !values[key])
    // ) {
    //   toast.error("Please fill in all required fields.");
    //   return;
    // }

    values.courseId = courseInfo.courseId;
    values.courseTitle = courseInfo.courseTitle;
    values.month = courseInfo.month;
    values.transactionId = `TRX_${generateTimestampBased6DigitNumber()}`;
    values.couponCode = courseInfo.couponCode ?? "";
    values.amount = courseInfo?.amount;
    values.amountType = courseInfo?.amountType;
    values.discountAmount = courseInfo?.coupon ? courseInfo?.coupon?.amount : 0;
    values.discountType = courseInfo?.coupon
      ? courseInfo?.coupon?.amountType
      : undefined;
    values.couponId = courseInfo?.coupon ? courseInfo?.coupon?._id : null;
    const total = courseInfo?.coupon
      ? getDiscountValueFromTotal(
          courseInfo.amount,
          courseInfo?.coupon?.amountType,
          courseInfo?.coupon?.amount
        )
      : courseInfo?.amount;

    if (values.amountType == "FIXED") {
      if (values.amount > 0) {
        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/course-payment/create-session`,
            {
              courseTitle: values.courseTitle,
              amount: total,
              email: values.email,
              image: courseInfo?.image,
            }
          )
          .then((res) => {
            if (res.data.statusCode == 201) {
              localStorage.removeItem("enrollData");
              localStorage.setItem("regData", encryptData(values));
              const url = res.data?.details?.url;
              window.location.href = url;
            }
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/course-payment/create`,
            values
          )
          .then(async (res) => {
            await localStorage.removeItem("regData");
            await localStorage.removeItem("enrollData");
            await navigate("/course/payment-success");
          })
          .catch(async (error) => {
            await localStorage.removeItem("regData");
            await localStorage.removeItem("enrollData");
            await navigate("/course/payment-error");
          });
      }
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/course-payment/create`,
          values
        )
        .then(async (res) => {
          await localStorage.removeItem("regData");
          await localStorage.removeItem("enrollData");
          await navigate("/course/payment-success");
        })
        .catch(async (error) => {
          await localStorage.removeItem("regData");
          await localStorage.removeItem("enrollData");
          await navigate("/course/payment-error");
        });
    }
  };

  useEffect(() => {
    getNationalities();
    const data = localStorage.getItem("enrollData");
    if (data) {
      const payload = decryptData(data);
      setCourseInfo(payload);
    } else {
      setCourseInfo();
    }
  }, []);

  return (
    <div className="container">
      <div className="card pb-5 pt-3 my-5">
        <Formik
          initialValues={_init}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleRegister(values);
          }}
        >
          {({ values, setFieldValue, touched, errors, handleSubmit }) => (
            <Form>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3 style={{ fontSize: "20px" }} className="ms-5">
                    MH TRAINING ACAMEDY
                  </h3>
                  <h1
                    style={{ fontSize: "50px", fontWeight: "800" }}
                    className="ms-5"
                  >
                    REGISTRATION
                  </h1>
                  <h6 style={{ background: "#C6A24F", padding: "5px 10px" }}>
                    To Complete the registration, please answer the following
                    questions:
                  </h6>
                </div>
                <div
                  style={{ width: "120px", height: "120px" }}
                  className="me-5"
                >
                  <img
                    src={"/logo.png"}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
              <div className="row px-5 py-3">
                <div className="col-md-6 mb-3">
                  <label>Applicant Full Name:</label>
                  <Input
                    placeholder="Full Name"
                    value={values.name}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                  {errors.name && touched.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Phone Number</label>
                  <Input
                    placeholder="Phone"
                    value={values.phone}
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value);
                    }}
                  />
                  {errors.phone && touched.phone && (
                    <div className="text-danger">{errors.phone}</div>
                  )}
                </div>
              </div>
              <div
                className="row px-3 py-3 mx-0"
                style={{ background: "#C6A24F" }}
              >
                <div className="col-md-6" style={{ fontWeight: "600" }}>
                  Course Title: {courseInfo?.courseTitle}
                </div>
                <div className="col-md-6" style={{ fontWeight: "600" }}>
                  Month: {courseInfo?.month}
                </div>
              </div>
              <div className="row px-5 py-3">
                <div className="col-md-6 mb-3">
                  <label>Nationality</label>
                  <Select
                    value={values.nationality}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select Nationality"
                    onChange={(val) => {
                      setFieldValue("nationality", val);
                    }}
                    options={nationalities}
                  />
                  {errors.nationality && touched.nationality && (
                    <div className="text-danger">{errors.nationality}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Email Address</label>
                  <Input
                    placeholder="Email"
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />
                  {errors.email && touched.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Address</label>
                  <Input
                    placeholder="Address"
                    value={values.address}
                    onChange={(e) => {
                      setFieldValue("address", e.target.value);
                    }}
                  />
                  {errors.address && touched.address && (
                    <div className="text-danger">{errors.address}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Postcode</label>
                  <Input
                    placeholder="Postcode"
                    value={values.postcode}
                    onChange={(e) => {
                      setFieldValue("postcode", e.target.value);
                    }}
                  />
                  {errors.postcode && touched.postcode && (
                    <div className="text-danger">{errors.postcode}</div>
                  )}
                </div>
              </div>
              <div className="row px-4 mt-4">
                <div className="col-md-2 mb-3">
                  <label>Emergency Contact</label>
                </div>
                <div className="col-md-10 mb-3">
                  <Input
                    placeholder="Emergency Contact"
                    value={values.emmergencyNumber}
                    onChange={(e) => {
                      setFieldValue("emmergencyNumber", e.target.value);
                    }}
                  />
                  {errors.emmergencyNumber && touched.emmergencyNumber && (
                    <div className="text-danger">{errors.emmergencyNumber}</div>
                  )}
                </div>
                <div className="col-md-2 mb-3">
                  <label>Date Of Birth</label>
                </div>
                <div className="col-md-10 mb-3">
                  <DatePicker
                    style={{ width: "100%" }}
                    // value={moment(values.dob).format("YYYY-MM-DD")}
                    onChange={(val, date) => {
                      setFieldValue("dob", date);
                      console.log(val);
                      console.log(date);
                    }}
                  />
                  {errors.dob && touched.dob && (
                    <div className="text-danger">{errors.dob}</div>
                  )}
                </div>
                <div className="col-md-2 mb-3">
                  <label>Gender</label>
                </div>
                <div className="col-md-10 mb-3">
                  <Radio.Group
                    value={values.gender}
                    onChange={(e) => {
                      setFieldValue("gender", e.target.value);
                    }}
                  >
                    <Radio value={"male"}>Male</Radio>
                    <Radio value={"female"}>Female</Radio>
                    <Radio value={"other"}>Other</Radio>
                  </Radio.Group>
                  {errors.gender && touched.gender && (
                    <div className="text-danger">{errors.gender}</div>
                  )}
                </div>
              </div>
              <div className="row px-4 mx-4 mt-4">
                <div
                  className="col-md-12 mb-3 py-2"
                  style={{ background: "#C6A24F" }}
                >
                  <div className="row">
                    <div className="col-10">Questions</div>
                    <div className="col-1">Yes</div>
                    <div className="col-1">No</div>
                  </div>
                </div>
                <div className="col-10 mb-3">
                  1. Are you currently working?{" "}
                  {errors.currentlyWorking && touched.currentlyWorking && (
                    <div className="text-danger">{errors.currentlyWorking}</div>
                  )}
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.currentlyWorking == true ? true : false}
                    onChange={(e) => {
                      setFieldValue("currentlyWorking", true);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.currentlyWorking == false ? true : false}
                    onChange={(e) => {
                      setFieldValue("currentlyWorking", false);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-10 mb-3">
                  2. Have you got more than 2 years experience in hospitality?
                  {errors.experienceInHospitality &&
                    touched.experienceInHospitality && (
                      <div className="text-danger">
                        {errors.experienceInHospitality}
                      </div>
                    )}
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={
                      values.experienceInHospitality == true ? true : false
                    }
                    onChange={(e) => {
                      setFieldValue("experienceInHospitality", true);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={
                      values.experienceInHospitality == false ? true : false
                    }
                    onChange={(e) => {
                      setFieldValue("experienceInHospitality", false);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-10 mb-3">
                  3. Do you have the right permit to work in UK?
                  {errors.workPermitInUK && touched.workPermitInUK && (
                    <div className="text-danger">{errors.workPermitInUK}</div>
                  )}
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.workPermitInUK == true ? true : false}
                    onChange={(e) => {
                      setFieldValue("workPermitInUK", true);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.workPermitInUK == false ? true : false}
                    onChange={(e) => {
                      setFieldValue("workPermitInUK", false);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-10 mb-3">
                  4. Have you got any criminal record?
                  {errors.criminalRecord && touched.criminalRecord && (
                    <div className="text-danger">{errors.criminalRecord}</div>
                  )}
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.criminalRecord == true ? true : false}
                    onChange={(e) => {
                      setFieldValue("criminalRecord", true);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.criminalRecord == false ? true : false}
                    onChange={(e) => {
                      setFieldValue("criminalRecord", false);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-10 mb-3">
                  5. Are you aware of anyone who may be interested in our
                  courses?
                  {errors.courseInterest && touched.courseInterest && (
                    <div className="text-danger">{errors.courseInterest}</div>
                  )}
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.courseInterest == true ? true : false}
                    onChange={(e) => {
                      setFieldValue("courseInterest", true);
                    }}
                  ></Checkbox>
                </div>
                <div className="col-1 mb-3">
                  <Checkbox
                    checked={values.courseInterest == false ? true : false}
                    onChange={(e) => {
                      setFieldValue("courseInterest", false);
                    }}
                  ></Checkbox>
                </div>
              </div>
              <div className="py-0 px-5 my-0 text-center">
                <button
                  type="button"
                  className="mt-5 px-4"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "#C6A34F",
                    color: "white",
                    border: "1px solid #C6A34F",
                    textDecoration: "none",
                  }}
                >
                  Proceed to Pay
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default StudentForm;
