import { Button, Modal, Result } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

function PaymentError() {
  // const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showContactModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Result
        status="error"
        title="Stripe Payment Failed"
        subTitle={`Sorry, something went wrong during the payment process`}
        extra={[
          <Button type="primary" onClick={showContactModal} key="support">
            Contact Support
          </Button>,
          <Button key="dashboard">
            <Link
              style={{
                // color: 'white',
                textDecoration: "none",
              }}
              to="/payment-invoice"
            >
              Go back
            </Link>
          </Button>,
        ]}
      />
      <Modal
        title="Contact Support"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>If you have any issues, please contact our support team:</p>
        <p>Email: support@example.com</p>
        <p>Phone: +1 (800) 123-4567</p>
      </Modal>
    </>
  );
}

export default PaymentError;
