import { Button, Tooltip, message } from "antd";
import { addMonths, isSameDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { FcCalendar } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";
import Calendar from "./Newcalendar";
import { useTranslation } from "react-i18next";

export default function EmployeeCalendar() {
  const { t } = useTranslation();
  const [selectedDays, setSelectedDays] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [rangeStart, setRangeStart] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [unavailableDatesFromAPI, setUnavailableDatesFromAPI] = useState([]);
  const [bookedDates, setBookedDates] = useState([]);
  const [pendingDates, setPendingDates] = useState([]);
  const navigate = useNavigate();
  const decodedata = jwtDecode(token());
  const currentMonth = new Date();
  currentMonth.setDate(1);
  currentMonth.setHours(0, 0, 0, 0);

  // Get the next month
  const nextMonth = addMonths(currentMonth, 1);
  const fetchData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/working-history/${decodedata._id}`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      // console.log(data);

      // Existing code for unavailableDates
      const unavailableDatesFromAPI = data.result.unavailableDates.map(
        (dateRange) => ({
          from: new Date(dateRange.startDate),
          to: new Date(dateRange.endDate),
        })
      );
      setRanges(unavailableDatesFromAPI);
      setUnavailableDatesFromAPI(unavailableDatesFromAPI);

      // New code for bookedDates
      // New code for bookedDates
      const bookedDatesFromAPI = data.result.bookedDates.map((dateRange) => ({
        from: new Date(dateRange.startDate),
        to: new Date(dateRange.endDate),
      }));
      setBookedDates(bookedDatesFromAPI);

      // New code for pendingDates
      const pendingDatesFromAPI = data.result.pendingDates.map((dateRange) => ({
        from: new Date(dateRange.startDate),
        to: new Date(dateRange.endDate),
      }));
      setPendingDates(pendingDatesFromAPI);
    }
  };
  useEffect(() => {
    fetchData();
  }, [decodedata._id]);

  const handleDayClick = (day, { selected }) => {
    if (rangeStart) {
      const sortedDates = [rangeStart, day].sort(
        (a, b) => a.getTime() - b.getTime()
      );
      const newRange = { from: sortedDates[0], to: sortedDates[1] };

      // console.log("newRange", newRange);

      // Check if the new range overlaps with any existing range
      const isRangeOverlapping = ranges.some((range) => {
        return newRange.from <= range.to && newRange.to >= range.from;
      });

      if (isRangeOverlapping) {
        // console.log("Range partially overlaps with an existing selection.");
        message.error(
          "This range partially overlaps with an existing selection."
        );
        return;
      }

      let dateCursor = new Date(newRange.from);
      let isAnyDateUnavailable = false;

      while (dateCursor <= newRange.to) {
        if (
          bookedDates.some((dateRange) =>
            isDateWithinRange(dateCursor, dateRange)
          )
        ) {
          isAnyDateUnavailable = true;
          break;
        }
        dateCursor.setDate(dateCursor.getDate() + 1);
      }

      if (isAnyDateUnavailable) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // Check if the range includes disabled dates
      if (isRangeIncludingDisabledDates(newRange)) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // Assuming `isRangeIncludingDisabledDates` is a function that returns true or false
      if (!isRangeIncludingDisabledDates(newRange)) {
        setRanges((prev) => [...prev, newRange]);
        setSelectedDays((prev) =>
          prev.filter((d) => !isDateWithinRange(d, newRange))
        );
      }
      setRangeStart(null);
    } else {
      // Existing logic for handling individual days
      const isUnavailable = bookedDates.some((dateRange) =>
        isDateWithinRange(day, dateRange)
      );

      if (isUnavailable || isDaySelected(day)) {
        message.error(
          "This date is either booked, pending, or already selected."
        );
        return;
      }

      if (isRangeIncludingDisabledDates({ from: day, to: day })) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      if (selected) {
        setSelectedDays((prev) => prev.filter((d) => !isSameDay(d, day)));
      } else {
        setSelectedDays((prev) => [...prev, day]);
      }
      setRangeStart(day);
    }

    const selectedDateElement = document.getElementById(day.toString());
    if (selectedDateElement) {
      selectedDateElement.classList.add("selected-date");
    }
  };

  // console.log("ranges", ranges);

  const isDaySelected = (day) => {
    for (let range of ranges) {
      if (isDateWithinRange(day, range)) {
        return true;
      }
    }
    return selectedDays.some((selectedDay) => isSameDay(day, selectedDay));
  };

  const isDateWithinRange = (date, { from, to }) => {
    // Get the start and end of the day for the 'from' and 'to' dates
    const startOfDayFrom = new Date(from);
    const endOfDayTo = new Date(to);
    // Set the time for the start of the day (00:00:00.000)
    startOfDayFrom.setHours(0, 0, 0, 0);

    // Set the time for the end of the day (23:59:59.999)
    endOfDayTo.setHours(23, 59, 59, 999);

    // Check if the 'date' falls within the adjusted range
    const withinRange =
      date.getTime() >= startOfDayFrom.getTime() &&
      date.getTime() <= endOfDayTo.getTime();

    return withinRange;
  };

  const parsedUnavailableDates = unavailableDatesFromAPI.map((dateRange) => ({
    from: new Date(dateRange.from),
    to: new Date(dateRange.to),
  }));

  const isRangeIncludingDisabledDates = ({ from, to }) => {
    return (
      unavailableDates.some(
        (disabledDate) =>
          disabledDate.getTime() >= from.getTime() &&
          disabledDate.getTime() <= to.getTime()
      ) ||
      bookedDates.some(
        (bookedDate) =>
          bookedDate.from.getTime() >= from.getTime() &&
          bookedDate.to.getTime() <= to.getTime()
      ) ||
      pendingDates.some(
        (pendingDate) =>
          pendingDate.from.getTime() >= from.getTime() &&
          pendingDate.to.getTime() <= to.getTime()
      )
    );
  };

  const handleSave = async () => {
    const formattedDates = [];
    ranges.forEach((range) => {
      formattedDates.push({
        startDate: moment(zonedTimeToUtc(range.from, "UTC")).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(zonedTimeToUtc(range.to, "UTC")).format("YYYY-MM-DD"),
      });
    });

    selectedDays.forEach((date) => {
      formattedDates.push({
        startDate: moment(zonedTimeToUtc(date, "UTC")).format("YYYY-MM-DD"),
        endDate: moment(zonedTimeToUtc(date, "UTC")).format("YYYY-MM-DD"),
      });
    });

    // console.log({ dates: formattedDates });

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/update-unavailable-date`;
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
      body: JSON.stringify({ dates: formattedDates }),
    }).then((res) => res.json());
    if (response.status === "success") {
      toast.success("Successfully updated unavailable dates");
      fetchData();
    }
  };

  const deselectDateRange = (dateRange) => {
    // Remove the date range from the local state
    setRanges((prev) =>
      prev.filter(
        (range) =>
          !(
            isSameDay(range.from, dateRange.from) &&
            isSameDay(range.to, dateRange.to)
          )
      )
    );

    setUnavailableDatesFromAPI((prev) =>
      prev.filter(
        (range) =>
          !(
            isSameDay(range.from, dateRange.from) &&
            isSameDay(range.to, dateRange.to)
          )
      )
    );
  };

  const deselectDays = (dayToRemove) => {
    setSelectedDays((prevSelectedDays) => {
      return prevSelectedDays.filter(
        (day) => day.getTime() !== dayToRemove.getTime()
      );
    }); // it contains first date only
    setRangeStart(null);
  };

  const today = moment().startOf("day").toDate();
  const goBack = () => {
    navigate(-1);
  };

  const DateTimeComponent = ({ date, idx, type, deselect }) => {
    const countDates = (startDate, endDate) => {
      if (!startDate || !endDate) {
        return "Select End Date";
      }
      const start = moment(startDate, "YYYY-MM-DD");
      const end = moment(endDate, "YYYY-MM-DD");
  
      if (start.isSame(end, "day")) {
        return `1 Day`;
      }
  
      const dayDifference = end.diff(start, "days") + 1; // Add 1 to include both start and end dates
  
      return `${dayDifference}
      ${dayDifference > 1 ? t("days") : t("day")}
      `;
    };
  
    const displayDate =
      type === "range"
        ? formatDateRange(date.from, date.to)
        : formatDate(new Date(date));
  
    return (
      // <Tooltip
      //   title={
      //     type !== "range" && (
      //       <p>You must select an end date before update or remove</p>
      //     )
      //   }
      // >
        <div
          key={`${type}-${idx}`}
          style={{
            backgroundColor: "#FAFAFA",
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <p>
            <FcCalendar
              style={{
                marginRight: "5px",
              }}
            />
            {displayDate}
            {type === "range" && deselect ? ( // Check if deselect function is defined
              <span
                className="text-red-500 ml-2 cursor-pointer"
                onClick={() => deselect(date)}
              >
                <AiFillDelete
                  style={{
                    color: "red",
                    marginLeft: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                />
              </span>
            ) : (
              <span
                className="text-red-500 ml-2 cursor-pointer"
                onClick={() => deselect(date)}
              >
                <AiFillDelete
                  style={{
                    color: "red",
                    marginLeft: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                />
              </span>
            )}
          </p>
          <div
            style={{
              color: "#C6a34f",
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                color:
                  countDates(date.from, date.to) === "Select End Date"
                    ? "red"
                    : "#C6a34f",
              }}
            >
              {countDates(date.from, date.to)}
            </p>
          </div>{" "}
        </div>
      // </Tooltip>
    );
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 col-md-7">
          <Calendar
            bookedDates={bookedDates}
            pendingDates={pendingDates}
            unavailableDates={unavailableDates}
            selectedDays={selectedDays}
            ranges={ranges}
            handleDayClick={handleDayClick}
            deselectDateRange={deselectDateRange}
            goBack={goBack}
            isSameDay={isSameDay}
            isDateWithinRange={isDateWithinRange}
            today={today}
            nextMonth={nextMonth}
            setUnavailableDates={setUnavailableDates}
          />
        </div>

        <div className="col-12 col-md-5">
          <div
            className="shadow p-4 overflow-y-auto max-h-[400px] rounded"
            style={{ backgroundColor: "#fff", border: "1px solid #C6a34f" }}
          >
            <h4 className="text-gray-700 border-b p-2 mb-3 text-center">
            {t("your_unavailability")}
            </h4>
            <div>
              {ranges.map((range, idx, dates) => (
                <DateTimeComponent
                  date={range}
                  idx={idx}
                  type="range"
                  deselect={deselectDateRange}
                  dates={dates}
                />
              ))}

              {selectedDays.map((day, idx) => (
                <DateTimeComponent
                  key={`day-${idx}`}
                  date={day}
                  deselect={deselectDays}
                  idx={idx}
                  type="day"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4 mb-5">
        <Button
          onClick={handleSave}
          style={{
            backgroundColor: "#C6a34f",
            color: "white",
          }}
        >
          {t("update_unavailable_dates")}
        </Button>
      </div>
    </div>
  );
}

// Function to format individual dates
const formatDate = (date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

// Function to format date ranges
const formatDateRange = (from, to) => {
  if (from && !to) {
    return `${formatDate(new Date(from))} - Select endDate`;
  }

  const fromDate = formatDate(new Date(from));
  const toDate = formatDate(new Date(to));

  return fromDate === toDate
    ? `${fromDate} - ${fromDate} `
    : `${fromDate} - ${toDate}`;
};


