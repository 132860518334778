import { Select, Switch, Table ,Space,Popconfirm} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { token } from "../../../utils/authentication";
import GoBack from "../../../utils/goBack";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Option } = Select;

const TermsTable = () => {
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("United Kingdom");

  const fetchTermsConditions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/terms-conditions?country=${selectedCountry}`
      );

      setData(response.data.termsConditions);
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to fetch terms and conditions");
    }
  };

  useEffect(() => {
    fetchTermsConditions();
  }, [selectedCountry]);

  const handleUpdate = async (id, active) => {
    // console.log("Switch change:", id, active);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/terms-conditions/update-status`,
        {
          id,
          active,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toast.success("Terms and conditions updated successfully");
      fetchTermsConditions();
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to update terms and conditions");
    }
  };

  const handleDelete = async (id) => {
   // console.log("Switch change:", id, active);
   try {
    await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/terms-conditions/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    toast.success("Terms and conditions updated successfully");
    fetchTermsConditions();
  } catch (error) {
    console.error("API Error:", error);
    toast.error("Failed to update terms and conditions");
  }
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => handleUpdate(record._id, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/admin/terms-edit/${record._id}`}>
            <EditOutlined style={{ color: "green" }} />
          </Link>{" "}
          <Popconfirm
            title="Are you sure to delete this About Record?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
   <>
   <GoBack/>
    <div style={{
      padding:"20px"
    }}>
      <Select
        defaultValue="United Kingdom"
        style={{ width: 250, marginBottom: 16 }}
        onChange={(value) => setSelectedCountry(value)}
      >
        <Option value="Italy">Italy</Option>
        <Option value="United Kingdom">United Kingdom</Option>
        <Option value="Dubai">Dubai</Option>
      </Select>
      <Table dataSource={data} columns={columns} rowKey="id" />
    </div>
   </>
  );
};

export default TermsTable;
