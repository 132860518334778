import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-day-picker/dist/style.css";
import { HelmetProvider } from "react-helmet-async";
import "./assets/css/_main.css";
import ContextProvider from "./hooks/ContextApi";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
const helmetContext = {};
const queryClient = new QueryClient()
 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <App />
        </ContextProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </>
);
