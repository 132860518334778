import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApi } from "../../../../hooks/ContextApi";
import { token } from "../../../../utils/authentication";

export default function Notification() {
  const [notificationList, setNotificationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const scrollDiv = useRef(null);
  const {
    notificationCount,
    setNotificationCount,
    getShortList,
    setShortList,
  } = useContext(ContextApi);
  const fetchNotifications = (page = 1) => {
    setIsLoading(true); // Set loading to true
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/notifications/list?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        if (page === 1) {
          setNotificationList(res.data.notifications);
        } else {
          setNotificationList((prevNotifications) => [
            ...prevNotifications,
            ...res.data.notifications,
          ]);
        }
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false
      });
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollDiv.current;

    if (scrollTop + clientHeight >= scrollHeight) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage, notificationCount]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (scrollDiv.current) {
      scrollDiv.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv.current) {
        scrollDiv.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const handleNotificationClick = (id) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/notifications/update-status`,
        {
          id: id,
          readStatus: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then(() => {
        fetchNotifications();
        setNotificationCount(notificationCount - 1);
      });
  };
  return (
    <div
      ref={scrollDiv}
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        width: "300px",
        height: "300px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        zIndex: "9999",
        padding: "20px",
        overflowY: "scroll",
        borderRadius: "5px",
      }}
    >
      <div>
        <h5>Notifications</h5>
        <hr />
        {notificationList.map((item) => (
          <div key={item.id} onClick={() => handleNotificationClick(item._id)}>
            <p
              style={{
                color: "#000000",
                fontSize: "12px",
                fontWeight: item.readStatus === true ? "normal" : "bold",
                cursor: "pointer",
              }}
            >
              {item.text}
            </p>
            <hr />
          </div>
        ))}
        {isLoading && <p>Loading...</p>} {/* Display loading text */}
      </div>
    </div>
  );
}
