import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  FcCalendar,
  FcConferenceCall,
  FcMoneyTransfer,
  FcReadingEbook,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import { ContextApi } from "../../../../hooks/ContextApi";
import { token } from "../../../../utils/authentication";
import PreLoader from "../../../loadar/PreLoader";
import noSearchResult from "./No-Results-Found.svg";
import job from "./job.svg";
import noResult from './no-result.png'
import NotFound from "../notFound/notFound";
import { useTranslation } from "react-i18next";

// export const NoJobsFound = () => (
//   <div className="text-center">
//     <h2>No jobs found</h2>
//     <p>Sorry, there are currently no jobs available.</p>
//     <img
//       src={noResult}
//       alt="404"
//       style={{ maxWidth: "100%", maxHeight: "400px" }}
//     />
//   </div>
// );

const displayDate = (dates) => {
  if (dates && dates?.length > 0) {
    const { startDate, endDate } = dates[0];
    return `${startDate} - ${endDate}`;
  }
  return "";
};

const displayNationalities = (nationalities) => {
  if (nationalities && nationalities?.length > 0) {
    // Display the first two nationalities or all if there are fewer than two
    const displayedNationalities = nationalities?.slice(0, 1).join(", ");
    return displayedNationalities;
  }
  return "";
};

// const displayNationalities = (nationalities) => {
//   const nationalityMap = {
//     UK: "United Kingdom",
//     USA: "United States of America",
//     // Add more mappings as needed
//   };

//   if (nationalities && nationalities?.length > 0) {
//     // Filter only UK and USA nationalities
//     const filteredNationalities = nationalities.filter(
//       (nationality) => nationality === "UK" || nationality === "USA"
//     );

//     // Map the filtered nationalities to their full forms
//     const displayedNationalities = filteredNationalities.map(
//       (nationality) => nationalityMap[nationality]
//     );

//     return displayedNationalities.join(", ");
//   }

//   return "";
// };

const AllJobs = () => {
  const { t } = useTranslation();
  const [jobs, setJobs] = useState([]);
  const { currencyType } = useContext(ContextApi);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (newPage) => {
    // console.log(`newPage`, newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const fetchJobs = async () => {
      const itemsPerPage = 8;
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/job` +
            `?page=${currentPage}&limit=${itemsPerPage}&status=PUBLISHED`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setJobs(data.jobs);
          // console.log("data.jobs", data);
          setTotal(data.total);
          setTotalPages(Math.ceil(total / itemsPerPage));
          setLoading(false);
        } else {
          console.error("Failed to fetch jobs");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, [currentPage, total]);

  return (
    <div
      className="container mt-4 mx-auto  d-flex flex-column mb-5"
      style={{
        backgroundColor: "#FFFFFF",
        padding: "20px",
        borderRadius: "12px",
        borderColor: "#A6A6A6",
        border: "1px solid #A6A6A6",
      }}
    >
      <div
        className="mb-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 className="">
          <img
            className=" me-2"
            src={job}
            alt="job"
            style={{ width: "30px", height: "30px" }}
          />
          {t("jobs_posts")}
        </h5>
        <h5 className="">
          <span style={{ color: "#C6A34F" }}>{total}</span>
          {t("new_job_posts_are_showing")}
        </h5>
      </div>
      {jobs.length === 0 ? (
        <NotFound
          title={t("no_jobs_found")}
          description={t("sorry,_there_are_currently_no_jobs_available.")}
        />
      ) : (
        <>
          {loading ? (
            <PreLoader />
          ) : (
            <>
              <div className="row">
                {jobs?.map((job) => (
                  <div key={job._id} className="col-md-6 col-lg-3 mb-4">
                    <Card
                      style={{
                        // width: "18rem",
                        height: "25rem",
                        borderRadius: "10px",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${job.positionId.logo}`}
                        style={{
                          objectFit: "contain",
                          height: "85px",
                          width: "85px",
                          margin: "0 auto",
                          marginTop: "20px",
                        }}
                      />
                      <Card.Body>
                        <Card.Title className="text-center fw-bold">
                          {job.positionId.name}
                        </Card.Title>
                        <hr
                          style={{
                            backgroundColor: "#C6A34F",
                            height: "2px",
                            border: "none",
                          }}
                        />
                        <Card.Text>
                          <FcMoneyTransfer
                            style={{
                              marginRight: "5px",
                            }}
                          />
                          {t("rate:")} {currencyType}
                          {job.minRatePerHour} - {currencyType}
                          {job.maxRatePerHour} {t("per_hour")}
                        </Card.Text>
                        <Card.Text>
                          <FcReadingEbook
                            style={{
                              marginRight: "5px",
                            }}
                          />
                          {t("experience:")} {job.minExperience} - {job.maxExperience}{" "}
                          {t("years")}
                        </Card.Text>
                        <Card.Text>
                          <FcConferenceCall
                            style={{
                              marginRight: "5px",
                            }}
                          />
                          {t("nationality:")} {displayNationalities(job.nationalities)}
                        </Card.Text>
                        <Card.Text>
                          <FcCalendar
                            style={{
                              marginRight: "5px",
                            }}
                          />
                          {t("date:")} {displayDate(job.dates)}
                        </Card.Text>
                        <Link to={`/job-details/${job._id}`}>
                          <Button
                            style={{
                              backgroundColor: "#C6A34F",
                              color: "#FFFFFF",
                              border: "none",
                              borderRadius: "10px",
                              width: "100%",
                              height: "40px",
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginTop: "10px",
                            }}
                          >
                            {t("full_view")}
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>
                ))}{" "}
                <section className="">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 d-flex justify-content-center">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination paginationLiA">
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Previous"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                              >
                                <span aria-hidden="true">«</span>
                              </a>
                            </li>
                            {Array.from({ length: totalPages }, (_, index) => (
                              <li
                                className={`page-item ${
                                  currentPage === index + 1 ? "active" : ""
                                }`}
                                key={index}
                              >
                                <a
                                  className="page-link"
                                  href="#"
                                  onClick={() => handlePageChange(index + 1)}
                                  style={
                                    currentPage === index + 1
                                      ? { backgroundColor: "#C6A34F" }
                                      : {}
                                  }
                                >
                                  {index + 1}
                                </a>
                              </li>
                            ))}

                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Next"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                <span aria-hidden="true">»</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AllJobs;
