import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Popconfirm, Select, Table, Upload } from "antd";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { token } from "../../../utils/authentication";
import { useTranslation } from "react-i18next";

const _init = {
  title: "",
  description: "",
  time: "",
  amountType: "",
  amount: "",
  tags: [],
  seat: "",
  image: false,
  categoryId: "",
  status: "",
};

const CourseCategory = () => {
  const {t} = useTranslation();
  const [courseData, setCourseData] = useState(_init);
  const [courseCategories, setCourseCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const baseImageUrl = `${process.env.REACT_APP_ASSETS_FRONTEND_URL}/`; // Your base URL
  const [selectedAmountType, setSelectedAmountType] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/course`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setData(response.data.courses);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/course-category/list-for-dropdown`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setCourseCategories(res.data.courseCategorys);
      });
  }, []);

  const columns = [
    {
      title: t("serial"),
      dataIndex: "serial", // You need to include a "serial" field in your data
      key: "serial",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("price"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <span>{amount} £</span>,
    },
    {
      title: t("amount_type"),
      dataIndex: "amountType",
      key: "amountType",
    },
    {
      title: t("time"),
      dataIndex: "time",
      key: "time",
    },
    {
      title: t("tags"),
      dataIndex: "tags",
      key: "tags",
      render: (tags) => <span>{tags?.join(", ")}</span>,
    },
    {
      title: t("action"),
      key: "action",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{
              backgroundColor: "#C6A34F",
              color: "#fff",
              textAlign: "center",
            }}
            onClick={() => handleEdit(record)}
          >
            {t("edit")}
          </Button>
          <Popconfirm
            title={t("are_you_sure_you_want_to_delete_this_course_category")}
            onConfirm={() => handleDelete(record._id)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button
              type="danger"
              style={{
                backgroundColor: "#E23821",
                marginLeft: "10px",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {t("delete")}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const handleEdit = (record) => {
  //   setIsEditing(true);
  //   setEditRecord(record);
  //   setIsModalVisible(true);
  // };

  const handleEdit = (record) => {
    setIsEditing(true);
    setEditRecord(record);
    setIsModalVisible(true);

    // Reset the form values, including the image
    setCourseData(_init);
    setSelectedImage(null);
  };

  const handleDelete = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/course/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then(() => {
        // Handle success
        toast.success("Course deleted successfully");

        // Refresh the data to remove the deleted course category
        fetchData();
      })
      .catch((error) => {
        // Handle error
        toast.error("Something went wrong");
      });
  };

  const handleCreateClick = () => {
    setIsModalVisible(true);
  };

  const handleSave = async (values, action) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("time", values.time);
    formData.append(
      "amount",
      selectedAmountType === "FIXED" ? values.amount : 0
    );
    // formData.append("amountType", values.amountType);
    formData.append("amountType", selectedAmountType);
    formData.append("tags", JSON.stringify(values.tags));
    formData.append("seat", values.seat);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    formData.append("categoryId", values.categoryId);
    formData.append("status", values.status);

    if (isEditing) {
      formData.append("id", values._id);
      await axios
        .put(`${process.env.REACT_APP_API_BASE_URL}/course/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token()}`,
          },
        })
        .then(async (res) => {
          await setIsEditing(false);
          await fetchData();
          await setSelectedImage(false);
          await action.resetForm();
          await setIsModalVisible(false);
        })
        .catch((error) => {
          // Handle error here
          console.error("Error updating course:", error);
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/course/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token()}`,
          },
        })
        .then(async (res) => {
          await setIsEditing(false);
          await fetchData();
          await setSelectedImage("Select Files");
          await action.resetForm();
          await handleModalCancel();
          await setIsModalVisible(false);
        })
        .catch((error) => {
          // Handle error here
          console.error("Error creating course:", error);
        });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("upload")}
      </div>
    </div>
  );

  // Validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    time: Yup.string().required("Time is required"),
    amount: Yup.number(),
    amountType: Yup.string(),
    tags: Yup.array().of(Yup.string()).required("Tags are required"),
    seat: Yup.number().required("Seat is required"),
    image: Yup.mixed().test("required", "Image is required", function (value) {
      const hasId = Boolean(this.parent._id); // Check if _id is present
      return hasId || (hasId === false && !!value); // Require 'image' if no _id
    }),
    categoryId: Yup.string().required("Category ID is required"),
    status: Yup.string().required("Status is required"),
  });

  const handleModalCancel = () => {
    setIsEditing(false);
    setEditRecord({});
    setCourseData(_init);
    setSelectedImage(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (editRecord && Object.keys(editRecord).length > 0 && isEditing) {
      setCourseData({
        ...editRecord,
        status: editRecord.status ? "true" : "false",
      });
    } else {
      setCourseData(_init);
    }
  }, [editRecord, isEditing]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        <Button
          style={{
            backgroundColor: "#C6A34F",
          }}
          type="primary"
          onClick={handleCreateClick}
        >
          {t("create_course")}
        </Button>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="_id"
      />

      <Modal
        title={isEditing ? t("update_course") : t("create_course")}
        visible={isModalVisible}
        footer={null}
        onCancel={handleModalCancel}
      >
        <Formik
          initialValues={courseData}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, action) => {
            handleSave(values, action);
          }}
        >
          {({ values, setFieldValue, errors, touched, handleSubmit }) => (
            <Form>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("title")}:</label>
                    </div>
                    <div className="col-9">
                      <Input
                        onChange={(e) => {
                          setFieldValue("title", e.target.value);
                        }}
                        value={values.title}
                      />
                      {errors.title && touched.title && (
                        <div className="text-danger">{errors.title}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("description")}:</label>
                    </div>
                    <div className="col-9">
                      <Input.TextArea
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        value={values.description}
                      />
                      {errors.description && touched.description && (
                        <div className="text-danger">{errors.description}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("time")}:</label>
                    </div>
                    <div className="col-9">
                      <Input
                        onChange={(e) => {
                          setFieldValue("time", e.target.value);
                        }}
                        value={values.time}
                      />
                      {errors.time && touched.time && (
                        <div className="text-danger">{errors.time}</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>Time Range:</label>
                    </div>
                    <div className="col-9">
                      <TimePicker.RangePicker
                        format="h:mm a" // Use a 12-hour clock format with "am" and "pm"
                        onChange={(values) => {
                          // values will be an array with two moment objects (start and end times)
                          setFieldValue("time", values);
                        }}
                        value={values.time}
                      />
                      {errors.time && touched.time && (
                        <div className="text-danger">{errors.time}</div>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("amount_type")}:</label>
                    </div>
                    <div className="col-9">
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => setSelectedAmountType(value)}
                        value={selectedAmountType?.amountType}
                      >
                        <Select.Option value="FIXED">FIXED</Select.Option>
                        <Select.Option value="NEGOTIABLE">
                          NEGOTIABLE
                        </Select.Option>
                      </Select>
                      {errors.amountType && touched.amountType && (
                        <div className="text-danger">{errors.amountType}</div>
                      )}
                    </div>
                  </div>
                </div>

                {selectedAmountType === "FIXED" && (
                  <>
                    <div className="col-12 mb-3">
                      <div className="row">
                        <div className="col-3">
                          <label>{t("amount")}:</label>
                        </div>
                        <div className="col-9">
                          <Input
                            onChange={(e) => {
                              setFieldValue("amount", e.target.value);
                            }}
                            type="number"
                            min={0}
                            value={values.amount}
                          />
                          {errors.amount && touched.amount && (
                            <div className="text-danger">{errors.amount}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("tags")}:</label>
                    </div>
                    <div className="col-9">
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder={t("select_or_enter_tags")}
                        onChange={(selectedTags) => {
                          setFieldValue("tags", selectedTags);
                        }}
                        value={values?.tags}
                      >
                        {values.tags &&
                          values?.tags.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                              {tag}
                            </Select.Option>
                          ))}
                      </Select>
                      {errors.tags && touched.tags && (
                        <div className="text-danger">{errors.tags}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("seat")}:</label>
                    </div>
                    <div className="col-9">
                      <Input
                        onChange={(e) => {
                          setFieldValue("seat", e.target.value);
                        }}
                        type="number"
                        min={0}
                        value={values.seat}
                      />
                      {errors.seat && touched.seat && (
                        <div className="text-danger">{errors.seat}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("image")}:</label>
                    </div>
                    <div className="col-9">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={(e) => {
                          setSelectedImage(e.file.originFileObj);
                          setFieldValue("image", e.file.originFileObj);
                        }}
                      >
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : values.image ? (
                          <img
                            src={`${baseImageUrl}${values.image}`}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      {errors.image && touched.image && (
                        <div className="text-danger">{errors.image}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("category")}:</label>
                    </div>
                    <div className="col-9">
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setFieldValue("categoryId", value);
                        }}
                        value={values.categoryId}
                      >
                        {courseCategories.map((category) => (
                          <Select.Option
                            key={category._id}
                            value={category._id}
                          >
                            {category.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {errors.categoryId && touched.categoryId && (
                        <div className="text-danger">{errors.categoryId}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <label>{t("status")}:</label>
                    </div>
                    <div className="col-9">
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setFieldValue("status", value);
                        }}
                        value={values.status}
                      >
                        <Select.Option value="true">Active</Select.Option>
                        <Select.Option value="false">Inactive</Select.Option>
                      </Select>
                      {errors.status && touched.status && (
                        <div className="text-danger">{errors.status}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Add more fields as needed */}
              <div className="modal-footer border-0 px-0 mx-0">
                <Button type="primary" htmlType="button" onClick={handleSubmit}>
                  {isEditing ? t("update") : t("submit")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default CourseCategory;
