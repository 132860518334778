import React, { useCallback, useEffect, useState } from "react";

import { Button, Drawer, Form, Input, Select, Table } from "antd";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { addHandler, updateSkillHandler } from "../../../api/skill";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function Skill() {
  const {t} = useTranslation();
  //get skills
  const [skills, setSkills] = useState([]);

  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [open, setOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [skillId, setSkillId] = useState();
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");


  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  };

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/admin/skill?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showEditDrawer = (id) => {
    setSkillId(id);
    setIsEditModalOpen(true);
  };

  const onCloseEdit = () => {
    setIsEditModalOpen(false);
  };

  const onFinish = (values) => {
    const name = values?.name;
    const active = values?.active === "YES" ? true : false;

    const addSkillFields = { name, active };

    if (name) {
      setLoading(true);
      addHandler(addSkillFields)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 201) {
            setError(undefined);
            setLoading(false);
            responseNotification("Skill created successfully!", "success");
            fetchSkills();
            onClose();
            form.resetFields();
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setLoading(false);
          }
        });
    }
  };

  //Edit skill Data
  const onFinishEdit = (values) => {
    const id = skillId;
    const name = values?.name;
    const active = values?.active === "YES" ? true : false;

    const receivedSkillFields = { id, name, active };

    if (id) {
      setLoading(true);
      updateSkillHandler(receivedSkillFields)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 200) {
            setError(undefined);
            setLoading(false);
            responseNotification("Skill updated successfully!", "success");
            fetchSkills();
            onCloseEdit();
            form.resetFields();
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setLoading(false);
          }
        });
    }
  };


  const fetchSkills = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/skills?page=${currentPage}&limit=${pageSize}`+
        (searchText ? `&name=${searchText}` : "")
        ,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (res?.status === 200) {
        setSkills(res?.data?.skills);
        setTotalData(res?.data?.total);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize,searchText]);

  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]);

  const fetchSingleSkill = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/skills/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      formEdit.setFieldsValue({
        name: res?.data?.details?.name,
        active: res?.data?.details?.active === true ? "YES" : "NO",
      });
    } catch (err) {
      // console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchSingleSkill();
  }, [skillId]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("status"),
      dataIndex: "active",
      key: "active",
      render: (text) => (text ? "YES" : "NO"),
    },
    {
      title: t("action"),
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          style={{ background: "#C6A34F", color: "white" }}
          onClick={() => showEditDrawer(record._id)}
        >
          {t("edit")}
        </Button>
      ),
    },
  ];

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="mb-4 title">{t("skill_list")}</h3>
          <Button
            type="primary"
            style={{ background: "#C6A34F", color: "white" }}
            className="ml-5"
            onClick={showDrawer}
          >
            {t("add_skill")}
          </Button>
        </div>
      </div>
      <div className="card sd">
        <div className="m-3">
          <Input
            placeholder={t("enter_name")}
            allowClear
            size="large"
            style={{
              width: 300,
              marginLeft: "10px",
            }}
            onChange={onSearchChange}
          />
          {/* <Button
            style={{
              background: "#C6A34F",
              marginLeft: "-10px",
              color: "white",
            }}
            size="large"
            onClick={handleSearch}
          >
            Search
          </Button> */}
        </div>
        <Table
          columns={columns}
          dataSource={skills}
          loading={loading}
          rowKey="_id"
          locale={{ emptyText: t("no_data_found!") }}
          pagination={{
            pageSize: Number(pageSize),
            current: Number(currentPage),
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setpageSize(pageSize);
              navigate(`?page=${page}&limit=${pageSize}`);
            },
            total: totalData,
            showSizeChanger: true, // Add this line to enable the option
            pageSizeOptions: ["10", "20", "50", "100"], // Add this line to customize the options
          }}
        />

        {/* Add skill Drawer */}
        <Drawer
          title={t("add_new_skill")}
          width={520}
          closable={false}
          onClose={onClose}
          open={open}
        >
          <div className="drawer-toggle-wrapper">
            <div className="drawer-toggle-inner-wrapper">
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onFinish}
                form={form}
              >
                <div className="col-lg-12">
                  <div className="single-field">
                    <Form.Item
                      label={t("skill_name")}
                      name="name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_skill_name")
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_skill_name")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="single-field1">
                    <Form.Item
                      label={t("active")}
                      name="active"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "status is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_active_yes_or_no")}
                        optionFilterProp="children"
                      >
                        <Option value="YES">YES</Option>
                        <Option value="NO">NO</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="error-text-color">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-lg-12">
                  <Form.Item>
                    <button
                      disabled={loading}
                      className="btn"
                      style={{ background: "#C6A34F", color: "white" }}
                      type="submit"
                    >
                      {!loading && t("save")}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Drawer>

        {/* Edit Drawer  */}
        <Drawer
          title="Edit skill"
          width={520}
          closable={false}
          onClose={onCloseEdit}
          open={isEditModalOpen}
        >
          <div className="drawer-toggle-wrapper">
            <div className="drawer-toggle-inner-wrapper">
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onFinishEdit}
                form={formEdit}
                initialValues={{ remember: true }}
              >
                <div className="col-lg-12">
                  <div className="single-field">
                    <Form.Item
                      label={t("skill_name")}
                      name="name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_skill_name")
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_skill_name")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="single-field1">
                    <Form.Item
                      label={t("active")}
                      name="active"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("status_is_required")
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_active_yes_or_no")}
                        optionFilterProp="children"
                      >
                        <Option value="YES">YES</Option>
                        <Option value="NO">NO</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="error-text-color">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-lg-12">
                  <Form.Item>
                    <button
                      disabled={loading}
                      className="btn"
                      style={{ background: "#C6A34F", color: "white" }}
                      type="submit"
                    >
                      {!loading && t("save")}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default Skill;
