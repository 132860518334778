import { Spin, Tooltip } from "antd";
import axios from "axios";
import { eachDayOfInterval, startOfMonth } from "date-fns";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import CourseDetailsModal from "../CourseDetailsModal";
import "./calender.scss";

function Calendar() {
  const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const today = new Date();
  const [date, setDate] = useState(today);
  const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const [showModal, setShowModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseId, setCourseId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [columns, setColumn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});
  const [arrow, setArrow] = useState("Show");
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const getCourseDetails = async (id, month) => {
    setDetailsLoading(true);
    setCourseDetails({});
    if (id && month) {
      setSelectedDate(month);
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/course/${id}?month=${moment(
            month
          ).format("YYYY-MM")}`
        )
        .then((res) => {
          if (res.data.details) {
            setCourseDetails(res.data.details);
          } else {
            setCourseDetails({});
          }
        });

      setDetailsLoading(false);
    }
  };

  const handleGetSheduleInfo = async (month) => {
    setLoading(true);
    setColumn([]);
    setCourse([]);
    const currentMonth = month;

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/course/shedule-info`, {
        month: moment(currentMonth).format("YYYY-MM"),
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          const schedule = res.data?.data?.schedule;
          // Use a Set to store unique objects
          let uniqueObjArray = [
            ...new Map(
              schedule?.map((item) => [item["courseId"], item])
            ).values(),
          ];
          setCourse(uniqueObjArray);
          const daysInMonth = eachDayOfInterval({
            start: startOfMonth(currentMonth),
            end: new Date(
              currentMonth.getFullYear(),
              currentMonth.getMonth() + 1,
              0
            ),
          });
          let data = [];

          for (let i = 0; i < daysInMonth.length; i++) {
            data.push({
              name: `${daysInMonth[i]}`,
              items: [],
            });
          }
          if (res.data.data == null) {
            setColumn(data);
            setLoading(false);
          }
          if (schedule?.length > 0) {
            for (let i = 0; i < schedule?.length; i++) {
              for (let j = 0; j < data?.length; j++) {
                if (
                  moment(data[j]?.name).format("YYYY-MM-DD") ==
                  moment(schedule[i]?.date).format("YYYY-MM-DD")
                ) {
                  data[j].items?.push(schedule[i]);
                }
              }
            }
          }

          setColumn(data);
          setLoading(false);
        }
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const currentMonth = date ? date : new Date();

  const renderWeekdayLabels = () => {
    const weekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return (
      <div className="weekday-labels">
        {weekdayLabels.map((label) => (
          <div key={label} className="weekday-label">
            {label}
          </div>
        ))}
      </div>
    );
  };

  const renderDayCells = () => {
    const firstDayOfWeek = startOfMonth(currentMonth).getDay(); // Get the index of the first day of the month

    return (
      <div>
        {renderWeekdayLabels()} {/* Render the weekday labels */}
        <div className="calendar-grid">
          {/* Empty cells for days before the first day of the month */}
          {Array.from({ length: firstDayOfWeek }, (_, index) => (
            <div key={`empty-${index}`} className="calendar-day empty" />
          ))}
          {/* Render day cells */}
          {columns?.map((day) => {
            // const isSelected = selectedDates.some((d) => isSameDay(d, day));
            // const isWeekend = day.getDay() === 0 || day.getDay() === 6; // Sunday or Saturday
            // const isBooked = bookedDates.some((d) => isSameDay(d, day));
            // const isUnavailable = unavailableDates.some((d) =>
            //   isSameDay(d, day)
            // );

            return (
              <div
                key={new Date(day.name).getDate()}
                className={`calendar-day`}
              >
                <h6>{new Date(day.name).getDate()}</h6>
                <div className="d-flex flex-column">
                  {day?.items?.map((item, i) => (
                    <Tooltip
                      key={i}
                      placement="top"
                      title={item?.courseTitle}
                      arrow={mergedArrow}
                    >
                      <p
                        onClick={() => {
                          // handleShowMoreClick(day?.name);
                          setCourseId(item?.courseId);
                          setCourseTitle(item?.courseTitle);
                          getCourseDetails(item?.courseId, day?.name);
                          setShowModal(true);
                        }}
                      >
                        {item?.courseTitle}
                      </p>
                    </Tooltip>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const currentMonth = date;
    handleGetSheduleInfo(currentMonth);
  }, [date]);

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
  }, [date]);

  return (
    <div className=" bg-white calender-main-div">
      <div className="d-lg-flex d-md-flex justify-content-lg-between justify-content-md-between py-3 mx-lg-5  mx-md-1 mx-sm-5">
        <h5 className="mt-4 py-2 px-2">
          <SlCalender className="me-2 -mt-2 " />
          Today, {formatDate(today)}
        </h5>

        <div className="d-flex my-2">
          <MdKeyboardArrowLeft
            className="arrow-button"
            onClick={() => {
              setDate(new Date(year, month - 1, day));
              handleGetSheduleInfo(new Date(year, month - 1, day));
            }}
          />
          <div className="mx-2 mt-lg-3 mt-md-4 custom-margin">
            <h4 className="font-weight-bold date-width ms-2">
              {MONTHS[month]} {year}
            </h4>
          </div>
          <MdKeyboardArrowRight
            className="arrow-button"
            onClick={() => {
              setDate(new Date(year, month + 1, day));
              handleGetSheduleInfo(new Date(year, month + 1, day));
            }}
          />
        </div>
        <div className="tab-item d-flex  mt-4 py-1 px-2">
          {/* <p className='me-2   month'> Months <SlCalender onClick={() => setTab('calendar')} /></p>
          <p className='me-2 common-border2 '><AiOutlineUnorderedList onClick={() => setTab('list')} /> List </p> */}
          <div>
            {course &&
              course.length > 0 &&
              course.map((item) => <p className="m-0">{item?.courseTitle}</p>)}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex flex-column mx-auto test-center">
          <Spin tip="Loading" size="large" />
        </div>
      ) : (
        <div className="container-fluid">{renderDayCells()}</div>
      )}

      <CourseDetailsModal
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
        title={courseTitle}
        id={courseId}
        selectedDate={date}
      />

      {/*<Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {moment(selectedDate).format("DD MMMM YYYY")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailsLoading ? (
            <div className="d-flex flex-column mx-auto test-center">
              <Spin tip="Loading" size="large" />
            </div>
          ) : (
            <Fragment>
              <h6>Course Title:</h6>
              <p>{courseDetails?.courseDetails?.title}</p>
              <h6>Description:</h6>
              <p>{courseDetails?.courseDetails?.description}</p>
              <h6>Class Time:</h6>
              <p>{courseDetails?.courseDetails?.time}</p>
              <h6>Price: </h6>
              <p>£ {courseDetails?.courseDetails?.amount}</p>
            </Fragment>
          )}
        </Modal.Body>
          </Modal>*/}
    </div>
  );
}
export default Calendar;
