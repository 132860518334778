import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Table,
  Upload,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import { useTranslation } from "react-i18next";

const { Option } = Select;
function Position() {
  const { t } = useTranslation();
  //get positions
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [open, setOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [positionId, setPositionId] = useState();
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const [imageFileList, setImageFileList] = useState([]);
  const [logoFileList, setLogoFileList] = useState();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  };

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/admin/position?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  const fetchPositions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/positions?page=${currentPage}&limit=${pageSize}` +
          (searchText ? `&searchKeyword=${searchText}` : ""),
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        },
      );
      if (res?.status === 200) {
        console.log(res?.data);
        setPositions(res?.data?.positions);
        setTotalData(res?.data?.total);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize, searchText]);

  useEffect(() => {
    fetchPositions();
  }, [fetchPositions]);

  // console.log(positions);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [imagesKey, setImagesKey] = useState(0); // Change the key to force a re-render
  const previousImages = formEdit.getFieldValue("images") || [];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // fetchPositions();
    window.location.reload();
    form.resetFields();
  };

  const showEditDrawer = (id) => {
    setPositionId(id);
    setIsEditModalOpen(true);
  };

  const onCloseEdit = () => {
    setIsEditModalOpen(false);
    fetchSinglePosition();
    formEdit.resetFields();
    window.location.reload();
  };

  const uploadButton = (
    <div className="d-flex mt-1">
      <InboxOutlined className="mt-1" />
      Upload
    </div>
  );

  const removeButton = (
    <div>
      <DeleteOutlined />
      <div style={{ marginTop: 8 }}></div>
    </div>
  );

  const formStyle = {
    border: "1px solid #eaeaea",
    padding: "20px",
    borderRadius: "5px",
  };

  const itemStyle = {
    marginBottom: "15px",
    border: "1px solid #eaeaea",
    padding: "10px",
    borderRadius: "5px",
  };

  const handleImageChange = (info) => {
    setImageFileList(info.fileList);
  };

  const handleEditImageChange = (info) => {
    // Get the new images from the 'info' object
    const newImages = info.fileList;
    setImageFileList(newImages);
  };

  const handleLogoChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setLogoFileList(fileList[0]);
  };

  const handleRemoveImage = (file) => {
    // console.log(file, "file");
    const clickedImage = file;
    const previousImages = formEdit.getFieldValue("images");
    // console.log(previousImages, "previousImages");

    // Filter out the clicked image from the previous images
    const newImages = previousImages.filter((image) => image !== clickedImage);

    console.log(newImages, "newImages");
    // Set the new images
    formEdit.setFieldsValue({
      images: newImages,
    });
    setImagesKey((prevKey) => prevKey + 1);
  };

  // Edit Position Data
  const onFinishEdit = (values) => {
    const id = positionId;
    const formData = new FormData();

    const previousLogo = formEdit.getFieldValue("logo");
    const previousImages = formEdit.getFieldValue("images") || [];
    // Check if logoFileList exists to get the updated logo or fallback to the previous one
    const updatedLogo = logoFileList && logoFileList.originFileObj;

    //  append the new logo if changed, otherwise append the previous logo

    formData.append("id", id);
    if (updatedLogo) {
      formData.append("logo", updatedLogo);
    }

    // append the new image as inage
    if (imageFileList && imageFileList.length > 0) {
      imageFileList.forEach((image) => {
        if (image.originFileObj) {
          formData.append("image", image.originFileObj);
        }
      });
    }

    // append the previous images as oldImage array
    formData.append("oldImage", JSON.stringify(previousImages));
    formData.append("name", values.name);
    formData.append("active", values.active ? "true" : "false");
    formData.append("willProvide", values.willProvide ? "true" : "false");

    fetch(`${process.env.REACT_APP_API_BASE_URL}/positions/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token()}`,
        // contentType: "multipart/form-data",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.statusCode === 200) {
          responseNotification(t("position_updated_successfully!"), "success");
          fetchPositions();
          // window.location.reload();
          onCloseEdit();
          // formEdit.resetFields();
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("active", values.active ? "true" : "false");

    if (values.images && values.images.fileList) {
      values.images.fileList.forEach((image) => {
        if (image.originFileObj) {
          formData.append("image", image.originFileObj);
        }
      });
    }

    if (values.logo && values.logo.fileList) {
      const logo = values.logo.fileList[0]; // Assuming only one logo is selected
      if (logo && logo.originFileObj) {
        formData.append("logo", logo.originFileObj);
      }
    }

    formData.append("willProvide", values.willProvide ? "true" : "false");

    const authToken = token();

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/positions/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((response) => {
        console.log("Response:", response.data);
        // Handle the response here
        if (response.data.statusCode === 201) {
          responseNotification(t("position_created_successfully!"), "success");
          fetchPositions();
          onClose();
          form.resetFields();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle any errors here
      });
  };

  const fetchSinglePosition = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/positions/${positionId}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        },
      );
      setLoading(false);
      formEdit.setFieldsValue({
        name: res?.data?.details?.name,
        // slug: res?.data?.details?.slug,
        logo: `${res?.data?.details?.logo}`,
        images: Array.isArray(res?.data?.details?.images)
          ? res?.data?.details?.images.map((image) => `${image}`)
          : [],
        active: res?.data?.details?.active,
        willProvide: res?.data?.details?.willprovide, // Make sure the letter case matches the form field
      });
    } catch (err) {
      // console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchSinglePosition();
  }, [positionId, isEditModalOpen]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("slug"),
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: t("status"),
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        <Switch
          checked={text}
          onChange={async (checked) => {
            try {
              toast.promise(
                fetch(
                  `${process.env.REACT_APP_API_BASE_URL}/positions/update-status`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token()}`,
                    },
                    body: JSON.stringify({
                      id: record._id,
                      active: checked,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res?.statusCode === 200) {
                      fetchPositions();
                      return { message: res?.message };
                    }
                  }),
                {
                  loading: "Updating...",
                  success: (res) => "Position status updated successfully!",
                  error: (err) => err.message,
                },
              );
            } catch (error) {
              console.error("Error updating status:", error);
            }
          }}
        />
      ),
    },
    {
      title: t("action"),
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          style={{ background: "#C6A34F", color: "white" }}
          onClick={() => showEditDrawer(record._id)}
        >
          {t("edit")}
        </Button>
      ),
    },
  ];

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="title mb-4">{t("position_list")}</h3>
          <Button
            type="primary"
            style={{ background: "#C6A34F", color: "white" }}
            className="ml-5"
            onClick={showDrawer}
          >
            {t("add_position")}
          </Button>
        </div>
      </div>
      <div className="card sd">
        <Input
          placeholder={t("enter_name")}
          allowClear
          size="large"
          style={{
            width: 300,
            margin: "10px",
          }}
          onChange={onSearchChange}
        />
        <Table
          columns={columns}
          dataSource={positions}
          loading={loading}
          rowKey="_id"
          locale={{ emptyText: "No Data Found!" }}
          pagination={{
            pageSize: Number(pageSize),
            current: Number(currentPage),
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setpageSize(pageSize);
              navigate(`?page=${page}&limit=${pageSize}`);
            },
            total: totalData,
            showSizeChanger: true, // Add this line to enable the option
            pageSizeOptions: ["10", "20", "50", "100"], // Add this line to customize the options
          }}
        />
        {/* Add Drawer  */}
        <Drawer
          title="Add new position"
          width={520}
          closable={false}
          onClose={onClose}
          open={open}
        >
          <div className="drawer-toggle-wrapper">
            <div className="drawer-toggle-inner-wrapper">
              <Form form={form} onFinish={handleSubmit} style={formStyle}>
                <Form.Item
                  name="name"
                  label={t("name")}
                  style={itemStyle}
                  rules={[
                    { required: true, message: t("please_enter_a_name") },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="active"
                  label={t("active")}
                  style={itemStyle}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>

                <Form.Item name="images" label={t("images")} style={itemStyle}>
                  <Upload
                    fileList={imageFileList}
                    listType="picture"
                    onChange={handleImageChange}
                    multiple
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>

                <Form.Item name="logo" label={t("logo")} style={itemStyle}>
                  <Upload
                    fileList={logoFileList ? [logoFileList] : []}
                    onChange={handleLogoChange}
                    listType="picture"
                    beforeUpload={() => false}
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>

                <Form.Item
                  name="willProvide"
                  label={t("will_provide")}
                  style={itemStyle}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>

                <Form.Item style={itemStyle}>
                  <Button type="primary" htmlType="submit">
                    {t("submit")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Drawer>

        {/* Edit Drawer  */}
        <Drawer
          title={t("edit_position")}
          width={520}
          closable={false}
          onClose={onCloseEdit}
          open={isEditModalOpen}
        >
          <div className="drawer-toggle-wrapper">
            <div className="drawer-toggle-inner-wrapper">
              {loading ? (
                <>
                  <p>{t("loading...")}</p>
                </>
              ) : (
                <Form form={formEdit} onFinish={onFinishEdit} style={formStyle}>
                  <Form.Item
                    name="name"
                    label={t("name")}
                    style={itemStyle}
                    rules={[{ required: true, message: "Please enter a name" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="active"
                    label={t("active")}
                    style={itemStyle}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>

                  <div style={itemStyle}>
                    <p>
                      <b>{t("previous_logo")}</b>
                    </p>
                    {formEdit.getFieldValue("logo") && (
                      <div className="d-flex justify-content-between m-2">
                        <img
                          src={`${
                            process.env.REACT_APP_ASSETS_FRONTEND_URL
                          }/${formEdit.getFieldValue("logo")}`}
                          alt="positionLogo"
                          style={{ width: "50px", height: "50px" }}
                        />
                        {/* <Button
                        type="primary"
                        danger
                        onClick={handleRemoveLogo}
                      >
                        {removeButton}
                      </Button> */}
                      </div>
                    )}
                  </div>

                  <div key={imagesKey} style={itemStyle}>
                    <p>
                      <b>{t("previous_images")}</b>
                    </p>
                    {previousImages.map((image, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between m-2"
                      >
                        <img
                          src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${image}`}
                          alt={`positionImage-${index}`}
                          style={{ width: "50px", height: "50px" }}
                        />
                        <Button
                          type="primary"
                          danger
                          onClick={() => handleRemoveImage(image)}
                        >
                          {removeButton}
                        </Button>
                      </div>
                    ))}
                  </div>
                  <Form.Item
                    name="logoUpload"
                    label={t("upload_new_logo")}
                    style={itemStyle}
                  >
                    <Upload
                      fileList={logoFileList ? [logoFileList] : []}
                      onChange={handleLogoChange}
                      listType="picture"
                      beforeUpload={() => false}
                      // disabled={formEdit.getFieldValue("logo") ? true : false}
                    >
                      {uploadButton}
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name="imagesUpload"
                    label={t("upload_new_images")}
                    style={itemStyle}
                  >
                    <Upload
                      fileList={imageFileList || []}
                      listType="picture"
                      onChange={handleEditImageChange}
                      // onRemove={(file) => handleRemoveImage(file)}
                      multiple
                    >
                      {uploadButton}
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name="willProvide"
                    label={t("will_provide")}
                    style={itemStyle}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item style={itemStyle}>
                    <Button type="primary" htmlType="submit">
                      {t("submit")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default Position;
