import { Result } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { decryptData } from "../../../utils/secure-stoarage";

const CoursePaymentSuccess = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const data = localStorage.getItem("regData");
  const payload = data && decryptData(data);

  const handleSubmit = useCallback(async () => {
    if (!hasSubmitted) {
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/course-payment/create`,
          payload
        )
        .then((res) => {
          localStorage.removeItem("regData");
          setHasSubmitted(true);
        });
    }
  }, [hasSubmitted, payload]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (payload && Object.keys(payload).length > 0) {
        handleSubmit();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="container">
      <div className="card my-5 py-5">
        <Result
          status="success"
          title="Successfully Purchased!"
          subTitle="Congratulations on your successful purchase! 🎉 Your order has been processed, and a confirmation email has been sent to your inbox. Please check your email to access important details about your purchase and enjoy your new product or service. Thank you for choosing us, and we look forward to serving you again in the future!"
        />
      </div>
    </div>
  );
};

export default CoursePaymentSuccess;
