import { Form, Input } from 'antd';
import React from 'react';
import { Fo } from 'react-flags-select';

const Translate = () => {
    const [form] = Form.useForm();
    return (
        <div>
            <Form className="row mt-2"  form={form}>
                <Form.Item
                    label="Main Text"
                    name="main"
                    rules={[
                        {
                            required: true,
                            message: "Please enter current password",
                        },
                    ]}
                >
                    <Input
                        placeholder="Enter current password"
                        className="ant-input ant-input-lg"
                        value="main"
                       
                    />
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="key"
                    
                    
                >
                    <Input
                        placeholder="Enter new password"
                        className="ant-input ant-input-lg"
                        value="key"
                        
                    />
                </Form.Item>
                <Form.Item
                    label="Trnaslate"
                    name="translate"
                    >
                    <Input
                        placeholder="Enter new password"
                        className="ant-input ant-input-lg"
                        value="translate"
                        />
                    </Form.Item>
                <button className="btn btn-primary" 
                    onClick={() => {
                        const main = form.getFieldValue("main");
                        const cutFirstandLastSpace = main.trim();
                        // replce space with underscore and convert to lowercase
                        const key = cutFirstandLastSpace.replace(/\s+/g, "_").toLowerCase();
                        const data = '"'+key+'" : "'+cutFirstandLastSpace+'"';
                        form.setFieldsValue({ key: data });
                        form.setFieldsValue({ translate: 
                            '{t("' + key + '")}'
                        });
                    }}
                >Save</button>
                </Form>
        </div>
    );
};

export default Translate;