import axios from "axios";
import { token } from "../../utils/authentication";

// fetch api call
// api/skill.js (where fetchHandler is defined)

export const fetchHandler = async (page = 1, limit = 10) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/skills?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    return res;
  };
  

//fetch list for dropdown
export const fetchSkillListForDropdownHandler = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/skills/list-for-dropdown?skipLimit=YES`,
        {
            headers: {
                Authorization: `Bearer ${token()}`,
            },
        }
    );
    return res;
};

// add api call
export const addHandler = async (receivedPositionFields) => {
    const res = fetch(`${process.env.REACT_APP_API_BASE_URL}/skills/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(receivedPositionFields),
    });
    return res;
};

//update api call
export const updateSkillHandler = async (receivedPrintingFields) => {
    let res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/skills/update`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token()}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(receivedPrintingFields),
    });
    return res;
};