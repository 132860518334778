import React, { Fragment, useCallback, useEffect, useState } from "react";
import CourseDetailsModal from "./CourseDetailsModal";
import axios from "axios";
import moment from "moment";

const AcademyCard = ({
  course,
  price,
  duration,
  description,
  comming,
  example,
  id,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Fragment>
      <div
        className="card pb-5 pt-3"
        style={{
          borderRadius: "20px",
          background: "#fff",
          backdropFilter: "blur(10px)",
          boxShadow: "rgba(0, 0, 0, 0.1) -8px 0px 14px 10px",
        }}
      >
        <div className="card-body">
          <div
            style={{
              backgroundColor: "#C6A34F",
              borderRadius: "15px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="  px-3   "
          >
            <h5
              className="text-white m-0"
              style={{
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {course}
            </h5>
          </div>
          <div className="mx-2 ">
            <p
              style={{
                color: "#7B7B7B",
                font: "Montserrat",
                fontWeight: 400,
                fontSize: "15px",
                marginRight: "0%",
                textAlign: "center",
              }}
              className="mt-2"
            >
              {description}
            </p>
            {price}
            {duration}

            <p
              style={{
                color: "#7B7B7B",
                font: "Montserrat",
                fontWeight: 400,
                fontSize: "13px",
                marginRight: "10%",
              }}
              className=""
            >
              {example}
            </p>

            <h5 className="text-center">{comming}</h5>

            <div className="d-flex justify-content-between">
              <a
                href="mailto:academy@mhpremierstaffingsolutions.com?subject=Hello%20MH%20Team"
                style={{
                  backgroundColor: "#C6A34F",
                  color: "white",
                  border: "1px solid #C6A34F",
                  marginLeft: "-15px",
                  borderRadius: "15px",
                  textDecoration: "none",
                }}
                className="px-4 mx-3 py-1 mt-3 text-center mb-3"
              >
                Contact Us
              </a>
              <button
                style={{
                  backgroundColor: "#C6A34F",
                  color: "white",
                  border: "1px solid #C6A34F",
                  marginLeft: "-15px",
                  borderRadius: "15px",
                  textDecoration: "none",
                }}
                onClick={() => {
                  showModal();
                }}
                className="px-4 mx-3 py-1 mt-3 text-center mb-3"
              >
                Enroll
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CourseDetailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          title={course}
          id={id}
        />
      )}
    </Fragment>
  );
};

export default AcademyCard;
