import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { iconsList } from "./common";

const IconSelectorModal = ({ visible, onCancel, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Backspace" && !searchTerm) {
        // Clear the search input if Backspace is pressed and it's already empty
        inputRef.current.focus(); // Focus on the input
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredIcons = iconsList.filter((icon) =>
    icon.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      title={
        <div>
          <span>Select Icon</span>
          <Input
            ref={inputRef}
            placeholder="Search icon"
            prefix={<AiOutlineSearch />}
            value={searchTerm}
            onChange={handleSearch}
            style={{ marginLeft: 10, width: 200 }}
          />
        </div>
      }
      visible={visible}
      width={600}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <div style={{ maxHeight: 400, overflowY: "auto" }}>
        <Row className="p-2">
          {filteredIcons.map((icon) => (
            <Col key={icon} span={2}>
              <div
                className="icon-option m-2"
                onClick={() => onSelect(icon)}
                style={{ cursor: "pointer" }}
              >
                <i className={`icon ${icon}`} />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Modal>
  );
};

export default IconSelectorModal;
