import { Card, Col, Row, Tag } from "antd";
import { addMonths } from "date-fns";
import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { token } from "../../../utils/authentication";
// Custom Navbar component

export default function EmployeeCalendarView() {
  const { id } = useParams();
  const [bookedDates, setBookedDates] = useState([]);
  const [pendingDates, setPendingDates] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [EmployeeViewDetails, setEmployeeViewDetails] = useState([]);
  const navigate = useNavigate();
  // Get the current month
  const currentMonth = new Date();
  currentMonth.setDate(1);
  currentMonth.setHours(0, 0, 0, 0);

  // Get the next month
  const nextMonth = addMonths(currentMonth, 1);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/working-history/${id}`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setEmployeeViewDetails(data.result.employeeDetails);
        setBookedDates(formatDates(data.result.bookedDates));
        setPendingDates(formatDates(data.result.pendingDates));
        setUnavailableDates(formatDates(data.result.unavailableDates));
      }
    };

    fetchData();
  }, [id]);
  // console.log(EmployeeViewDetails);

  const formatDates = (dates) => {
    return dates.map((dateRange) => ({
      from: new Date(dateRange.startDate),
      to: new Date(dateRange.endDate),
    }));
  };
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Row justify="center" align="middle" style={{ marginTop: "2rem" }}>
      <Col>
        <div className="container mt-3">
          <span
            // type="default"
            onClick={goBack}
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
              border: "2px solid #C6a34f",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            <IoChevronBackCircleOutline
              size={20}
              backgroundColor="#C6a34f"
              color="#C6a34f"
            />
          </span>
        </div>
        <Card style={{ width: 350 }}>
          <div
            style={{
              margin: "20px",
            }}
          >
            <h6>It's {EmployeeViewDetails.name}'s calender</h6>
          </div>
          <DayPicker
            fromMonth={currentMonth} // Set the earliest month to the current month
            toMonth={nextMonth} // Set the latest month to the next month
            disabled={[
              ...bookedDates.map((date) => ({ from: date.from, to: date.to })),
              ...pendingDates.map((date) => ({ from: date.from, to: date.to })),
              ...unavailableDates.map((date) => ({
                from: date.from,
                to: date.to,
              })),
            ]}
            modifiers={{
              booked: bookedDates,
              pending: pendingDates,
              unavailable: unavailableDates,
            }}
            modifiersStyles={{
              booked: {
                color: "#00C92C",
                cursor: "not-allowed",
                pointerEvents: "none",
              },
              pending: {
                color: "#C6a34f",
                cursor: "not-allowed",
                pointerEvents: "none",
              },
              unavailable: {
                color: "#FF5029",
              },
            }}
          />
        </Card>
        <Row justify="center" style={{ margin: "1rem" }}>
          <Col>
            <Tag color="#00C92C">Booked</Tag>
          </Col>
          <Col>
            <Tag color="#C6a34f">Pending</Tag>
          </Col>
          <Col>
            <Tag color="#FF5029">Unavailable</Tag>
          </Col>
          <Col>
            <Tag color="#000">Available</Tag>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
