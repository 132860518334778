import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Header from "./Header";

function DashboardLayout() {
  return (
    <div>
      {/* <TawkMessengerReact
        propertyId="63ce628cc2f1ac1e202f1e7f"
        widgetId="1gnf31u24"
      > */}
      <Header />
      <Outlet />
      <Footer />
      {/* </TawkMessengerReact> */}
    </div>
  );
}

export default DashboardLayout;
