import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import the English locale
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { token } from "../../../utils/authentication";
import { useTranslation } from "react-i18next";
dayjs.locale("en");

const CoursePromotion = () => {
  const {t} = useTranslation();
  const [coursePromotions, setCoursePromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // Define courseId and editFormData for editing
  const [courseId, setCourseId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);

  const openEditModal = (record) => {
    console.log("record", record);
    // Set the course data to be edited in the form fields
    const formattedValidTill = moment(record?.validTill, "YYYY-MM-DD"); // Format the date
    form.setFieldsValue({
      couponCode: record?.couponCode,
      discountType: record?.discountType,
      amountType: record?.amountType,
      amount: record?.amount,
      validTill: formattedValidTill,
      maxUserLimit: record?.maxUserLimit,
    });

    // Set courseId and editFormData for editing
    setCourseId(record._id);
    setEditFormData(record);

    // Show the edit modal
    setIsEditModalVisible(true);
  };

  console.log("coursePromotions edit", editFormData);

  const [form] = Form.useForm(); // Ant Design form instance

  const columns = [
    {
      title: t("serial_no"),
      dataIndex: "serial", // You need to include a "serial" field in your data
      key: "serial",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: t("coupon_code"),
      dataIndex: "couponCode",
      key: "couponCode",
    },
    {
      title: t("discount_type"),
      dataIndex: "discountType",
      key: "discountType",
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{`${text} ${record?.amountType === "amount" ? "£" : "%"}`}</span>
      ),
    },
    {
      title: t("used_times"),
      dataIndex: "used",
      key: "used",
    },
    {
      title: t("max_use"),
      dataIndex: "maxUserLimit",
      key: "maxUserLimit",
    },
    {
      title: t("valid_till"),
      dataIndex: "validTill",
      key: "validTill",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("action"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => openEditModal(record)}>
            {t("edit")}
          </Button>
          <Popconfirm
            title={t("are_you_sure_you_want_to_delete_this_course")}
            onConfirm={() => deleteCourse(record._id)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button
              type="danger"
              style={{
                backgroundColor: "#E23821",
                marginLeft: "10px",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {t("delete")}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/course-promotion?limit=10&page=1`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === "success" && data.CoursePromotions) {
        setCoursePromotions(data.CoursePromotions);
      }
    } catch (error) {
      console.error("Error fetching course promotions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      // Validate the form fields
      const values = await form.validateFields();
      // console.log("Received values of form: ", values);
      // Prepare the data to send to the API
      const requestData = {
        discountType: values.discountType,
        amountType: values.amountType,
        amount: values.amount,
        couponCode: values.couponCode,
        validTill: values.validTill.format("YYYY-MM-DD"),
        maxUserLimit: values.maxUserLimit,
      };

      // Make a POST request to create the coupon
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/course-promotion/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        // Coupon creation was successful
        setIsModalVisible(false);
        // You may want to update the coupon list here
        fetchData();
        toast.success("Coupon created successfully");
        form.resetFields();
      } else {
        // Handle the case where coupon creation failed
        console.error("Coupon creation failed:", data.message);
      }
    } catch (error) {
      // Handle any form validation errors or API request errors
      console.error("Error creating coupon:", error);
    }
  };
  const deleteCourse = (courseId) => {
    // Implement the logic to delete the course here
    console.log(`Deleting course with ID: ${courseId}`);

    // Use the provided API to delete the course based on courseId
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/course-promotion/${courseId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success("Course deleted successfully");
          fetchData();
        } else {
          // Handle the deletion error, if any.
          console.error("Error deleting course:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error deleting course:", error);
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setCourseId(null);
    // Handle the modal
    window.location.reload();
    form.resetFields();
  };

  const handleEditModalOk = (record) => {
    form
      .validateFields()
      .then((values) => {
        // Send a request to update the course with the edited values
        const updatedCourse = {
          ...values,
          id: courseId,
          validTill: values.validTill.format("YYYY-MM-DD"), // Format date
        };
        console.log("Received values of form: ", courseId);
        // Send a request to update the course with updatedCourse data
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/course-promotion/update`, // Use the appropriate URL for updating
          {
            method: "PUT", // Use the appropriate HTTP method for updating
            headers: {
              Authorization: `Bearer ${token()}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedCourse),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              // Handle the successful update, e.g., refresh the course list
              toast.success("Course updated successfully");
              fetchData();
              setIsEditModalVisible(false);
            } else {
              // Handle the update error, if any
              console.error("Error updating course:", data.message);
            }
          })
          .catch((error) => {
            console.error("Error updating course:", error);
          });
      })
      .catch((errorInfo) => {
        console.log("Validation error", errorInfo);
      });
  };
  const handleEditModalCancel = () => {
    console.log("Closing Edit Coupon Modal");
    setIsEditModalVisible(false);
    setEditFormData(null);
    window.location.reload();
    // Reset the modal
    form.resetFields();
  };

  return (
    <div>
      <h2>{t("course_promotions")}</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        <Button
          style={{
            backgroundColor: "#C6A34F",
          }}
          type="primary"
          onClick={handleCreateClick}
        >
          {t("create_coupon")}
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={coursePromotions}
        loading={loading}
        pagination={{
          pageSize: 10, // Number of items per page
        }}
      />
      <Modal
        title={t("create_coupon")}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={t("create_coupon")}
        cancelText={t("cancel")}
      >
        <Form form={form}>
          <Form.Item
            label={t("discount_type")}
            name="discountType"
            labelCol={{ span: 8 }}
          >
            <Select style={{ width: "100%" }}>
              <Select.Option value="COUPON">Coupon</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t("coupon_code")}
            name="couponCode"
            labelCol={{ span: 8 }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label={t("amount_type")}
            name="amountType"
            labelCol={{ span: 8 }}
          >
            <Select style={{ width: "100%" }}>
              <Select.Option value="percent">Percent</Select.Option>
              <Select.Option value="amount">Amount</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label={t("amount")} name="amount" labelCol={{ span: 8 }}>
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label={t("valid_till")} name="validTill" labelCol={{ span: 8 }}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label={t("max_user_limit")}
            name="maxUserLimit"
            labelCol={{ span: 8 }}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("edit_coupon")}
        visible={isEditModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        okText={t("update_coupon")}
        cancelText={t("cancel")}
      >
        <Form form={form} initialValues={editFormData}>
          <Form.Item
            label={t("discount_type")}
            name="discountType"
            labelCol={{ span: 8 }}
          >
            <Select style={{ width: "100%" }}>
              <Select.Option value="COUPON">Coupon</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t("coupon_code")}
            name="couponCode"
            labelCol={{ span: 8 }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label={t("amount_type")}
            name="amountType"
            labelCol={{ span: 8 }}
          >
            <Select style={{ width: "100%" }}>
              <Select.Option value="percent">Percent</Select.Option>
              <Select.Option value="amount">Amount</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={
            t("amount")
          } name="amount" labelCol={{ span: 8 }}>
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label={
            t("valid_till")
          } name="validTill" labelCol={{ span: 8 }}>
            <Tooltip title="Please deselect the date with (x) to set a new date">
              <DatePicker
                style={{ width: "100%" }}
                // format="YYYY-MM-DD"
                // defaultValue={moment(editFormData?.validTill, "YYYY-MM-DD")}
                value={moment(editFormData?.validTill)}
              />
            </Tooltip>
          </Form.Item>

          <Form.Item
            label={t("max_user_limit")}
            name="maxUserLimit"
            labelCol={{ span: 8 }}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CoursePromotion;
