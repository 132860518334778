/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { BiMap } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import CountryWiseValidationRules from "../../../utils/static/countryList";
import MapModal from "./MapModal";
import { useTranslation } from "react-i18next";
import MyCard from "./Card";
import { getUserId, getUserInfo } from "../../../utils/commonFunctions";

const Profile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [clientForm] = Form.useForm();
  const [bankform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [bankUpdateLoading, setBankUpdateLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const jwtDecode = jwtTokenDecode();
  const [mapModal, setMapModal] = useState(false);
  const [myLocation, setMyLocation] = useState({});
  const [name, setName] = useState("");
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [prevImage, setPrevImage] = useState("");
  const [isHovered, setHovered] = useState(false);

  const navigate = useNavigate();

  const fetchClientData = async () => {
    setLoading(true);
    setClientLoading(true);
    setBankUpdateLoading(true);
    setCardLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${jwtDecode._id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setLoading(false);
      setClientLoading(false);
      setBankUpdateLoading(false);
      setCardLoading(false);
      setUserInfo(res.data);
      setCardInfo(JSON.parse(res?.data?.details?.sourceOfFunds));
      setPrevImage(res?.data?.details?.profilePicture);
      clientForm.setFieldsValue({
        clientDiscount: res?.data?.details.clientDiscount,
        vatNumber: res?.data?.details.vatNumber,
        companyRegisterNumber: res?.data?.details.companyRegisterNumber,
        countryName: res?.data?.details.countryName,
        restaurantAddress: res?.data?.details.restaurantAddress,
      });
      bankform.setFieldsValue({
        bankName: res?.data?.details.bankName || "",
        accountNumber: res?.data?.details.accountNumber,
        routingNumber: res?.data?.details.routingNumber,
        dressSize: res?.data?.details.dressSize,
        companyName: res?.data?.details?.companyName || "",
        additionalOne: res?.data?.details.additionalOne,
        additionalTwo: res?.data?.details.additionalTwo,
        additionalThree: res?.data?.details.additionalThree,
      });
    } catch (error) {
      setLoading(false);
      setClientLoading(false);
    }
  } 

  useEffect(() => {
    fetchClientData();
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      restaurantName: userInfo?.details?.restaurantName,
      restaurantAddress: userInfo?.details?.restaurantAddress,
      phoneNumber: userInfo?.details?.phoneNumber,
      email: userInfo?.details?.email,
      lat: userInfo?.details?.lat,
      lng: userInfo?.details?.long,
    });
    setName(userInfo?.details?.restaurantAddress);
    setMyLocation({
      lat: parseFloat(userInfo?.details?.lat),
      lng: parseFloat(userInfo?.details?.long),
    });
  }, [userInfo]);

  const handleUpdateProfile = async () => {
    const values = await form.getFieldsValue(true);
    const updateProfileReceivedFields = {
      id: jwtDecode._id,
      restaurantName: values?.restaurantName,
      email: values?.email,
      phoneNumber: values?.phoneNumber,
      restaurantAddress: values?.restaurantAddress,
      lat: values?.lat || myLocation?.lat,
      long: values?.lng || myLocation?.lng,
    };
    if (updateProfileReceivedFields) {
      setLoading(true);
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-client`,
          updateProfileReceivedFields,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        if (res.data) {
          responseNotification(res?.data?.message, "success");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    // update profile picture
    if (image.raw) {
      const formData = new FormData();
      formData.append("file", image.raw);
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-profile-image`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        if (res.data) {
          responseNotification(res?.data?.message, "success");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const clientFormSubmit = async () => {
    const values = clientForm.getFieldsValue(true);
    const receivedClientFields = {
      id: jwtDecode._id,
      clientDiscount: values?.clientDiscount,
      vatNumber: values?.vatNumber,
      companyRegisterNumber: values?.companyRegisterNumber,
      countryName: values?.countryName,
    };
    try {
      setClientLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/users/update-client-discount`,
        receivedClientFields,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (res?.data?.statusCode === 200) {
        setClientLoading(false);
        // setError(undefined);
        responseNotification(
          "Client information updated successfully!",
          "success"
        );
      } else if (res?.data?.statusCode === 400) {
        // setError(res?.data?.errors?.[0].msg);
        setClientLoading(false);
      } else if (res?.data?.statusCode === 500) {
        // setError(res?.message);
        setClientLoading(false);
      }
    } catch (error) {
      // setError(error?.response?.data?.errors?.[0].msg);
      setClientLoading(false);
    }
  };
  const onBankFinish = async () => {
    const values = bankform.getFieldsValue(true);
    const id = jwtDecode._id;
    const receivedEmployeeFields = {
      id: id,
      bankName: values?.bankName,
      accountNumber: values?.accountNumber,
      routingNumber: values?.routingNumber,
      dressSize: values?.dressSize,
      companyName: values?.companyName,
      additionalOne: values?.additionalOne,
      additionalTwo: values?.additionalTwo,
    };

    try {
      if (id && receivedEmployeeFields) {
        setBankUpdateLoading(true);

        const res = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-bank-dress`,
          receivedEmployeeFields,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (res?.data?.statusCode === 200) {
          // setError(undefined);
          setBankUpdateLoading(false);
          responseNotification("Client bank updated successfully!", "success");
        } else if (res?.data?.statusCode === 400) {
          // setError(res?.data?.errors?.[0].msg);
          setBankUpdateLoading(false);
        } else if (res?.data?.statusCode === 500) {
          // setError(res?.message);
          setBankUpdateLoading(false);
        }
      }
    } catch (error) {
      // setError(error?.response?.data?.errors?.[0].msg);
      setBankUpdateLoading(false);
    }
  };
  const onCardAdd = async () => {
    setCardLoading(true);

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/users/get-session`, {
          email: getUserInfo().email,
          returnUrl: window.location.href,
        })
        .then((res) => {
          navigate("/card-verify?sessionId=" + res.data.details.id);
        });
    } catch (error) {
      setCardLoading(false);
      console.error("Error fetching session ID:", error);
    }
  };
  const onCardDelete = async () => {
    setCardLoading(true);
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/users/remove-token`, {
          id: getUserId(),
        })
        .then((res) => {
          setTrigger(!trigger);
          setCardInfo(undefined);
          setCardLoading(false);
        });
    } catch (error) {
      setCardLoading(false);
      console.error("Error deleting card:", error);
    }
  };
  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const UploadButton = (
    <div
      style={{
        background: "black",
        color: "white",
        width: "95px",
        height: "95px",
        borderRadius: "50%",
        position: "absolute",
        top: "5px",
        left: "5px",
        opacity: "0.6",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <i
        class="fa fa-camera"
        style={{ fontSize: "30px", color: "white", opacity: "1" }}
      ></i>
    </div>
  );
  return (
    <div>
      <div className="container-fluid col-md-12 col-sm-12 mb-3 w-75">
        <div className="row mt-4">
          <div className="d-flex mb-4 align-items-md-center">
            <button className="commonBackButton" onClick={goBack}>
              <img
                src="assets/frontend/images/InnerDashboard/arrow.png"
                className="img-fluid"
                alt="arrow"
              />
            </button>
            <h4 className="title mt-2">{t("profile")}</h4>
          </div>
        </div>
        <div className="card">
          <div className="card-header">{t("profile_info")}</div>
          <div className="card-body">
            <Form
              className="ant-form ant-form-vertical"
              layout="vertical"
              onFinish={() => {
                handleUpdateProfile();
              }}
              form={form}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="d-flex justify-content-center">
                    <label htmlFor="upload-button">
                      {image.preview ? (
                        <div
                          style={{
                            position: "relative",
                          }}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <img
                            src={image.preview}
                            alt="dummy"
                            style={{
                              width: "105px",
                              height: "105px",
                              borderRadius: "50%",
                              padding: "5px",
                              border: "1px dashed #C6A34F",
                              objectFit: "cover",
                            }}
                            onMouseEnter={() => setHovered(true)}
                          />
                          {isHovered && <>{UploadButton}</>}
                        </div>
                      ) : (
                        <>
                          <span className="mb-1">
                            {prevImage ? (
                              <div
                                style={{
                                  position: "relative",
                                }}
                                onMouseLeave={() => setHovered(false)}
                              >
                                <img
                                  style={{
                                    width: "105px",
                                    height: "105px",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    border: "1px dashed #C6A34F",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    prevImage
                                      ? process.env.REACT_APP_ASSETs_BASE_URL +
                                        "/" +
                                        prevImage
                                      : null
                                  }
                                  className="Dashboard2-card-img-top img-fluid" // Make the image responsive
                                  alt="custom-profile"
                                  onMouseEnter={() => setHovered(true)}
                                />
                                {isHovered && <>{UploadButton}</>}
                              </div>
                            ) : (
                              <>
                                <img
                                  alt="default"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    marginTop: "10px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                  src="assets/frontend/images/registrationFormImages/employeeFormPictures/uploadeImage.png"
                                />
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </label>
                    <input
                      type="file"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label={t("restaurant_name")}
                      name="restaurantName"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_restaurant_name"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_restaurant_name")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="col-md-6"
                    style={{
                      position: "relative",
                    }}
                  >
                    <Form.Item
                      label={t("restaurant_address")}
                      name="restaurantAddress"
                      hasFeedback
                      style={{
                        paddingRight: "45px",
                      }}
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_restaurant_address"),
                        },
                      ]}
                    >
                      <Input
                        disabled
                        placeholder={t("enter_restaurant_address")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                    <Button
                      type="default"
                      onClick={() => {
                        setMapModal(true);
                      }}
                      style={{
                        position: "absolute",
                        top: "30px",
                        right: "12px",
                      }}
                    >
                      <BiMap />
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label={t("phone_number")}
                      name="phoneNumber"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_phone_number"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_phone_number")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label={t("email")}
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_email"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_email")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item>
                      <button
                        disabled={loading}
                        className="btn"
                        style={{ background: "#C6A34F", color: "white" }}
                        type="submit"
                      >
                        {!loading && t("update")}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            {t("please_wait...")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header">{t("update_client_information")}</div>
          <div className="card-body">
            <div>
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={clientFormSubmit}
                form={clientForm}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label={t("discount_amount")}
                        name="clientDiscount"
                        hasFeedback
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter discount",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_discount")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label={t("vat_number")}
                        name="vatNumber"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_vat_number")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label={t("company_register_number")}
                        name="companyRegisterNumber"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_company_register_number")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <Form.Item
                        label={t("country_name")}
                        name="countryName"
                        hasFeedback
                      >
                        <Select
                          showSearch={true}
                          placeholder={t("please_select_country_name")}
                          optionFilterProp="children"
                          disabled={true}
                        >
                          {CountryWiseValidationRules?.map((item, index) => (
                            <Select key={index} value={item?.name}>
                              {item?.name}
                            </Select>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                {/* {getError ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="error-message">
                      <p className="text-danger">{getError}</p>
                    </div>
                  </div>
                </div>
              ) : undefined} */}

                <div className="col-md-6">
                  <Form.Item>
                    <button
                      disabled={clientLoading}
                      className="btn"
                      style={{ background: "#C6A34F", color: "white" }}
                      type="submit"
                    >
                      {!clientLoading && t("submit")}
                      {clientLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header">{t("bank_information")}</div>
          <div className="card-body">
            <div>
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onBankFinish}
                form={bankform}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        label={t("bank_name")}
                        name="bankName"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_bank_name")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("account_number")}
                        name="accountNumber"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_bank_account_number")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("sort_code")}
                        name="routingNumber"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_sort_code")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    {/* <div className="col-md-4">
                      <Form.Item
                        label="Dress Size"
                        name="dressSize"
                        hasFeedback
                        rules={[
                          {
                            message: "Please enter dress size",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter dress size"
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div> */}

                    <div className="col-md-4">
                      <Form.Item
                        label={t("company_name")}
                        name="companyName"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_company_name")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_one")}
                        name="additionalOne"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_additional_one")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_two")}
                        name="additionalTwo"
                        hasFeedback
                      >
                        <Input
                          placeholder={t("enter_additional_two")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                {/* {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="text-danger">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined} */}

                <div className="col-md-6">
                  <Form.Item>
                    <button
                      disabled={bankUpdateLoading}
                      className="btn"
                      style={{ background: "#C6A34F", color: "white" }}
                      type="submit"
                    >
                      {!bankUpdateLoading && t("update_bank")}
                      {bankUpdateLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header">Crad Information</div>
          <div className="card-body">
            <div>
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={cardInfo?.provided ? onCardDelete : onCardAdd}
                form={bankform}
              >
                {
                 cardInfo?.provided ?  <MyCard cardInfo={cardInfo} /> : null
                }
                {!cardInfo?.provided ? (
                  <div className="col-md-6">
                    <Form.Item>
                      <button
                        disabled={cardLoading}
                        className="btn"
                        style={{ background: "#C6A34F", color: "white" }}
                        type="submit"
                      >
                        {!cardLoading && "Add Card"}
                        {cardLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            {t("please_wait...")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </Form.Item>
                  </div>
                ) : (
                  <div className="col-md-6">
                    {<Form.Item>
                      <button
                        disabled={cardLoading}
                        className="btn"
                        style={{ background: "#C6A34F", color: "white" }}
                        type="submit"
                      >
                        {!cardLoading && "Remove Card"}
                        {cardLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            {t("please_wait...")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </Form.Item>}
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>

        <MapModal
          isModalOpen={mapModal}
          setIsModalOpen={setMapModal}
          myLocation={myLocation}
          setMyLocation={setMyLocation}
          name={name}
          setName={setName}
          form={form}
        />
      </div>
    </div>
  );
};

export default Profile;
