// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDj8C1VcZWPlnRrHMc_2VjMLVZ3HmVxdWw",
  authDomain: "mirko-hospitality-ea7e2.firebaseapp.com",
  projectId: "mirko-hospitality-ea7e2",
  storageBucket: "mirko-hospitality-ea7e2.appspot.com",
  messagingSenderId: "831689638350",
  appId: "1:831689638350:web:2232d356fcb0da4bdd2b9f",
  measurementId: "G-9CYG79SDEF",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore(app);
export const messaging = getMessaging(app);
