
export const staticMenuPermission = [
    { name: "POSITION" },
    { name: "SKILL" },
    { name: "SOURCE" },
    { name: "CLIENT_LIST" },
    { name: "EMPLOYEE_LIST" },
    { name: "MH_EMPLOYEE_LIST" },
    { name: "ADD_MH_EMPLOYEE" },
    { name: "NOTIFICATION_LIST" },
    { name: "INVOICE_LIST" },
    { name: "CONTACT_LIST" },
    { name: "MH_ACADEMY" },
    { name: "EMPLOYEE_PAYMENT_HISTORY" }
];