import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import Contact from "../Home/Contact";
import NotFound from "../employee/notFound/notFound";
import AboutBranch from "./AboutBranch";
import ClientTestimonials from "./ClientTestimonials";
import HeroSection from "./Hero";
import LeaderShip from "./LeaderShip";
import MissionVission from "./MissionVission";
import TechDriverApproach from "./TechDriverApproach";
import TrustedPartner from "./TrustedPartner";

export default function AboutUs() {
  const { slug } = useParams();
  const [aboutUsDetails, setAboutUsDetails] = useState(null);

  useEffect(() => {
    const fetchAboutUsDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/about-us/${slug}`
          // {
          //   headers: {
          //     Authorization: `Bearer ${token()}`,
          //     "Content-Type": "application/json",
          //   },
          // }
        );

        setAboutUsDetails(response.data.details);
      } catch (error) {
        console.error("Error fetching about us details:", error);
      }
    };

    fetchAboutUsDetails();
  }, [slug]);

  if (aboutUsDetails === null) {
    return <Loader />;
  }
  return (
    <div
      className=""
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* Render components based on aboutUsDetails */}
      {aboutUsDetails && (
        <>
          <HeroSection
            county={aboutUsDetails.countryName}
            title={aboutUsDetails.title}
            subTitle={aboutUsDetails.subTitle}
            url={aboutUsDetails.url}
            contentType={aboutUsDetails.contentType}
          />

          <AboutBranch branchData={aboutUsDetails.branch} />
          <LeaderShip leaderShipData={aboutUsDetails.leadership} />
          <MissionVission missionAndVision={aboutUsDetails.missionAndVision} />
          <TechDriverApproach />
          <ClientTestimonials testimonials={aboutUsDetails.testimonials} />
          <TrustedPartner partners={aboutUsDetails.partners} />
          <Contact />
        </>
      )}
      {aboutUsDetails === undefined && (
        <>
          <>
            <NotFound title={"About Us Not Found"} description={""} />
          </>
        </>
      )}
    </div>
  );
}
