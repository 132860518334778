import React, { useState, useEffect } from "react";
import { Form, Select, Button, message } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { token } from "../../../utils/authentication";
import toast from "react-hot-toast";
import GoBack from "../../../utils/goBack";

const { Option } = Select;

const CreatePermission = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const [controllerOptions, setControllerOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("United Kingdom");

  useEffect(() => {
    fetchRoles(selectedCountry);
  }, [selectedCountry]);

  const fetchRoles = (country) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/roles/list-for-dropdown?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setRoleOptions(response.data.roles);
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const formik = useFormik({
    initialValues: {
      role: "",
      controller: "",
      services: [],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/permission/create`,
          {
            role: values.role,
            service: values.services,
          },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        console.log("Permission assigned successfully:", response.data);
        toast.success("Permission assigned successfully");
        resetForm();
      } catch (error) {
        console.error("Error assigning permission:", error);
        message.error("Error assigning permission");
      }
    },
  });

  const handleRoleChange = (value) => {
    formik.setFieldValue("role", value);
    fetchControllers(value);
  };

  const fetchControllers = (roleId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/service/controller-dropdown`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setControllerOptions(response.data.services);
        }
      })
      .catch((error) => {
        console.error("Error fetching controllers:", error);
      });
  };

  const handleControllerChange = (value) => {
    formik.setFieldValue("controller", value);
    fetchServices(value);
  };

  const fetchServices = (controllerId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/service/list-for-dropdown?controller=${controllerId}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setServiceOptions(response.data.services);
        }
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  };

  const handleServiceChange = (values) => {
    formik.setFieldValue("services", values);
  };

  return (
    <div className="container mx-auto">
      <GoBack />

      <h1>Assign Permissions</h1>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item label="Select Country">
          <Select defaultValue={selectedCountry} onChange={handleCountryChange}>
            <Option value="United Kingdom">United Kingdom</Option>
            <Option value="United Arab Emirates">United Arab Emirates</Option>
            <Option value="Italy">Italy</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Select Role">
          <Select value={formik.values.role} onChange={handleRoleChange}>
            {roleOptions.map((role) => (
              <Option key={role._id} value={role._id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Controller">
          <Select
            value={formik.values.controller}
            onChange={handleControllerChange}
          >
            {controllerOptions.map((controller) => (
              <Option key={controller._id} value={controller.controller}>
                {controller.controller}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Service(s)">
          <Select
            mode="multiple"
            value={formik.values.services}
            onChange={handleServiceChange}
          >
            {serviceOptions.map((service) => (
              <Option key={service._id} value={service._id}>
                {service.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Assign Permission
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreatePermission;
