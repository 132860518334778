import { Button, Modal, Result } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";

function PaymentSuccess() {
  const id = localStorage.getItem("invoiceId");
  console.log("id: ", id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const showInvoiceModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // Add your code to handle OK button click, like downloading the actual invoice
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (id !== null) {
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/invoices/update-status`,
          {
            id,
            status: "PAID",
          },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          console.log("res: ", res);
          if (res.data.status) {
            localStorage.removeItem("invoiceId");
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          // localStorage.removeItem("invoiceId");
          navigate("/invoice/payment-error");
        });
    }
  }, [id]);

  return (
    <>
      <div>
        <Result
          status="success"
          title="Stripe Payment Successful"
          subTitle={`Your payment was processed successfully`}
          extra={[
            <Button type="primary" onClick={showInvoiceModal} key="download">
              Download Invoice
            </Button>,
            <Button key="dashboard">
              <Link
                style={{
                  // color: 'white',
                  textDecoration: "none",
                }}
                to="/payment-invoice"
              >
                Go Back
              </Link>
            </Button>,
          ]}
        />
        <Modal
          title="Download Invoice"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Your invoice is ready for download. Click OK to proceed.</p>
        </Modal>
      </div>
    </>
  );
}

export default PaymentSuccess;
