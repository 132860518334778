import React from "react";
import "./card.scss";
import { getUserInfo } from "../../../utils/commonFunctions";

const MyCard = ({
  cardInfo
}) => {
  console.log( "cardInfo ========>>> ",cardInfo)
  const userInfo = getUserInfo();
  console.log("userInfo ========>>> ",userInfo)
  return (
    <div className="CardSection">
      <div className="container">
        <div className="card">
          <div className="card-inner">
            <div className="front">
              <img
                src="https://i.ibb.co/PYss3yv/map.png"
                alt="Map"
                className="map-img"
              />
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "24px",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <p>{cardInfo?.provided?.card?.number.slice(0, 4)}</p>
                <p>{cardInfo?.provided?.card?.number.slice(4, 8)}</p> 
                <p>{cardInfo?.provided?.card?.number.slice(8, 12)}</p>
                <p>{cardInfo?.provided?.card?.number.slice(12, 16)}</p>
              </div>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <div>
                <div className="row card-holder">
                  <p>CARD HOLDER</p>
                </div>
                <div className="row name">
                  <p>{cardInfo?.provided?.card?.nameOnCard}</p>
                </div>
              </div>
              <div>
                <div className="row card-holder">
                  <p>VALID TILL</p>
                </div>
                <div className="row name">
                  <p>{cardInfo?.provided?.card?.expiry?.month}/{cardInfo?.provided?.card?.expiry?.year}</p>
                </div>
              </div>
              </div>
            </div>
            <div className="back">
              <img
                src="https://i.ibb.co/PYss3yv/map.png"
                alt="Map"
                className="map-img"
              />
              <div className="bar"></div>
              <div className="row card-cvv">
                <div>
                  <img
                    src="https://i.ibb.co/S6JG8px/pattern.png"
                    alt="Pattern"
                  />
                </div>
                <p>XXX</p>
              </div>
              <div className="row card-text">
                <p>
                  This is your card number. Please keep it safe and do not share
                </p>
              </div>
              <div className="row signature">
                <p>CUSTOMER SIGNATURE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCard;
