import { Button } from "antd";
import React from "react";

export default function AboutBranch({ branchData }) {
  // Check if branchData is provided
  if (!branchData || branchData.length === 0) {
    return null; // or handle the absence of data in a different way
  }

  const getDefaultImageUrl = () => {
    // Use the provided placeholder image URL from unsplash
    return "https://images.unsplash.com/photo-1582559934353-2e47140e7501?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Ds";
  };

  return (
    <div className="container mx-auto mt-5">
      {branchData.map((branch, index) => (
        <div
          key={branch._id}
          className={`row${index % 2 === 0 ? " flex-row-reverse" : ""}`}
          style={{ marginTop: index > 0 ? "20px" : "0" }}
        >
          <div className="col-md-6">
            <Button
              style={{
                border: "1px solid #C6A34F",
                color: "#C6A34F",
                borderRadius: "10px",
                marginBottom: "20px",
              }}
            >
              {branch.title}
            </Button>
            <h3>{branch.title}</h3>
            <p>{branch.subTitle}</p>
          </div>
          <div className="col-md-6">
            <img
              src={
                `${process.env.REACT_APP_ASSETs_BASE_URL}/${branch.url}` ||
                getDefaultImageUrl()
              }
              alt={`AboutBranchImage-${branch._id}`}
              style={{
                width: "100%",
                height: "400px",
                borderRadius: "15px",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
