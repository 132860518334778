import React, { useState } from "react";
import toast from "react-hot-toast";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import "./google-map.scss";

export default function LocationSearch({
  setMyLocation,
  myLocation,
  path,
  setName,
  setCoordinate,
  setLoading,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      // componentRestrictions: { country: "bd" },
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const [error, setError] = useState(false);

  const handleSelect =
    ({ description }) =>
    () => {
      setLoading(true);
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setError(false);
          setMyLocation({
            lat: lat,
            lng: lng,
          });
          setName(description);
          setCoordinate({
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          });
          setLoading(false);
        })
        .catch((error) => {
          setValue("");
          setError(true);
          toast.error("Please Select an Address within Area!");
          setLoading(false);
        });
      setLoading(false);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div
      className={
        error ? "location-serach-box red-border" : "location-serach-box"
      }
    >
      <div className="childbox">
        <div className="icon">
          <i className="fas fa-location-arrow"></i>
        </div>
        <div>
          <input
            className="form-control search-map-field"
            value={value}
            id="validationServerUsernameFeedback"
            onChange={handleInput}
            disabled={!ready}
            placeholder="Search Address or Move Marker Pin"
          />
        </div>
      </div>

      <div id="validationServerUsernameFeedback" className="invalid-feedback">
        Please choose a username.
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
}
