import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Loader from "../../../common/Loader";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import logo from "./Logo.png";
import "./print-invoice.css";
import moment from "moment";
import dayjs from "dayjs";
export default function InvoiceDetails2() {
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [clientBankInfo, setclientBankInfo] = useState(null);
  const { currencyType } = useContext(ContextApi);
  const componentRef = React.createRef();
  // console.log("invoiceData: ", invoiceData);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories//${id}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`, // Call the function to get the token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoiceData(data.details);
        } else {
          console.error("Failed to fetch invoice details");
        }
      })
      .catch((error) => {
        console.error("Error while fetching invoice details: ", error);
      });
  }, [id]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/bank-info/${invoiceData?.clientId}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`, // Call the function to get the token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setclientBankInfo(data.details);
        } else {
          console.error("Failed to fetch invoice details");
        }
      })
      .catch((error) => {
        console.error("Error while fetching invoice details: ", error);
      });
  }, [invoiceData?.clientId]);

  const formatDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };
  
  const formatDateRange = (fromDate, toDate) => {
    const formattedFromDate = moment(fromDate).format("MMM D");
    const formattedToDate = moment(toDate).format("MMM D");
    const isSameYear = moment(fromDate).isSame(toDate, 'year');
    const yearString = isSameYear ? `, ${moment(fromDate).format("YYYY")}` : "";
    return `From ${formattedFromDate} to ${formattedToDate}${yearString}`;
  };
  

  return (
    <div>
      {invoiceData ? (
        <>
          <div
            className="text-center"
            style={{
              // backgroundColor: "#C6A34F",
              color: "white",
              // padding: "10px",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  className="mt-5 px-4 text-center rounded"
                  style={{
                    backgroundColor: "#C6A34F",
                    color: "white",
                    border: "1px solid #C6A34F",
                    textDecoration: "none",
                  }}
                >
                  Print Now
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div
            className="container mx-auto p-3 m-3 rounded"
            style={{
              border: "1px solid #C6a34f",
              width: "800px",
              backgroundColor: "#FFF",
              fontWeight: 600, // or fontWeight: "bold" depending on your preference
            }}
            ref={componentRef}
          >
            {/* header */}
            <div>
              <div
                style={{
                  backgroundColor: "#FFF2CC",
                  padding: "10px",
                }}
                className="d-flex justify-content-between align-items-center w-full"
              >
                <img
                  style={{
                    width: "80px",
                    height: "80px",
                  }}
                  src={logo}
                  alt="logo"
                  className="logo"
                />
                <h3>
                  <strong className="text-uppercase text-white">Invoice</strong>
                </h3>
              </div>
              <div className="d-flex justify-content-between align-items-center w-full mt-1">
                <div
                  className="hr-1"
                  style={{
                    backgroundColor: "#FFD966",
                    height: "10px",
                    flex: "1",
                  }}
                ></div>
                <div
                  className="hr-2"
                  style={{
                    backgroundColor: "#BF9001",
                    height: "10px",
                    flex: "1",
                  }}
                ></div>
                <div
                  className="hr-3"
                  style={{
                    backgroundColor: "#7F6001",
                    height: "10px",
                    flex: "1",
                  }}
                ></div>
              </div>
            </div>
            {/* body */}

            <div
              className="from m-5"
              style={{
                lineHeight: "1.3",
              }}
            >
              <h3 className="mb-4 mt-5">MH Premier Staffing Solutions Ltd</h3>
              <small>48 Warwick St, Regent St </small>
              <br />
              <small>London, W1B 5AW</small>
              <br />
              <small>VAT number: 450105738</small>
              <br />
              <small>account@mhpremierstaffingsolutions.com</small>
              <br />
              <small>07960966110</small>

              <div className="to">
                <div className="row mb-4 mt-5">
                  <div className="col-2">
                    <h4>To</h4>
                  </div>
                  {/* <div
                    className="col-6"
                    style={{
                      lineHeight: "inherit", // or set it to "1" or any other desired value
                    }}
                  >
                    <p>{invoiceData?.restaurantName}</p>
                    <p>
                      {clientBankInfo?.companyName &&
                        `${clientBankInfo?.companyName}`}
                    </p>
                    <p>
                      {clientBankInfo?.companyRegisterNumber &&
                        `Reg No: ${clientBankInfo?.companyRegisterNumber}`}
                    </p>{" "}
                    <p>{invoiceData?.restaurantAddress}</p>
                    <p>
                      {clientBankInfo?.phoneNumber &&
                        `${clientBankInfo?.phoneNumber}`}
                    </p>
                    <p>{clientBankInfo?.email && `${clientBankInfo?.email}`}</p>
                  </div> */}

                  <div
                    className="col-6"
                    style={{
                      lineHeight: "1",
                    }}
                  >
                    <p style={{ margin: "0" }}>{invoiceData?.restaurantDetails?.restaurantName}</p>
                    <p style={{ margin: "0" }}>
                      {clientBankInfo?.companyName &&
                        `${clientBankInfo?.companyName}`}
                    </p>
                    <p style={{ margin: "0" }}>
                      {clientBankInfo?.companyRegisterNumber &&
                        `Reg No: ${clientBankInfo?.companyRegisterNumber}`}
                    </p>{" "}
                    <p style={{ margin: "0" }}>
                      {invoiceData?.restaurantDetails?.restaurantAddress}
                    </p>
                    <p style={{ margin: "0" }}>
                      {clientBankInfo?.phoneNumber &&
                        `${clientBankInfo?.phoneNumber}`}
                    </p>
                    <p style={{ margin: "0" }}>
                      {invoiceData?.restaurantDetails?.email && `${invoiceData?.restaurantDetails?.email}`}
                    </p>
                  </div>

                  <div className="col">
                    <p>Invoice No: {invoiceData?.invoiceNumber}</p>
                    <p>Date: &nbsp;{formatDate(invoiceData?.createdAt)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead
                  style={{
                    backgroundColor: "#FFF2CC",
                    padding: "10px",
                  }}
                >
                  <tr className="text-center">
                    <th>QUANTITY</th>
                    <th>DESCRIPTION</th>
                    <th>DATE</th>
                    <th 
                    style={{
                      textAlign : "right"
                    }}
                    >TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <small>One</small>
                    </td>
                    <td>
                      <small>Event Management Services staff members</small>
                    </td>
                    <td>
                      <small>
                        {" "}
                        {/* {formatDateRange(
                          invoiceData?.fromWeekDate,
                          invoiceData?.toWeekDate
                        )} */}
                        {
                          dayjs(invoiceData?.createdAt).format("MMM D, YYYY")
                        }
                      </small>
                    </td>
                    <td style={{
                      textAlign : "right"
                    }}>
                      {invoiceData
                        ? `${currencyType}  ${invoiceData?.clientAmount?.toFixed(2)}`
                        : ""}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2" className="text-end">
                      <small> VAT</small>
                    </td>
                    <td className="text-center">
                      <small>
                        {" "}
                        {invoiceData ? ` ${invoiceData.vat}%` : ""}
                      </small>
                    </td>
                    <td className="text-end">
                      {invoiceData
                        ? ` ${currencyType} ${invoiceData?.vatAmount?.toFixed(2)}`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-end">
                      <small>Platform fee</small>
                    </td>
                    <td></td>
                    <td className="text-end">
                      {invoiceData
                        ? ` ${currencyType} ${invoiceData?.platformFee?.toFixed(
                            2
                          )}`
                        : ""}
                    </td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: "#FFF2CC",
                    }}
                  >
                    <td colSpan="3" className="text-end">
                      Total
                    </td>
                    <td style={{
                      textAlign : "right"
                    }}>
                      {invoiceData
                        ? `${currencyType}${invoiceData?.totalAmount?.toFixed(2)}`
                        : ""}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* footer */}
            <div className="from lh-1 m-5">
              <div className="to">
                <div className="row mb-4 mt-5">
                  <div className="col-2">
                    <h5
                      style={{
                        // fontStyle: "italic",
                        marginTop: "30px",
                      }}
                    >
                      Bank Transfer:
                    </h5>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        lineHeight: "1",
                      }}
                    >
                      <div>
                        <small>MH PREMIER STAFFING SOLUTIONS LTD</small>
                        <br />
                        <small>Account number: 06418279</small> <br />
                        <small>Sort code: 04-29-09</small> <br />
                      </div>
                      <small>
                        Beneficiary address: 48, Warwick Street, W1B 5AW,
                        London, United Kingdom
                      </small>{" "}
                      <br />
                      <small>Company number: 14706276</small> <br />
                      <small>Bank / Payment institution: Revolut Ltd</small>
                      <small>
                        {" "}
                        <br />
                        Bank / Payment institution address: 7 Westferry Circus,
                        E14 4HD, London, United Kingdom
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                backgroundColor: "#FFF2CC",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <p>
                Make all cheques payable to MH Premier Staffing Solutions Ltd
              </p>
              <p>THANK YOU FOR YOUR BUSINESS!</p>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
