import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Switch,
  Modal,
  Form,
  Input,
  message,
  Select,
} from "antd";
import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const { confirm } = Modal;
const { Option } = Select;

const RoleList = () => {
  const [roles, setRoles] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editRoleData, setEditRoleData] = useState(null);
  const [form] = Form.useForm();

  const fetchRoles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/roles`
      );
      if (response.data.status === "success") {
        setRoles(response.data.roles);
      } else {
        console.error("Failed to fetch roles:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleEdit = (record) => {
    setEditRoleData(record);
    setEditModalVisible(true);
    form.setFieldsValue(record);
  };

  const handleStatusChange = async (id, active) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/roles/update-status`,
        {
          id,
          active,
        }
      );
      toast.success("Status updated successfully");
      fetchRoles();
    } catch (error) {
      console.error("Error updating status:", error);
      message.error("Failed to update status");
    }
  };

  const handleConfirmEdit = async () => {
    try {
      const values = await form.validateFields();
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/roles/update`, {
        id: editRoleData._id,
        name: values.name,
        country: values.country,
        active: editRoleData.active,
        slug: values.name.toUpperCase().replace(/ /g, "_"),
      });
      toast.success("Role updated successfully");
      fetchRoles();
      setEditModalVisible(false);
    } catch (error) {
      console.error("Error updating role:", error);
      message.error("Failed to update role");
    }
  };

  const handleCancelEdit = () => {
    setEditModalVisible(false);
  };

  const columns = [
    {
        title:"#",
        dataIndex:"index",
        key:"index",
        render:(_,__,index)=>index+1
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => {
            confirm({
              title: `Are you sure you want to change the status to ${
                checked ? "active" : "inactive"
              }?`,
              onOk() {
                handleStatusChange(record._id, checked);
              },
            });
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      <h2>Role List</h2>
      <Table dataSource={roles} columns={columns} />

      <Modal
        title="Edit Role"
        visible={editModalVisible}
        onCancel={handleCancelEdit}
        onOk={handleConfirmEdit}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Country" name="country">
            <Select>
              <Option value="United Arab Emirates">United Arab Emirates</Option>
              <Option value="Italy">Italy</Option>
              <Option value="United Kingdom">United Kingdom</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RoleList;
