import {
    Col,
    DatePicker,
    Form,
    InputNumber,
    Modal,
    Row,
    Select,
    Table,
    TimePicker,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

import { useTranslation } from "react-i18next";
import Loader from "../../../common/Loader";
import { token } from "../../../utils/authentication";
import {
    getUserCurrency,
    getUserCurrencyByCountry,
    getUserId,
    getUserInfo,
    paramsToQueryString,
} from "../../../utils/commonFunctions";
import { donwloadCSV } from "../../../utils/static/donwloadCSV";

const { Option } = Select;
const { RangePicker } = DatePicker;

function ComplainList() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null); // [
  const [totalEmployee, setTotalEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [currency, setCurrency] = useState(getUserCurrency());
  const [notify, setNotify] = useState(false);
  const [loadar, setLoadar] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [editId, setEditId] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [form] = Form.useForm();

  // socket connection for real time update
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    withCredentials: false,
    transports: ["websocket", "polling", "flashsocket"],
  });
  useEffect(() => {
    socket.on("notification", (dataFromServer) => {
      const logedInUserId = getUserId();
      if (dataFromServer.userInfo._id === logedInUserId) {
        setNotify(!notify);
      }
    });
    return () => {
      socket.off("notification");
    };
  }, []);

  // table data
  useEffect(() => {
    setLoadar(true);
    const parmas = {
      clientId: selectedClient ? selectedClient : undefined,
      // filterDate: selectedDate
      //   ? dayjs(selectedDate).format("YYYY-MM-DD")
      //   : undefined,
      startDate: selectedDate
        ? selectedDate[0]
          ? dayjs(selectedDate[0]).format("YYYY-MM-DD")
          : undefined
        : undefined,
      endDate: selectedDate
        ? selectedDate[1]
          ? dayjs(selectedDate[1]).format("YYYY-MM-DD")
          : undefined
        : undefined,
      employeeId: selectedEmployee ? selectedEmployee : undefined,
      countryName: selectedCountry ? selectedCountry : undefined,
      page: currentPage,
      limit: pageSize,
    };
    const parmasToQuery = paramsToQueryString(parmas);
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories?${parmasToQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setTotal(res.data.total);
          setLoadar(false);
          const checkInCheckOutHistories = res.data.result.map((item) => {
            const finalCheckIn =
              item?.checkInCheckOutDetails?.clientCheckInTime ||
              item?.checkInCheckOutDetails?.checkInTime;
            const finalCheckOut =
              item?.checkInCheckOutDetails?.clientCheckOutTime ||
              item?.checkInCheckOutDetails?.checkOutTime;
            const finalBreakTime =
              item?.checkInCheckOutDetails?.clientBreakTime ||
              item?.checkInCheckOutDetails?.breakTime;
            const isEditCheckIn = item?.checkInCheckOutDetails
              ?.clientCheckInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) ===
                dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
                ? false
                : true
              : false;
            const isEditCheckOut = item?.checkInCheckOutDetails
              ?.clientCheckOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) ===
                dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
                ? false
                : true
              : false;
            const isEditBreakTime = item?.checkInCheckOutDetails
              ?.clientBreakTime
              ? item?.checkInCheckOutDetails?.breakTime ===
                item?.checkInCheckOutDetails?.clientBreakTime
                ? false
                : true
              : false;
            return {
              date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
              id: item?._id,
              country: item?.restaurantDetails?.countryName,
              restaurantName: item?.restaurantDetails?.restaurantName,
              employeeName: item?.employeeDetails?.name,
              position: item?.employeeDetails?.positionName,
              restaurantRate:
                item?.employeeDetails?.hourlyRate +
                " " +
                getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
                t("per_hour"),
              customerRate:
                item?.employeeDetails?.contractorHourlyRate +
                " " +
                getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
                t("per_hour"),
              checkIn: item?.checkInCheckOutDetails?.checkInTime
                ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalCheckIn: finalCheckIn
                ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
                : null,
              isEditCheckIn: isEditCheckIn,

              checkOut: item?.checkInCheckOutDetails?.checkOutTime
                ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalcheckOut: finalCheckOut
                ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
                : null,
              isEditCheckOut: isEditCheckOut,

              break: item?.checkInCheckOutDetails?.breakTime,
              finalBreakTime: finalBreakTime ? finalBreakTime : 0,
              isEditBreakTime: isEditBreakTime,

              totalHours: finalCheckOut
                ? minutestoHours(
                    dayjs(finalCheckOut)
                      .diff(dayjs(finalCheckIn), "seconds", true)
                      .toFixed(0) -
                      finalBreakTime * 60
                  )
                : null,
                workedHour : item?.workedHour,
                paid : item?.paid,
              totalAmount: finalCheckOut
                ? (
                    (dayjs(finalCheckOut)
                      .diff(dayjs(finalCheckIn), "seconds", true)
                      .toFixed(0) -
                      finalBreakTime * 60) *
                    (item?.employeeDetails?.hourlyRate / 3600)
                  ).toFixed(2) +
                  " " +
                  getUserCurrencyByCountry(item?.restaurantDetails?.countryName)
                : null,
              clientComment: item?.checkInCheckOutDetails?.clientComment,
              mainItem: item,
              status: item?.status,
            };
          });
          setData(checkInCheckOutHistories);
        });
    } catch (err) {
      setLoadar(false);
    }
  }, [
    selectedDate,
    selectedClient,
    selectedEmployee,
    currentPage,
    pageSize,
    notify,
    currency,
    selectedCountry,
    forceRefresh,
  ]);

  useEffect(() => {
    if (!modal) {
      form.resetFields();
      setEditId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const userInfo = getUserInfo();
  const minutestoHours = (secs) => {
    const isMinus = secs < 0 ? true : false;
    const sec = isMinus ? secs * -1 : secs;
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = (sec - hours * 3600 - minutes * 60).toFixed(0);
    return (
      (isMinus ? "-" : "") +
      (hours < 10 ? "0" : "") +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds +
      " H"
    );
  };

  const countryColumn =
    userInfo?.role === "SUPER_ADMIN"
      ? [
          {
            title: "Country",
            dataIndex: "country",
            fixed: "left",
          },
        ]
      : [];

  // table columns
  const columns = [
    {
      title: t("date"),
      dataIndex: "date",
      position: "left",
      fixed: "left",
    },
    ...countryColumn,
    {
      title: t("restaurant_name"),
      dataIndex: "restaurantName",
      fixed: "left",
    },
    {
      title: t("employee_name"),
      dataIndex: "employeeName",
      fixed: "left",
    },
    {
      title: t("position"),
      dataIndex: "position",
      // sorter: (a, b) => a.active.length - b.active.length,
    },
    {
      title: t("restaurant_rate"),
      dataIndex: "restaurantRate",
    },
    {
      title: t("customer_rate"),
      dataIndex: "customerRate",
    },
    {
      title: t("check_in"),
      dataIndex: "checkIn",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckIn && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkIn}
              </div>
            )}
            <div
              style={
                record?.isEditCheckIn
                  ? { color: "#c6a34f", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckIn}
            </div>
          </div>
        );
      },
    },
    {
      title: t("check_out"),
      dataIndex: "checkOut",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckOut && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkOut}
              </div>
            )}
            <div
              style={
                record?.isEditCheckOut
                  ? { color: "#c6a34f", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalcheckOut}
            </div>
          </div>
        );
      },
    },
    {
      title: t("break"),
      dataIndex: "break",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditBreakTime && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.break} min
              </div>
            )}
            <div
              style={
                record?.isEditBreakTime
                  ? { color: "#c6a34f", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalBreakTime} min
            </div>
          </div>
        );
      },
    },
    /* {
      title: t("total_hours"),
      dataIndex: "totalHours",
    },
    {
      title: t("total_amount"),
      dataIndex: "totalAmount",
    }, */
    {
        title: t("total_hours"),
        dataIndex: "workedHour",
      },
      {
        title: t("total_amount"),
        dataIndex: "paid",
      },
    {
      title: t("client_comment"),
      dataIndex: "clientComment",
      fixed: "right",
    },
    {
      title: t("status"),
      dataIndex: "status",
      fixed: "right",
      render: (text, record) => {
        return (
          <div>
            {record?.status === "PAID" ? (
              <div
                style={{
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                {record?.status}
              </div>
            ) : (
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {record?.status}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "action",
      fixed: "right",
      render: (text, record) => {
        return (
          <div>
            <button className="btn btn-primary">{t("Action")}</button>
          </div>
        );
      },
    },
  ];

  const saveHnadler = async () => {
    await form.validateFields();
    setModalLoader(true);
    const values = form.getFieldsValue(true);
    const hiredDate = dayjs(values?.date).format("YYYY-MM-DD");
    const overWritCheckIn = dayjs(
      dayjs(values?.date).format("YYYY-MM-DD") +
        " " +
        dayjs(values?.checkInTime).format("HH:mm:ss")
    ).format("YYYY-MM-DD HH:mm:ss");
    const overWriteCheckOut = dayjs(
      dayjs(values?.date).format("YYYY-MM-DD") +
        " " +
        dayjs(values?.checkOutTime).format("HH:mm:ss")
    ).format("YYYY-MM-DD HH:mm:ss");
    const payload = {
      employeeId: values?.employee?._id,
      hiredBy: values?.hiredBy?._id,
      hiredDate: hiredDate,
      checkIn: true,
      checkOut: values?.checkOutTime ? true : false,
      lat: values?.hiredBy?.lat,
      long: values?.hiredBy?.long,
      checkInDistance: 0,
      checkInTime: values?.checkInTime ? overWritCheckIn : null,
      breakTime: values?.breakTime,
      checkOutDistance: 0,
      checkOutTime: values?.checkOutTime ? overWriteCheckOut : null,
    };
    console.log(values);
    const payload2 = {
      id: editId,
      checkIn: true,
      checkOut: values?.checkOutTime ? true : false,
      lat: values?.hiredBy?.lat,
      long: values?.hiredBy?.long,
      breakTime: values?.breakTime,
      // totalWorkingHour: 5,
      checkOutDistance: 0,
      checkInDistance: 0,
      checkInTime: values?.checkInTime ? overWritCheckIn : null,
      checkOutTime: values?.checkOutTime ? overWriteCheckOut : null,
      hiredDate: hiredDate,
    };
    try {
      !editId
        ? await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/current-hired-employees/create`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
        : await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/current-hired-employees/update`,
            payload2,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          );
      setModal(false);
      setModalLoader(false);
      setForceRefresh(!forceRefresh);
    } catch (err) {
      setModalLoader(false);
      console.log(err);
    }
  };

  return (
    <div className="container-fluid px-4 mt-4">
      <h3 className="mb-4 title">{t("Complain List")}</h3>
      {/* table here */}
      <div className="card">
        <div className="card-header">
          <div className="col-12">
            <div className="row">
              <div className="col-10">
                <Row gutter={[8, 8]}>
                  {/* <Col sm={24} md={12} lg={6}>
                      <DatePicker
                        size="large"
                        onChange={(date, dateString) => {
                          setSelectedDate(dateString);
                          setpageSize(10);
                          setCurrentPage(1);
                        }}
                        style={{ width: "100%" }}
                        allowClear
                      />
                    </Col> */}
                  <Col sm={24} md={12} lg={8}>
                    <RangePicker
                      size="large"
                      onChange={(date, dateString) => {
                        setSelectedDate(dateString);
                        setpageSize(10);
                        setCurrentPage(1);
                      }}
                      placeholder={[t("start_date"), t("end_date")]}
                      style={{ width: "100%" }}
                      allowClear
                    />
                  </Col>
                  {userInfo?.role === "SUPER_ADMIN" ? (
                    <Col sm={24} md={12} lg={5}>
                      <Select
                        size="large"
                        allowClear
                        showSearch={true}
                        style={{ width: "100%" }}
                        placeholder={t("select_country_name")}
                        onChange={(value) => {
                          setSelectedCountry(value);
                          setpageSize(10);
                          setCurrentPage(1);
                        }}
                      >
                        <Option value="United Kingdom">United Kingdom</Option>
                        <Option value="United Arab Emirates">
                          United Arab Emirates
                        </Option>
                      </Select>
                    </Col>
                  ) : null}
                  <Col sm={24} md={12} lg={5}>
                    <Select
                      showSearch={true}
                      placeholder={t("restaurant")}
                      optionFilterProp="children"
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setSelectedClient(value);
                        setpageSize(10);
                        setCurrentPage(1);
                      }}
                    >
                      {clients?.map((item, index) => (
                        <Option key={index} value={item?._id}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col sm={24} md={12} lg={5}>
                    <Select
                      showSearch={true}
                      placeholder={t("employee")}
                      optionFilterProp="children"
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setSelectedEmployee(value);
                        setpageSize(10);
                        setCurrentPage(1);
                      }}
                    >
                      {totalEmployee?.map((item, index) => (
                        <Option key={index} value={item?._id}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {loadar ? (
            <tr>
              <td>
                <Loader />
              </td>
            </tr>
          ) : (
            <Table
              columns={columns}
              scroll={{ x: 1500 }}
              dataSource={data}
              pagination={{
                pageSize: Number(pageSize),
                current: Number(currentPage),
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setpageSize(pageSize);
                },
                total: total,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20"],
              }}
            />
          )}
        </div>
      </div>
      <Modal
        title={t("add_new_check_in_check_out_details")}
        visible={modal}
        width={1000}
        onCancel={() => {
          setModal(false);
        }}
        footer={
          <>
            <button
              className="btn btn-primary"
              disabled={modalLoader}
              style={{
                marginRight: "10px",
              }}
              onClick={() => saveHnadler()}
            >
              {modalLoader ? t("loading...") : editId ? t("update") : t("save")}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModal(false);
              }}
              disabled={modalLoader}
            >
              {t("cancel")}
            </button>
          </>
        }
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[10, 8]} className="mt-5 mb-4">
            <Col sm={24} md={12} lg={8}>
              <label>{t("date")}</label>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: t("please_select_date"),
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  placeholder={t("select_date")}
                  onChange={(date, dateString) => {
                    // const baseDate = dayjs(dateString).format("YYYY-MM-DD");
                    // setBaseDate(baseDate);
                  }}
                  style={{ width: "100%" }}
                  disabled={editId ? true : false}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <label>{t("restaurant")}</label>
              <Form.Item
                name="hiredBy"
                rules={[
                  {
                    required: true,
                    message: t("please_select_restaurant"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  placeholder={t("restaurant")}
                  disabled={editId ? true : false}
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  onChange={(value, option) => {
                    form.setFieldsValue({
                      hiredBy: option?.option,
                      lat: option?.option?.lat,
                      long: option?.option?.long,
                    });
                  }}
                >
                  {clients?.map((item, index) => (
                    <Option key={index} value={item?._id} option={item}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <label>{t("employee")}</label>
              <Form.Item
                name="employee"
                rules={[
                  {
                    required: true,
                    message: t("please_select_employee"),
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  placeholder={t("employee")}
                  optionFilterProp="children"
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  disabled={editId ? true : false}
                  // options={totalEmployee}
                  onChange={(value, option) => {
                    console.log(option.option);
                    form.setFieldsValue({
                      employee: option?.option,
                    });
                  }}
                >
                  {totalEmployee?.map((item, index) => (
                    <Option key={index} value={item?._id} option={item}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <label>{t("check_in_time")}</label>
              <Form.Item
                name="checkInTime"
                rules={[
                  {
                    required: true,
                    message: t("please_select_check_in_time"),
                  },
                ]}
              >
                <TimePicker
                  onChange={(value) => {
                    form.setFieldsValue({
                      checkInTime: value,
                    });
                  }}
                  placeholder={t("select_check_in_time")}
                  size="large"
                  style={{ width: "100%" }}
                  showTime={true}
                  format={"HH:mm:ss"}
                  disabled={
                    isEdit && !form.getFieldValue("checkInTime") ? true : false
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <label>{t("check_out_time")}</label>
              <Form.Item
                name="checkOutTime"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select Check Out Time",
                //   },
                // ]}
              >
                <TimePicker
                  onChange={(value) => {
                    form.setFieldsValue({
                      checkOutTime: value,
                    });
                  }}
                  placeholder={t("select_check_out_time")}
                  size="large"
                  style={{ width: "100%" }}
                  showTime={true}
                  format={"HH:mm:ss"}
                  // disabled={isEdit && !form.getFieldValue("checkOutTime") ? true : false}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <label>
                {t("break_time")} ({t("min")})
              </label>
              <Form.Item
                name="breakTime"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Break Time",
                //   },
                // ]}
              >
                <InputNumber
                  type="number"
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={t("break_time")}
                  onChange={(e) => {
                    form.setFieldsValue({
                      breakTime: e,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default ComplainList;
