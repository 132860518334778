import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ourmission2 from "./our-mission2.jpg";
const Mission = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="At MH Premier Staffing, we prioritize shared success. Entrust us with your hospitality, hotel, and restaurant staffing needs, and witness our commitment to excellence.
          "
        />
        <title>
          MH Premier Staffing Solutions: Our Mission in Hospitality Recruitment
        </title>
      </Helmet>

      <div
        class="banner-header section-padding valign bg-img bg-fixed img-fluid container"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_ASSETS_FRONTEND_URL}/our_mission.jpg`,
          minHeight: "400px",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 caption align-middle"
              style={{ marginTop: "150px" }}
            >
              <h5 style={{ color: "white", fontSize: "20px" }}>
                {/* {t("meet_the_team_company_name")} */}
              </h5>
              <h1 style={{ color: "white", fontSize: "70px" }}>
                {/* {t("mission_and_values")} */}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style={{ marginTop: "60px" }}>
        <div class="row justify-content-end">
          <div class="col-12 col-md-6 col-lg-6">
            <h2 class="my-5">{t("our_mission")} </h2>
            <p className="about_us_section_paragraph">
              {t("our_mission_description")}
            </p>
          </div>
          <div class="col-12 col-md-6 col-lg-6 mt-5">
            <img src={ourmission2} alt="the mission" class="img-fluid" />
          </div>
        </div>
        {/* <div class="row justify-content-end mt-5">
          <div class="col-12 col-md-6 col-lg-6 mt-5">
            <img
              src="assets/frontend/images/mission/vission.jpg"
              alt="the mission"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <h2 class="my-5">{t("our_vision")}</h2>
            <p className="about_us_section_paragraph">
              {t("our_vision_description")}
            </p>
          </div>
        </div>{" "} */}
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Mission;
