import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    TimePicker,
} from "antd";
import "./MHInput.css";
const { RangePicker } = DatePicker;

const conditionalRenderFunc = (props) => {

  switch (props?.type || "") {
    case "number":
      return (
        <InputNumber
          size={props?.size || "large"}
          className="w-full rounded"
          placeholder={props?.placeholder ? props?.placeholder : props?.label}
          maxLength={props?.maxLength}
          onChange={props?.onChange}
          defaultValue={props?.defaultValue ? props?.defaultValue : undefined}
          disabled={props?.disabled}
          min={props?.min || 0}
          max={props?.max || undefined}
          prefix={props?.prefix}
          type="number"
          onWheel={props?.onWheel}
        />
      );
    case "date":
      return props?.disabledDate ? (
        <DatePicker
          size={props?.size || "large"}
          className="w-full rounded"
          placeholder={props?.placeholder ? props?.placeholder : props?.label}
          disabled={props?.disabled}
          format="DD/MM/YYYY"
          onChange={props?.onChange}
          allowClear={props?.allowClear}
        />
      ) : (
        <DatePicker
          size={props?.size || "large"}
          className="w-full rounded"
          placeholder={props?.placeholder ? props?.placeholder : props?.label}
          disabled={props?.disabled}
          format={props?.format ? props?.format : "DD/MM/YYYY"}
          picker={props?.picker}
          onChange={props?.onChange}
          allowClear={props?.allowClear}
        />
      );
    case "dateRange":
      return (
        <RangePicker
          className="w-full rounded"
          disabled={props?.disabled}
          format="DD/MM/YYYY"
          allowClear={props?.allowClear}
        />
      );
    case "timePicker":
      return (
        <TimePicker
          className="w-full rounded"
          disabled={props?.disabled}
          format="h:mm a"
          use12Hours
        />
      );
    case "dateAndTimePicker":
      return (
        <DatePicker
          className="w-full rounded"
          disabled={props?.disabled}
          format="DD/MM/YYYY h:mm a"
          use12Hours
          showTime
          onChange={props?.onChange}
        />
      );
    case "search":
      return (
        <Input
            size={props?.size || "large"}
          placeholder={props?.placeholder ? props?.placeholder : props?.label}
          maxLength={props?.maxLength}
          onChange={props?.onChange}
          className="rounded serchFieldTopbar"
          defaultValue={props?.defaultValue ? props?.defaultValue : undefined}
          disabled={props?.disabled}
        />
      );
    case "checkbox":
      return (
        <Checkbox
          className={`${props?.className ? props?.className : ""} rounded`}
          disabled={props?.disabled}
          onChange={props?.onChange}
          checked={props?.checked}
        >
          {props?.checkboxText}
        </Checkbox>
      );
    case "textarea":
      return (
        <Input.TextArea
          className={`${
            props?.className ? props?.className : ""
          } w-full rounded`}
          placeholder={props?.placeholder ? props?.placeholder : props?.label}
          maxLength={props?.maxLength}
          rows={props?.rows}
          onChange={props?.onChange}
          defaultValue={props?.defaultValue ? props?.defaultValue : undefined}
          disabled={props?.disabled}
          autoSize={props?.autoSize}
        />
      );

    default:
      return (
        <Input
          size={props?.size || "large"}
          placeholder={props?.placeholder ? props?.placeholder : props?.label}
          maxLength={props?.maxLength}
          onChange={props?.onChange}
          className="rounded"
          defaultValue={props?.defaultValue ? props?.defaultValue : undefined}
          disabled={props?.disabled}
          prefix={props?.prefix}
          suffix={props?.suffix}
        />
      );
  }
};
export const MHInput = (props) => {
  return (
    <Form.Item
      label={props?.label}
      name={props?.name}
      rules={props?.rules}
      placeholder={props?.placeholder}
      valuePropName={props?.valuePropName}
    >
      {conditionalRenderFunc(props)}
    </Form.Item>
  );
};
