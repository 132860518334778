import React from "react";
import CustomVideoPlayer from "./VideoPlayer/VideoPlayer";
export default function MissionVission({ missionAndVision }) {
  // console.log("missionAndVision:", missionAndVision);
  const focusData = missionAndVision?.list;
  const itemsPerLine = 4;
  const lines = Math.ceil(missionAndVision?.list.length / itemsPerLine);

  const renderContent = () => {
    if (missionAndVision.contentType === "image") {
      return (
        <img
          src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${missionAndVision.url}`}
          alt="Hero Section"
          className="img-fluid rounded"
        />
      );
    } else if (missionAndVision.contentType === "video") {
      return (
        <CustomVideoPlayer
          videoLink={`${process.env.REACT_APP_ASSETs_BASE_URL}/${missionAndVision.url}`}
        />
      );
    }

    // Handle other content types if needed
    return null;
  };

  return (
    <div className="container mx-auto mt-5 mb-5">
      <div className="row">
        <div className="col-md-6">
          <h3>{missionAndVision.title}</h3>
          <p>{missionAndVision.subTitle}</p>
          <p>We give proper focus on:</p>
          <div>
            {[...Array(lines)].map((_, lineIndex) => (
              <div
                key={lineIndex}
                style={{ display: "flex", marginBottom: "20px" }}
              >
                {focusData
                  .slice(
                    lineIndex * itemsPerLine,
                    (lineIndex + 1) * itemsPerLine
                  )
                  .map((value, index) => (
                    <div
                      key={index}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#C6A34F",
                        borderRadius: "50px",
                        padding: "7px",
                        color: "white",
                      }}
                    >
                      {value}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        <div
          className="col-md-6"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
}
