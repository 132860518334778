import i18n from "../i18n";
import { jwtTokenDecode } from "./jwtDecode";

const currencyList = {
  "united kingdom": "£",
  "united arab emirates": "د.إ",
  default: "$",
};

export function getUserInfo() {
  const userInfo = jwtTokenDecode();
  if (userInfo) {
    return userInfo;
  }
  return null;
}

export function getUserCurrency() {
  const userInfo = getUserInfo();
  const country = userInfo.countryName?.toLowerCase();
  if (userInfo) {
    if (currencyList[country]) {
      return currencyList[country];
    }
    return currencyList["default"];
  }
  return null;
}

export function getUserCurrencyByCountry(country) {
  const countryName = country?.toLowerCase();
  if (currencyList[countryName]) {
    return currencyList[countryName];
  }
  return currencyList["default"];
}

export function getUserId() {
  const userInfo = getUserInfo();
  if (userInfo) {
    return userInfo._id;
  }
  return null;
}

export function paramsToQueryString(params) {
  const parmasToQuery = Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return parmasToQuery;
}
export function paramsToQueryString2(params) {
  const parmasToQuery = Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== "" && params[key] !== null )
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return parmasToQuery;
}

export function exactDate(checkInTime) {
  let dateObj = new Date(checkInTime);
  let year = dateObj.getUTCFullYear();
  let month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // months start from 0
  let day = String(dateObj.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const changeLanguageFunc = (e) => {
  const lang = e === "GB" ? "en" : e === "AE" ? "ar" : "it";
  localStorage.setItem("countryCode", e);
  localStorage.setItem("langSelect", lang);
  return i18n.changeLanguage(lang);
};