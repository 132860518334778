import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function DubaiDetails() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Discover MH Premier Staffing Solutions – your trusted partner since 2016. Evolved from Mirko Hospitality, we're committed to unparalleled staffing expertise."
        />
        <title>
          MH Premier Staffing Solutions: Expertise Since 2016 from Mirko
          Hospitality
        </title>
      </Helmet>

      <div
        class="banner-header section-padding valign bg-img bg-fixed img-fluid container"
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1553337546-017c3075360c?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
          minHeight: "400px",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 caption align-middle"
              style={{ marginTop: "150px" }}
            >
              <h5 style={{ color: "white", fontSize: "18px" }}>
                {/* {t("meet_the_team_company_name")} */}
              </h5>
              {/* <h1 style={{ color: "black", fontSize: "70px" }}>
                {t("home_navbar_about_us")}
              </h1> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 style={{ color: "black", fontSize: "50px" }}>
          {t("home_navbar_about_us_dubai")}
        </h2>
        <div className="mt-5 mb-5">
          <div className="about_us_section_sub_title1">
            <h3> {t("about_us_description_welcome_dubai")}</h3>
          </div>
          <br />
          <p className="about_us_section_paragraph">
            {t("about_us_description1_dubai")}{" "}
            {/* <a target="_blank" href="https://www.mirkohospitality.com/">
              Mirko Hospitality.
            </a> */}
          </p>
          <p className="about_us_section_paragraph">
            {t("about_us_description2_dubai")}
          </p>
          <p className="about_us_section_paragraph">
            {t("about_us_description3_dubai")}
          </p>

          {/* <p className="about_us_section_paragraph">
            {t("about_us_description4")}
          </p>

          <p className="about_us_section_paragraph">
            {t("about_us_description5")}
          </p>
          <p className="about_us_section_paragraph">
            {t("about_us_description6")}
          </p> */}
          <div className="text-center">
            <a
              className="btn"
              style={{ backgroundColor: "#c6a34f", color: "white" }}
              href="/meet-the-team"
            >
              {t("meet_the_team")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DubaiDetails;
