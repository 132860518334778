import { Button, Col, Form, Modal, Row, Select, Slider } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { FcCalendar } from "react-icons/fc";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchPositionListForDropdownHandler } from "../../../../api/position";
import { MHInput } from "../../../../common/Components";
import { token } from "../../../../utils/authentication";
import {
  getUserCurrencyByCountry,
  getUserId,
} from "../../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import Loader from "../../../loadar/Loader";
import BookForJob from "../../calender/BookForJob";
import MHCalender from "../../calender/MHCalender";
import { allSkills, language } from "../utils/utils";
import JobDetails from "./JobDetails";

const CreateJobPost = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [nationalities, setNationalities] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState(undefined);
  const [languages, setLanguages] = useState(language);
  const [rangeValue, setRangeValue] = useState([0, 60]);
  const [ageRangeValue, setAgeRangeValue] = useState([0, 100]);
  const [expRangeValue, setExpRangeValue] = useState([0, 50]);
  const [rangeUpdateValue, setRangeUpdateValue] = useState([]);
  const [calenderModal, setCalenderModal] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState([]);
  const [prevModal, setPrevModal] = useState(false);
  const [prevDate, setPrevDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [dates, setDates] = useState([]);
  const [finalizedDates, setFinalizedDates] = useState([]);
  const [totalDays, setTotalDays] = useState(0);

  // fetch all position list for dropdown
  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPositions(res?.data?.positions);
      form.setFieldsValue({
        position: res?.data?.positions[0]?.name,
      });
      setSelectedPosition(res?.data?.positions[0]?._id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/commons/nationality`)
      .then((res) => res.json())
      .then((data) => {
        setNationalities(data.nationalities);
      });
  }, []);
  useEffect(() => {
    fetchPositionData(); // fetch all position list for dropdown
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/job/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        })
        .then((res) => {
          setLoading(false);
          const data = res.data.details;
          form.setFieldsValue({
            position: data.positionId.name,
            vacancy: data.vacancy,
            // dateRange: [moment(data.dates[0].startDate), moment(data.dates[0].endDate)],
            nationalities: data.nationalities,
            skills: data.skills,
            languages: data.languages,
            description: data.description,
            jobPostDate: dayjs(data.publishedDate),
            jobEndDate: dayjs(data.endDate),
            country: data.country,
          });
          setDescription(data.description);
          setPrevDate(data.dates);
          setSelectedPosition(data.positionId._id);
          setRangeValue([data.minRatePerHour, data.maxRatePerHour]);
          setAgeRangeValue([data.minAge, data.maxAge]);
          setExpRangeValue([data.minExperience, data.maxExperience]);
          setTotalDays(data.dates.length);
          setDates(data.dates);
          setFinalizedDates(data.dates);
        });
    }
  }, [id, form]);

  const onFinish = async () => {
    const values = form.getFieldsValue(true);
    setLoading(true);
    const payload = {
      positionId: selectedPosition,
      clientId: getUserId(),
      minRatePerHour: rangeValue[0],
      maxRatePerHour: rangeValue[1],
      vacancy: values.vacancy,
      dates: finalizedDates,
      nationalities: values?.nationalities,
      skills: values.skills,
      minExperience: expRangeValue[0],
      maxExperience: expRangeValue[1],
      languages: values.languages,
      description: description,
      publishedDate: dayjs(values.jobPostDate).format("YYYY-MM-DD"),
      endDate: dayjs(values.jobEndDate).format("YYYY-MM-DD"),
      minAge: ageRangeValue[0],
      maxAge: ageRangeValue[1],
    };
    if (id) {
      payload.id = id;
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/job/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          form.resetFields();
          res.json();
        })
        .then(() => {
          setLoading(false);
          setModal(true);
        });
      return;
    }
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/job/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        form.resetFields();
        setModal(true);
      });
  };

  const countDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.toDateString() === end.toDateString()) {
      return "1";
    }

    const timeDifference = Math.abs(end - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1; // Add 1 to include both start and end dates

    return `${dayDifference}`;
  };

  const trackStyle = [{ backgroundColor: "#C6A34F" }];
  const handleStyle = {
    backgroundColor: "#C6A34F",
    borderColor: "#C6A34F",
  };

  useEffect(() => {
    console.log(dates);
  }, [dates]);
  useEffect(() => {
    const totalDay = finalizedDates?.length
      ? finalizedDates?.reduce((acc, item) => {
          const start = new Date(item?.startDate);
          const end = new Date(item?.endDate);
          const dayDifference = Math.ceil(
            Math.abs(end - start) / (1000 * 3600 * 24)
          );
          return acc + dayDifference + 1;
        }, 0)
      : 0;
    setTotalDays(totalDay);
  }, [finalizedDates]);
  return (
    <div
      className="container mt-2"
      style={{
        minHeight: "80vh",
      }}
    >
      <div className="innerDashSearchItems d-flex align-items-center my-2 justify-content-between">
        <div>
          <Link to="/client-dashboard">
            <button className="innerdashboardBackButton">
              <img
                src="https://mhpremierstaffingsolutions.com/assets/frontend/images/InvoiceAndPayment/arrow.png"
                className="img-fluid"
                alt="arrow"
              />
            </button>
          </Link>
          {
            <img
              src="/assets/frontend/images/Dashboardimages/dashboard 1/createJobPost.png"
              className="img-fluid"
              style={{
                height: "30px",
                width: "30px",
              }}
              alt="arrow"
            />
          }
          <span className="innerDashSearchItemsSpan">
            {id ? t("update_job_post") : t("create_job_post")}
          </span>
        </div>
        <div>
          <Link to="/job-requests">
            <Button
              type="primary"
              style={{
                backgroundColor: "#C6A34F",
                border: "none",
                color: "#fff",
                height: "30px",
                fontSize: "14px",
                fontWeight: "600",
                borderRadius: "0px 15px 0px 15px",
              }}
            >
              {t("my_job_posts")}
            </Button>
          </Link>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="form-group mb-5 mt-3"
          style={{
            border: "1px solid #A6A6A6",
            padding: "24px",
            borderRadius: "5px",
            boxShadow: "0px 0px 1px #A6A6A6",
            backgroundColor: "#fff",
          }}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={[20, 4]}>
              {/* position  */}

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t("position")}
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Position!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    disabled={id ? true : false}
                    allowClear={false}
                    optionFilterProp="children"
                    showSearch={true}
                    placeholder="Select Position"
                    value={selectedPosition}
                    onChange={(value, item) => {
                      setSelectedPosition(value);
                    }}
                    initialvalues={positions ? positions[0]?.name : undefined}
                  >
                    {positions?.map((item, index) => (
                      <Select key={index} value={item?._id}>
                        {item?.name}
                      </Select>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* select nationality  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t("nationality")}
                  name="nationalities"
                  rules={[
                    {
                      required: true,
                      message: t("please_enter_nationality"),
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    mode="multiple"
                    allowClear={false}
                    optionFilterProp="children"
                    showSearch={true}
                    placeholder={t("select_natinality")}
                    onChange={(value, item) => {
                      setSelectedNationality(value);
                    }}
                    defaultValue={
                      nationalities ? nationalities[0]?.name : undefined
                    }
                  >
                    {nationalities?.map((item, index) => (
                      <Select key={index} value={item?.nationality}>
                        {item?.nationality}
                      </Select>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* select skills  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t("skills")}
                  name="skills"
                  rules={[
                    {
                      required: true,
                      message: t("please_select_your_skills"),
                    },
                  ]}
                >
                  <Select
                    size="large"
                    mode="multiple"
                    allowClear={false}
                    style={{ width: "100%" }}
                    placeholder={t("select_skills")}
                  >
                    {allSkills?.map((item, index) => (
                      <Select key={index} value={item?.value}>
                        {item?.label}
                      </Select>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label={t("hourly_rate")} name="hourlyRate">
                  <div
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      padding: "0px 2px",
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                          {t("min")}{" "}
                          <span className="minHourlyRateSpan">
                            {rangeValue[0]} {getUserCurrencyByCountry()}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <div
                          style={{ textAlign: "right" }}
                          className="minHourlyRate"
                        >
                          {t("max")}{" "}
                          <span className="minHourlyRateSpan">
                            {rangeValue[1]} {getUserCurrencyByCountry()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Slider
                      range
                      min={0}
                      max={60}
                      onAfterChange={(val) => {
                        setRangeUpdateValue(val);
                      }}
                      value={rangeValue}
                      onChange={(value) => {
                        setRangeValue(value);
                      }}
                      trackStyle={trackStyle}
                      handleStyle={[handleStyle, handleStyle]}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label={t("experience")} name="experience">
                  <div
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      padding: "0px 2px",
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                          {t("min")}{" "}
                          <span className="minHourlyRateSpan">
                            {expRangeValue[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <div
                          style={{ textAlign: "right" }}
                          className="minHourlyRate"
                        >
                          {t("max")}{" "}
                          <span className="minHourlyRateSpan">
                            {expRangeValue[1]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Slider
                      range
                      min={0}
                      max={50}
                      onAfterChange={(val) => {
                        setExpRangeValue(val);
                      }}
                      value={expRangeValue}
                      onChange={(value) => {
                        setExpRangeValue(value);
                      }}
                      trackStyle={trackStyle}
                      handleStyle={[handleStyle, handleStyle]}
                    />
                  </div>
                </Form.Item>
              </Col>
              {/* vacancy  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <MHInput
                  type="number"
                  label={t("vacancy")}
                  placeholder={t("enter_vacancy_number")}
                  defaultValue={1}
                  min={1}
                  max={100}
                  name="vacancy"
                  rules={[
                    {
                      required: true,
                      message: t("please_input_your_acancy"),
                    },
                  ]}
                />
              </Col>
              {/* job post date  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <MHInput
                  type="date"
                  label={t("job_post_date")}
                  placeholder={t("enter_job_post_date")}
                  name="jobPostDate"
                  rules={[
                    {
                      required: true,
                      message: t("please_input_your_job_post_date!")
                    },
                  ]}
                />
              </Col>
              {/* job end date  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <MHInput
                  type="date"
                  label={t("job_end_date")}
                  placeholder={t("enter_job_end_date")}
                  name="jobEndDate"
                  rules={[
                    {
                      required: true,
                      message: t("please_input_your_job_end_date!"),
                    },
                  ]}
                />
              </Col>
              {/* preferred language  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t("preferred_language")}
                  name="languages"
                  rules={[
                    {
                      required: true,
                      message:t("please_input_your_preferred_language!"),
                    },
                  ]}
                >
                  {/* multi select  */}
                  <Select
                    size="large"
                    mode="multiple"
                    allowClear={false}
                    style={{ width: "100%" }}
                    placeholder={t("select_preferred_language")}
                  >
                    {languages?.map((item, index) => (
                      <Select key={index} value={item?.value}>
                        {item?.label}
                      </Select>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* age  */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Age" name="age">
                  <div
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      padding: "0px 2px",
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                        {t("min")}{" "}
                          <span className="minHourlyRateSpan">
                            {ageRangeValue[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <div
                          style={{ textAlign: "right" }}
                          className="minHourlyRate"
                        >
                          {t("max")}{" "}
                          <span className="minHourlyRateSpan">
                            {ageRangeValue[1]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Slider
                      range
                      min={0}
                      max={100}
                      onAfterChange={(val) => {
                        setAgeRangeValue(val);
                      }}
                      value={ageRangeValue}
                      onChange={(value) => {
                        setAgeRangeValue(value);
                      }}
                      trackStyle={trackStyle}
                      handleStyle={[handleStyle, handleStyle]}
                    />
                  </div>
                </Form.Item>
              </Col>
              {/* Description */}
              <Col
                xs={24}
                sm={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setDates(finalizedDates);
                    setCalenderModal(true);
                  }}
                  style={{
                    width: "30%",
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "#C6A34F",
                    border: "none",
                  }}
                >
                  <span>{t("select_date")} ({totalDays ? totalDays :0})</span>
                </Button>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label={t("description")} name="description">
                  <JobDetails values={description} setValue={setDescription} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <button
                  className="btn btn-lg btn-success"
                  style={{
                    backgroundColor: "#C6A34F",
                    border: "none",
                    color: "#fff",
                    width: "100%",
                    height: "50px",
                    fontSize: "18px",
                    fontWeight: "600",
                    borderRadius: "0px 15px 0px 15px",
                  }}
                  type="submit"
                >
                  {id ? t("update_job_post") : t("create_job_post")}
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      <Modal
        title=""
        open={modal}
        closable={false}
        centered={true}
        confirmLoading={false}
        onCancel={() => {
          setModal(false);
        }}
        footer={null}
        width={400}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
            color: "#000",
            padding: "0px 60px",
          }}
        >
          {t("your_job_offer_has_been_posted")}
        </p>
        <button
          className="btn btn-lg btn-success"
          style={{
            backgroundColor: "#C6A34F",
            border: "none",
            color: "#fff",
            width: "100%",
            height: "50px",
            fontSize: "18px",
            fontWeight: "600",
            borderRadius: "0px 15px 0px 15px",
          }}
          onClick={() => {
            setModal(false);
            navigate("/job-requests");
          }}
        >
          {t("ok")}
        </button>
      </Modal>
      <Modal
        title=""
        open={false}
        closable={true}
        confirmLoading={false}
        onCancel={() => {
          setCalenderModal(false);
        }}
        footer={null}
        width={"90%"}
      >
        <BookForJob
          id={id}
          setSelectedDateTime={setSelectedDateTime}
          setCalenderModal={setCalenderModal}
        />
      </Modal>
      <Modal
        title=""
        open={calenderModal}
        closable={true}
        confirmLoading={false}
        onCancel={() => {
          setCalenderModal(false);
        }}
        footer={null}
        width={"80%"}
      >
        <MHCalender
          dates={dates}
          setDates={setDates}
          onSubmitHandler={() => {
            setFinalizedDates(dates);
            setCalenderModal(false);
          }}
        />
      </Modal>
      <Modal
        title="Date & Time"
        open={prevModal}
        onOk={() => {
          setPrevModal(false);
        }}
        onCancel={() => {
          setPrevModal(false);
        }}
      >
        {prevDate?.map((item, index) => (
          <>
            <Row
              key={index}
              style={{
                background: "#FAFAFA",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Col style={{}}>
                <div>
                  <FcCalendar
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  {item?.startDate} - {item?.endDate}
                </div>
                <div
                  style={{
                    color: "#C6a34f",
                    fontWeight: "bold",
                  }}
                >
                  {countDates(item?.startDate, item?.endDate)} Days
                </div>
                <div>
                  {item?.startTime} - {item?.endTime}
                </div>
              </Col>
            </Row>
          </>
        ))}
      </Modal>
    </div>
  );
};

export default CreateJobPost;
