import { Button, List, Modal, Row, TimePicker, message } from "antd";
import axios from "axios";
import { addMonths, isSameDay } from "date-fns";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { FcCalendar } from "react-icons/fc";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import Loader from "../../loadar/Loader";
import Calendar from "./Newcalendar";
import "./daycss.css";
import nodate from "./nodate.png";
import { getTermsCondition } from "../../../locals/terms_condition";
export default function BookForClientCalender({ selectedId, selectedClient, allowOrDeny , resetForm }) {
  console.log(selectedId);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const position = queryParams.get("positionId");
  const [bookedDates, setBookedDates] = useState([]);
  const [pendingDates, setPendingDates] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [rangeStart, setRangeStart] = useState(null);
  const [timeRanges, setTimeRanges] = useState({});
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(true);
  const [usersData, setUsersData] = useState({});
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callTheApi, setCallTheApi] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [callBack, setCallback] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [timerShow, setTimerShow] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  // Initialize state variables
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [dateAndTimeRanges, setDateAndTimeRanges] = useState({});
  const [datesWithTimes, setDatesWithTimes] = useState([]);
  const { currencyType } = useContext(ContextApi);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const [termsConditions, setTermsConditions] = useState();
  const [uniformProvided, setUniformProvided] = useState("yes");

  const [isUniformModalOpen, setUniformModalOpen] = useState(false);
  const [dressSamples, setDressSamples] = useState([]);
  const location = useLocation();

  const { state } = location;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${selectedId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedEmployee(data?.details);
      })
      .catch((error) => {
        console.error("There was an error fetching the position:", error);
      });
  }, [selectedId]);

  console.log("selectedEmployee:", selectedEmployee);

  const hasDress = selectedEmployee?.hasUniform || false;
  console.log("hasDress", hasDress);
  const positionId = selectedEmployee?.positionId;

  const handleUniformChange = (event) => {
    setUniformProvided(event.target.value);
  };

  console.log("uniformProvided", uniformProvided);
  const openUniformModal = () => {
    setUniformModalOpen(true);
  };
  const closeUniformModal = () => {
    setUniformModalOpen(false);
  };

  // // position info
  useEffect(() => {
    if (position) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/positions/${position}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDressSamples(data?.details?.images);
        })
        .catch((error) => {
          console.error("There was an error fetching the position:", error);
        });
    }
  }, [position]);

  console.log(dressSamples);

  const carouselSettings = {
    infiniteLoop: true,
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    autoPlay: true,
    // disable the prev / next buttons
    showArrows: false,
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/terms-conditions`)
      .then((response) => response.json())
      .then((data) => {
        setTermsConditions(data.termsConditions);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the terms and conditions:",
          error
        );
      });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("timeRanges:", timeRanges);
    if (typeof timeRanges === "object" && !Array.isArray(timeRanges)) {
      let showButton = true;

      // Check if any object has a missing endTime
      const hasMissingEndTime = Object.keys(timeRanges).some((key) => {
        const item = timeRanges[key];
        return item && item.startTime && !item.endTime;
      });

      if (hasMissingEndTime) {
        showButton = false;
      }

      setShowSubmitButton(showButton);
    } else {
      console.log("timeRanges is not an object or is not defined.");
      setShowSubmitButton(false);
    }
  }, [timeRanges]);

  useEffect(() => {
    let isDateToFound = false;

    ranges.forEach((range) => {
      if (range.date && range.date.to) {
        isDateToFound = true;
      }
    });

    selectedDays.forEach((day) => {
      if (day.date && day.date.to) {
        isDateToFound = true;
      }
    });

    setShowSubmitButton(isDateToFound);
  }, [ranges, selectedDays]);

  const handleTimeChange = (time, type, date) => {
    if (date instanceof Date && !isNaN(date)) {
      const dateKey = date.toISOString();
      setTimeRanges({
        ...timeRanges,
        [dateKey]: { ...timeRanges[dateKey], [type]: time },
      });
      setIsTimeSelected(true);
    } else {
      console.warn("Invalid date object passed to handleTimeChange");
    }
  };

  const handleSubmit = async () => {
    // Create requestData
    const requestData = {
      employeeId: selectedId,
      requestDate: [
        ...ranges.map((range) => ({
          startDate: moment(range.from).format("YYYY-MM-DD"),
          endDate: moment(range.to).format("YYYY-MM-DD"),
          startTime: timeRanges[range.from.toISOString()]?.startTime
            ? timeRanges[range.from.toISOString()].startTime.format("hh:mm A")
            : null, // Use null for missing time
          endTime: timeRanges[range.from.toISOString()]?.endTime
            ? timeRanges[range.from.toISOString()].endTime.format("hh:mm A")
            : null, // Use null for missing time
        })),
        ...selectedDays.map((day) => ({
          startDate: moment(day).format("YYYY-MM-DD"),
          endDate: moment(day).format("YYYY-MM-DD"),
          startTime: timeRanges[day.toISOString()]?.startTime
            ? timeRanges[day.toISOString()].startTime.format("hh:mm A")
            : null, // Use null for missing time
          endTime: timeRanges[day.toISOString()]?.endTime
            ? timeRanges[day.toISOString()].endTime.format("hh:mm A")
            : null, // Use null for missing time
        })),
      ],
    };

    // console.log("Request Data:", requestData);
    // Validate requestData
    const isValid = requestData.requestDate.every(
      (item) =>
        item.startDate &&
        item.endDate &&
        item.startTime !== null &&
        item.endTime !== null
    );

    if (!isValid) {
      // Using Modal
      Modal.warning({
        title: "Incomplete Data",
        content:
          "Some data is missing, please complete all fields before submitting.",
        style: {
          marginTop: "20vh",
        },
      });
      return;
    }

    // console.log(`Request Data: ${requestData}`);
    setIsModalOpen(true);
    // Log the requestData to console
    setIsTermsModalVisible(true);

    // console.log("Request Data:", requestData);
    setFinalData(requestData);

    // console.log("****");
  };

  useEffect(() => {
    let unmount = false;
    // console.log("Call the api", callTheApi);
    if (finalData && Object.keys(finalData).length > 0) {
      handleCreateShortList(finalData);
    }
    return () => {
      unmount = true;
    };
  }, [callBack]);

  const handleCreateShortList = async (updatedData) => {
    const payload = {
      employeeId: updatedData.employeeId,
      hiredBy: selectedClient,
      bookedDate: updatedData.requestDate,
      uniformMandatory: hasDress ? uniformProvided === "yes" : false,
      status: allowOrDeny
    };
    console.log(payload)

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/book-history`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (res.data.statusCode === 201) {
        // handleBookUser(res.data.details._id);
        // toast.success(`Updated successfully`);
        setConfirmLoading(false);
        toast.success(`Booked successfully`);
        resetForm()
      }
    } catch (error) {
      // console.log("Error:", error.response.data.errors[0].msg);
      toast.error(error.response.data.errors[0].msg);
    }
  };
  const handleBookUser = async (shortListId) => {
    const newData = {
      selectedShortlist: [shortListId],
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/hired-histories/create`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      // console.log(res);
      if (res.data.statusCode === 200) {
        toast.success(`Hired successfully`);
        setConfirmLoading(false);
        navigate("/success-message");
      }
    } catch (error) {
      // console.log("Error:", error.response.data.errors[0].msg);
      toast.error(error.response.data.errors[0].msg);
    }
  };

  useEffect(() => {
    // console.log("Current timeRanges:", timeRanges);
  }, [timeRanges]);

  const handleDayClick = (day, { selected }) => {
    if (selectedStartDate && !selectedEndDate) {
      // Set the end date if start date is selected
      setSelectedEndDate(day);
    } else {
      // Set the start date if no start date is selected
      setSelectedStartDate(day);
      setSelectedEndDate(null);
    }

    // Reset the time picker for the selected date
    const selectedDateKey = day.toISOString();
    setTimeRanges({ ...timeRanges, [selectedDateKey]: {} });
    // setShowSubmitButton(false);

    // Set the selected date and its time ranges in the dictionary
    setDateAndTimeRanges({
      ...dateAndTimeRanges,
      [selectedDateKey]: {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        timeRanges: {},
      },
    });

    if (isDaySelected(day) && !rangeStart) {
      const rangeToRemove = ranges.find((range) =>
        isDateWithinRange(day, range)
      );
      if (rangeToRemove) {
        deselectDateRange(rangeToRemove);
      }
      deselectDay(day);
      return; // Return early, since the day is now deselected
    }

    if (rangeStart) {
      const sortedDates = [rangeStart, day].sort(
        (a, b) => a.getTime() - b.getTime()
      );
      const newRange = { from: sortedDates[0], to: sortedDates[1] };
      // Check if the new range overlaps with any existing range
      const isRangeOverlapping = ranges.some((range) => {
        return newRange.from <= range.to && newRange.to >= range.from;
      });

      if (isRangeOverlapping) {
        // console.log("Range partially overlaps with an existing selection.");
        message.error(
          "This range partially overlaps with an existing selection."
        );
        return;
      }
      let dateCursor = new Date(newRange.from);
      let isAnyDateUnavailable = false;

      while (dateCursor <= newRange.to) {
        if (
          bookedDates.some((dateRange) =>
            isDateWithinRange(dateCursor, dateRange)
          )
        ) {
          isAnyDateUnavailable = true;
          break;
        }
        dateCursor.setDate(dateCursor.getDate() + 1);
      }

      if (isAnyDateUnavailable) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // Check if the range includes disabled dates
      if (isRangeIncludingDisabledDates(newRange)) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // Assuming `isRangeIncludingDisabledDates` is a function that returns true or false
      if (!isRangeIncludingDisabledDates(newRange)) {
        setRanges((prev) => [...prev, newRange]);
        setDatesWithTimes((prev) => [
          ...prev,
          { from: newRange.from, to: newRange.to, start: "", end: "" },
        ]);

        // Remove individual days that are now part of a range
        setSelectedDays((prev) =>
          prev.filter((d) => !isDateWithinRange(d, newRange))
        );
      }
      setRangeStart(null);
    } else {
      // Existing logic for handling individual days
      const isUnavailable = bookedDates.some((dateRange) =>
        isDateWithinRange(day, dateRange)
      );

      if (isUnavailable) {
        message.error("This date is either booked or pending.");
        return;
      }

      // Check if the clicked day is within a disabled date range
      if (isRangeIncludingDisabledDates({ from: day, to: day })) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // If the clicked day is already selected, remove it from selectedDays
      if (selected) {
        setSelectedDays((prev) => prev.filter((d) => !isSameDay(d, day)));
      } else {
        setSelectedDays((prev) => [...prev, day]);
      }
      setRangeStart(day);
    }
    const selectedDateElement = document.getElementById(day.toString());
    if (selectedDateElement) {
      selectedDateElement.classList.add("selected-date");
    }
  };

  const deselectDateRange = (dateRange) => {
    setRanges((prev) =>
      prev.filter(
        (range) =>
          !(
            isSameDay(range.from, dateRange.from) &&
            isSameDay(range.to, dateRange.to)
          )
      )
    );
    setDatesWithTimes((prev) =>
      prev.filter(
        (range) =>
          !(
            isSameDay(range.from, dateRange.from) &&
            isSameDay(range.to, dateRange.to)
          )
      )
    );
  };

  const deselectDay = (dayToRemove) => {
    setSelectedDays((prevSelectedDays) => {
      return prevSelectedDays.filter(
        (day) => day.getTime() !== dayToRemove.getTime()
      );
    });
    if (selectedDays?.length) {
      setSelectedStartDate([]); // it contains first date only
      setRangeStart(null);
    }
  };

  const isRangeIncludingDisabledDates = ({ from, to }) => {
    const isUnavailableDateWithinRange = (date) => {
      return (
        unavailableDates.some(
          (disabledDate) =>
            disabledDate &&
            disabledDate.from &&
            disabledDate.to &&
            isDateWithinRange(date, disabledDate)
        ) ||
        bookedDates.some(
          (bookedDate) =>
            bookedDate &&
            bookedDate.from &&
            bookedDate.to &&
            isDateWithinRange(date, bookedDate)
        ) ||
        pendingDates.some(
          (pendingDate) =>
            pendingDate &&
            pendingDate.from &&
            pendingDate.to &&
            isDateWithinRange(date, pendingDate)
        )
      );
    };

    const isAnyDateWithinRangeUnavailable = Array.from(
      { length: (to - from) / (24 * 60 * 60 * 1000) + 1 },
      (_, i) => new Date(from.getTime() + i * 24 * 60 * 60 * 1000)
    ).some((date) => isUnavailableDateWithinRange(date));

    if (isAnyDateWithinRangeUnavailable) {
      // console.log("One or more dates in the range are unavailable.");
      return true;
    }

    return false;
  };

  const isDateWithinRange = (date, { from, to }) => {
    // Get the start and end of the day for the 'from' and 'to' dates
    const startOfDayFrom = new Date(from);
    const endOfDayTo = new Date(to);
    // Set the time for the start of the day (00:00:00.000)
    startOfDayFrom.setHours(0, 0, 0, 0);

    // Set the time for the end of the day (23:59:59.999)
    endOfDayTo.setHours(23, 59, 59, 999);

    // Check if the 'date' falls within the adjusted range
    const withinRange =
      date.getTime() >= startOfDayFrom.getTime() &&
      date.getTime() <= endOfDayTo.getTime();

    return withinRange;
  };

  // Get the current month
  const currentMonth = new Date();
  currentMonth.setDate(1);
  currentMonth.setHours(0, 0, 0, 0);

  // Get the next month
  const nextMonth = addMonths(currentMonth, 1);

  useEffect(() => {
    setIsLoading(true); // Set loading to true before fetching data

    const fetchData = async () => {
      //   console.log(selectedId);
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/working-history/${selectedId}`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        // console.log(data.result);
        setBookedDates(formatDates(data.result.bookedDates));
        setPendingDates(formatDates(data.result.pendingDates));
        setUnavailableDates(formatDates(data.result.unavailableDates));
        setUsersData(data.result.employeeDetails);
      }
    };

    fetchData().finally(() => {
      setIsLoading(false); // Set loading to false after fetching data
    });
  }, [selectedId]);

  const formatDates = (dates) => {
    return dates.map((dateRange) => ({
      from: new Date(dateRange.startDate),
      to: new Date(dateRange.endDate),
    }));
  };
  const isDaySelected = (day) => {
    for (let range of ranges) {
      if (isDateWithinRange(day, range)) {
        return true;
      }
    }
    return selectedDays.some((selectedDay) => isSameDay(day, selectedDay));
  };
  const today = moment().startOf("day").toDate();

  const goBack = () => {
    navigate(-1);
  };

  const handleTermsAccept = () => {
    // console.log("Terms accepted");
    setConfirmLoading(true);
    // Handle accept action
    setIsTermsModalVisible(false);
    setCallTheApi(true);
    setCallback(!callBack);
  };

  const handleTermsReject = () => {
    // Handle reject action
    setIsTermsModalVisible(false);
    setIsModalOpen(false); // Close this modal
    // console.log("Terms rejected");
  };

  const calculateEstimatedAmount = () => {
    let totalAmount = 0;

    const calcAmountForTimeRange = (start, end, dayCount = 1) => {
      if (start?.isValid() && end?.isValid()) {
        console.log("Start:", start);
        console.log("End:", end);

        const duration = moment.duration(end.diff(start));
        let hours = parseFloat(duration.asHours().toFixed(2)); // Round to two decimal places
        if (hours < 0) {
          hours = 24 + hours;
        }
        totalAmount += hours * dayCount * usersData.hourlyRate;
        // console.log("Total hour:", hours);
        // console.log("Total amount:", totalAmount);
      } else {
        // console.log("Invalid start or end time.");
      }
    };

    // For individual days
    selectedDays.forEach((day) => {
      const dayTime = timeRanges[day.toISOString()];
      calcAmountForTimeRange(dayTime?.startTime, dayTime?.endTime);
    });

    // For ranges
    ranges.forEach((range) => {
      const from = moment(range.from);
      const to = moment(range.to);
      const dayCount = to.diff(from, "days") + 1;

      // console.log("Day Count for Range:", dayCount);

      const rangeTime = timeRanges[range.from.toISOString()];
      if (rangeTime) {
        calcAmountForTimeRange(
          rangeTime?.startTime,
          rangeTime?.endTime,
          dayCount
        );
      }
    });

    // Update the estimated amount
    setEstimatedAmount(totalAmount);
  };

  useEffect(() => {
    calculateEstimatedAmount();
  }, [ranges, selectedDays, timeRanges]);

  const deleteAllDates = () => {
    Modal.confirm({
      title: "Are you sure you want to delete all selected dates?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // Reset the selectedDays and ranges back to empty arrays
        setSelectedDays([]);
        setRanges([]);
        setDatesWithTimes([]);
        setTimeRanges({});
        setSelectedStartDate([]); // it contains first date only
        setRangeStart(null);
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        // <></>
        <>
          <Row
            justify="space-around"
            className="container mx-auto"
            style={{ margin: "1rem" }}
          >
            {/* First Part: Calendar */}

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <Calendar
                    bookedDates={bookedDates}
                    pendingDates={pendingDates}
                    unavailableDates={unavailableDates}
                    selectedDays={selectedDays}
                    ranges={ranges}
                    timeRanges={timeRanges}
                    handleDayClick={handleDayClick}
                    deselectDateRange={deselectDateRange}
                    deselectDay={deselectDay}
                    handleTimeChange={handleTimeChange}
                    handleCreateShortList={handleCreateShortList}
                    handleBookUser={handleBookUser}
                    estimatedAmount={estimatedAmount}
                    deleteAllDates={deleteAllDates}
                    // showModal={showModal}
                    isTimeSelected={isTimeSelected}
                    usersData={usersData}
                    goBack={goBack}
                    isSameDay={isSameDay}
                    isDateWithinRange={isDateWithinRange}
                    today={today}
                    nextMonth={nextMonth}
                    state={
                      selectedEmployee
                        ? {
                            ...state,
                            positionId: selectedEmployee,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            </div>

            {/* Second Part: Selected Dates and Ranges */}
            <div className="selected-date-style">
              {/* Your JSX to display selected dates and ranges */}
              <div className="text-center">
                {ranges.length || selectedDays?.length ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <h5>Days Selected</h5>
                    <button
                      onClick={
                        selectedDays.length === 0 && ranges.length === 0
                          ? null
                          : deleteAllDates
                      }
                      style={{
                        backgroundColor:
                          selectedDays.length === 0 && ranges.length === 0
                            ? "#ccc"
                            : "red",
                        color: "#fff",
                        cursor:
                          selectedDays.length === 0 && ranges.length === 0
                            ? "not-allowed"
                            : "pointer",
                        outline: "none",
                        border: "none",
                        borderRadius: "5px",
                        padding: "5px 10px",
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                ) : null}
                <div>
                  {ranges.length === 0 && selectedDays.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "445px",
                      }}
                    >
                      <img src={nodate} alt="No Date" />
                    </div>
                  ) : (
                    <>
                      {ranges.map((range, idx, dates) => (
                        <DateTimeComponent
                          date={range}
                          idx={idx}
                          type="range"
                          deselect={deselectDateRange}
                          timeRanges={timeRanges}
                          setTimeRanges={setTimeRanges}
                          handleTimeChange={handleTimeChange}
                          setTimerShow={setTimerShow}
                          timerShow={timerShow}
                          dates={dates}
                        />
                      ))}

                      {selectedDays.map((day, idx) => (
                        <DateTimeComponent
                          key={`day-${idx}`}
                          date={day}
                          idx={idx}
                          type="day"
                          deselect={deselectDay}
                          timeRanges={timeRanges}
                          setTimeRanges={setTimeRanges}
                          handleTimeChange={handleTimeChange}
                          setTimerShow={setTimerShow}
                          timerShow={timerShow}
                        />
                      ))}
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            Total Estimate Amount
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            {currencyType}
                            {estimatedAmount.toFixed(2)}
                          </p>
                        </div>
                      </div>

                      {hasDress && (
                        <>
                          <hr
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          />

                          <div
                            style={{
                              width: "350px",
                              textAlign: "center",
                              backgroundColor: "#FAFAFA",
                              margin: "10px",
                              padding: "10px",
                              borderRadius: "10px",
                              lineHeight: "1.3",
                            }}
                          >
                            <div>
                              <p
                                className="fw-bold h6"
                                style={{
                                  lineHeight: "0.5",
                                }}
                              >
                                Uniform Provided?
                              </p>
                              <p
                                className=""
                                style={{
                                  color: "#A6A6A6",
                                }}
                              >
                                Will you provide uniform to the employees?
                              </p>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <div
                                  style={{
                                    marginRight: "10px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="uniform"
                                    id="yes"
                                    value="yes"
                                    checked={uniformProvided === "yes"}
                                    onChange={handleUniformChange}
                                  />
                                </div>
                                <div
                                  className=""
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <label htmlFor="yes" className="fw-bold">
                                    Yes, We will
                                  </label>
                                  <p
                                    style={{
                                      color: "#A6A6A6",
                                    }}
                                  >
                                    We will provide different uniforms for our
                                    employees
                                    <p
                                      style={{
                                        color: "#C6a34f",
                                        textDecoration: "underline",
                                        marginTop: "3px",
                                        cursor: "pointer",
                                        marginBottom: "0px",
                                      }}
                                      onClick={openUniformModal}
                                    >
                                      View Sample Uniform
                                    </p>
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex align-items-center">
                                <div
                                  style={{
                                    marginRight: "10px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="uniform"
                                    id="no"
                                    value="no"
                                    checked={uniformProvided === "no"}
                                    onChange={handleUniformChange}
                                  />
                                </div>
                                <div
                                  className="m-1"
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <label htmlFor="no" className="fw-bold">
                                    No, We won't
                                  </label>
                                  <p
                                    style={{
                                      color: "#A6A6A6",
                                    }}
                                  >
                                    We will not provide any uniforms for our
                                    employees. It's not mandatory
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {showSubmitButton && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#C6a34f",
                              color: "#fff",
                              cursor: "pointer",
                              outline: "none",
                              border: "none",
                            }}
                          >
                            Book Now
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </>
      )}
      <Modal
        title=""
        open={isUniformModalOpen}
        visible={isUniformModalOpen}
        onCancel={closeUniformModal}
        footer={null}
        width={350}
      >
        <div className="text-center">
          <p className="fw-bold h4">Uniform List</p>{" "}
          {/* <p>Here are the uniforms for different posts provided by us</p> */}
        </div>
        <div className="d-flex justify-content-center">
          <>
            <Carousel {...carouselSettings}>
              {dressSamples?.map((dress, index) => (
                <div key={index}>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${dress}`}
                    alt={`Dress ${index + 1}`}
                    style={{
                      width: "200px",
                      height: "300px",
                      // objectFit: "cover",
                      borderRadius: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </>
        </div>
      </Modal>

      <Modal
        title="Terms & Conditions"
        open={isModalOpen}
        visible={isTermsModalVisible}
        onOk={handleTermsAccept}
        onCancel={handleTermsReject}
        confirmLoading={confirmLoading} // Set confirmLoading to manage loading state
        okText="Accept"
        cancelText="Reject"
      >
        <p className="mb-3">Do you accept the terms & conditions?</p>

        <List
          style={{
            height: "450px",
            overflowY: "scroll",
          }}
          // dataSource={termsConditions?.filter((term) => term.active)}
          dataSource={getTermsCondition()}
          renderItem={(term, index) => (
            <List.Item>
              <p className="mb-2">
                <span style={{ fontWeight: "bold" }}>{index + 1}:</span>{" "}
                {term}
              </p>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}

// TimePicker Component
const TimePickers = ({ timeRanges, timeKey, handleTimeChange, index }) => {
  return (
    <div style={{}}>
      <TimePicker
        value={timeRanges[timeKey]?.startTime}
        placeholder="Start Time"
        onChange={(time) =>
          handleTimeChange(time, "startTime", new Date(timeKey), index)
        }
        format="HH:mm"
      />
      -
      <TimePicker
        value={timeRanges[timeKey]?.endTime}
        placeholder="End Time"
        onChange={(time) =>
          handleTimeChange(time, "endTime", new Date(timeKey), index)
        }
        format="HH:mm"
      />
      {/* <TimePicker.RangePicker format="HH:mm" /> */}
    </div>
  );
};

// Function to format individual dates
const formatDate = (date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

// Function to format date ranges
const formatDateRange = (from, to) => {
  if (from && !to) {
    return `${formatDate(new Date(from))} - Select endDate`;
  }

  const fromDate = formatDate(new Date(from));
  const toDate = formatDate(new Date(to));

  return fromDate === toDate
    ? `${fromDate} - ${fromDate} `
    : `${fromDate} - ${toDate}`;
};

const DateTimeComponent = ({
  date,
  idx,
  type,
  deselect,
  timeRanges,
  setTimeRanges,
  handleTimeChange,
  setTimerShow,
  timerShow,
  dates = false,
}) => {
  console.log("index in date time component", idx);
  let dateKey;
  if (type === "range") {
    if (date.from instanceof Date) {
      dateKey = date.from.toISOString();
    }
  } else {
    if (date instanceof Date) {
      dateKey = date.toISOString();
    }
  }
  const countDates = (startDate, endDate) => {
    if (!startDate || !endDate) {
      setTimerShow(false);
      return "Select End Date";
    }

    setTimerShow(true);

    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");

    if (start.isSame(end, "day")) {
      return `1 Day`;
    }

    const dayDifference = end.diff(start, "days") + 1; // Add 1 to include both start and end dates

    return `${dayDifference}
    ${dayDifference > 1 ? "Days" : "Day"}
    `;
  };

  // Make sure formatDateRange is defined or imported
  const displayDate =
    type === "range"
      ? formatDateRange(date.from, date.to)
      : formatDate(new Date(date));

  return (
    <div
      key={`${type}-${idx}`}
      style={{
        backgroundColor: "#FAFAFA",
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "10px",
        width: "350px",
      }}
    >
      <p>
        <FcCalendar
          style={{
            marginRight: "5px",
          }}
        />
        {displayDate}
        <span
          className="text-red-500 ml-2 cursor-pointer"
          onClick={() => deselect(date)}
        >
          <AiFillDelete
            style={{
              color: "red",
              marginLeft: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          />
        </span>
      </p>

      <div
        style={{
          color: "#C6a34f",
          fontWeight: "bold",
        }}
      >
        <p
          style={{
            color:
              countDates(date.from, date.to) === "Select End Date"
                ? "red"
                : "#C6a34f",
          }}
        >
          {countDates(date.from, date.to)}
        </p>
      </div>

      {/* Pass the necessary props to the TimePickers component */}
      {date.to && (
        <TimePickers
          timeKey={dateKey} // Use the dateKey here
          timeRanges={timeRanges}
          setTimeRanges={setTimeRanges}
          index={idx}
          handleTimeChange={handleTimeChange} // Pass down the handleTimeChange function
        />
      )}
    </div>
  );
};
