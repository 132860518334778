import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { token } from "../../utils/authentication";
import { jwtTokenDecode } from "../../utils/jwtDecode";

const SidebarPage = () => {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showNames, setShowNames] = useState(true);
  const jwt_decode = jwtTokenDecode();

  const fetchMenuItems = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/menu-permission/menu-list`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    return response.data.details;
  };

  const {
    isLoading,
    isError,
    data: menuItems,
  } = useQuery("menuItems", fetchMenuItems);

  const handleMenuClick = (itemId) => {
    setSelectedMenu(selectedMenu === itemId ? null : itemId);
  };

  const toggleShowNames = () => {
    setShowNames(!showNames);
  };

  const renderMenuItemContent = (item) => {
    return (
      <>
        <div className="sb-nav-link-icon">
          <i className={item.icon}></i>
        </div>
        {showNames && <span>{item.name}</span>}
      </>
    );
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      const isOpen = selectedMenu === item._id;
      return (
        <li key={item._id} className="nav-item">
          {hasChildren ? (
            <a
              className={`nav-link collapsed ${isOpen ? "active" : ""}`}
              href="#"
              onClick={() => handleMenuClick(item._id)}
            >
              {renderMenuItemContent(item)}
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
          ) : (
            <Link
              to={item.path}
              className="nav-link"
              onClick={() => setSelectedMenu(null)}
            >
              {renderMenuItemContent(item)}
            </Link>
          )}
          {hasChildren && (
            <div
              className={`collapse ${isOpen ? "show" : ""}`}
              id={item._id}
              aria-labelledby="headingOne"
            >
              <nav className="sb-sidenav-menu-nested nav">
                {item.children.map((child) => (
                  <Link
                    key={child._id}
                    to={child.path}
                    className="nav-link"
                    onClick={() => setSelectedMenu(null)}
                  >
                    {renderMenuItemContent(child)}
                  </Link>
                ))}
              </nav>
            </div>
          )}
        </li>
      );
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching menu items</div>;

  return (
    <>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav
            className="sb-sidenav accordion sb-sidenav-dark"
            id="sidenavAccordion"
          >
            <div className="sb-sidenav-menu">
              <div className="nav">{renderMenuItems(menuItems)}</div>
            </div>
            {/* <div className="sb-sidenav-footer">
              <div className="small">Toggle Menu Names:</div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={showNames}
                  onChange={toggleShowNames}
                />
                <span className="slider round"></span>
              </label>
            </div> */}
            <div className="sb-sidenav-footer">
              <div className="small">Logged in as:</div>
              {jwt_decode?.name}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SidebarPage;
