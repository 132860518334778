import { Button, Input, Modal, Space, Tooltip } from "antd";
import axios from "axios";
import { eachDayOfInterval, startOfMonth } from "date-fns";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";
import { encryptData } from "../../../utils/secure-stoarage";

const CourseDetailsModal = ({
  isModalOpen,
  setIsModalOpen,
  title,
  month,
  setMonth,
  id,
  selectedDate,
}) => {
  const navigate = useNavigate();
  const today = new Date();
  const [date, setDate] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [coupon, setCoupon] = useState();
  const [details, setDetails] = useState();
  const [columns, setColumn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [arrow, setArrow] = useState("Show");
  const [allSchedule, setAllSchedule] = useState([]);

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const handleOk = () => {
    if (details && details?.availableSeat == 0) {
      toast.error("No Seat Available!");
    } else if (allSchedule && allSchedule.length == 0) {
      toast.error(`No Class in ${moment(date).format("MMMM-YYYY")}`);
    } else if (moment(date).isBefore(moment(), "month")) {
      toast.error(
        `${moment(date).format(
          "MMMM-YYYY"
        )} class is no more, please choose from current month!`
      );
    } else {
      const payload = {
        courseId: details?._id,
        courseTitle: details?.title,
        amount: details?.amount,
        amountType: details?.amountType,
        month: moment(date).format("YYYY-MM"),
        couponCode: couponCode,
        coupon: coupon,
        image: `${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${details?.image}`,
      };

      localStorage.setItem("enrollData", encryptData(payload));
      navigate(`/course-enroll/student-form/${details?._id}`);
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDetails();
  };

  const getDiscountValue = (total, type, value) => {
    let amount = 0;
    if (type == "amount") {
      amount = Math.round(Number(total) - Number(value));
    } else {
      amount = Math.round(total - (total * value) / 100);
    }

    return amount;
  };

  const getCourseDetails = useCallback(async () => {
    const currentMonth = date;
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/course/${id}?month=${moment(
          date
        ).format("YYYY-MM")}`
      )
      .then((res) => {
        if (res.data?.statusCode == 200) {
          setDetails(res.data?.details?.courseDetails);
          const schedule = res.data?.details?.courseSchedule[0]?.schedule;
          setAllSchedule(schedule ?? []);

          const daysInMonth = eachDayOfInterval({
            start: startOfMonth(currentMonth),
            end: new Date(
              currentMonth.getFullYear(),
              currentMonth.getMonth() + 1,
              0
            ),
          });
          console.log(daysInMonth);
          let data = [];

          for (let i = 0; i < daysInMonth.length; i++) {
            data.push({
              name: `${daysInMonth[i]}`,
              items: [],
            });
          }

          if (schedule?.length > 0) {
            for (let i = 0; i < schedule?.length; i++) {
              for (let j = 0; j < data?.length; j++) {
                if (
                  moment(data[j]?.name).format("YYYY-MM-DD") ==
                  moment(schedule[i]?.date).format("YYYY-MM-DD")
                ) {
                  data[j].items?.push(schedule[i]);
                }
              }
            }
          }

          setColumn(data);
        }
      });
  }, [id, date]);

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    } else {
      setDate(today);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (id) {
      getCourseDetails();
    }
  }, [id, date]);

  const renderWeekdayLabels = () => {
    const weekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return (
      <div className="weekday-labels">
        {weekdayLabels.map((label) => (
          <div key={label} className="weekday-label">
            {label}
          </div>
        ))}
      </div>
    );
  };

  const renderDayCells = () => {
    const firstDayOfWeek = startOfMonth(date).getDay(); // Get the index of the first day of the month

    return (
      <div>
        {renderWeekdayLabels()} {/* Render the weekday labels */}
        <div className="calendar-grid">
          {/* Empty cells for days before the first day of the month */}
          {Array.from({ length: firstDayOfWeek }, (_, index) => (
            <div key={`empty-${index}`} className="calendar-day-modal empty" />
          ))}
          {/* Render day cells */}
          {columns?.map((day) => {
            // const isSelected = selectedDates.some((d) => isSameDay(d, day));
            // const isWeekend = day.getDay() === 0 || day.getDay() === 6; // Sunday or Saturday
            // const isBooked = bookedDates.some((d) => isSameDay(d, day));
            // const isUnavailable = unavailableDates.some((d) =>
            //   isSameDay(d, day)
            // );

            return (
              <div
                key={new Date(day.name).getDate()}
                className={`calendar-day-modal`}
              >
                <h6>{new Date(day.name).getDate()}</h6>
                <div className="d-flex flex-column">
                  {day?.items?.map((item, i) => (
                    <Tooltip
                      key={i}
                      placement="top"
                      title={item?.courseTitle}
                      arrow={mergedArrow}
                    >
                      <p>{item?.courseTitle}</p>
                    </Tooltip>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const availCoupon = useCallback(async () => {
    setErrorMsg("");
    setSuccessMsg("");
    setCoupon();
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/course-promotion/check-coupon?couponCode=${couponCode}`
      )
      .then((res) => {
        if (res.data?.statusCode == 200) {
          console.log(res);
          setSuccessMsg("Coupon Applied!");
          setCoupon(res.data?.coupon);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          setErrorMsg(error?.response?.data?.message);
        }
      });
  }, [couponCode]);

  //   useEffect(() => {
  //     const currentMonth = date;
  //     handleGetSheduleInfo(currentMonth);
  //   }, [date]);

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1200}
      centered
    >
      <div className="row">
        <div className="col-md-8">
          <div className="d-flex align-items-center mb-4 mx-auto justify-content-center">
            <MdKeyboardArrowLeft
              className="arrow-button"
              onClick={() => {
                var currentDate = new Date(date);

                // Get the next month by adding 1 to the current month
                currentDate.setMonth(currentDate.getMonth() - 1);
                setDate(currentDate);
              }}
            />
            <div className="mx-4 mt-4">
              <h4 className="">{moment(date).format("DD MMM YYYY")}</h4>
            </div>
            <MdKeyboardArrowRight
              className="arrow-button"
              onClick={() => {
                var currentDate = new Date(date);

                // Get the next month by adding 1 to the current month
                currentDate.setMonth(currentDate.getMonth() + 1);
                setDate(currentDate);
              }}
            />
          </div>
          {renderDayCells()}
        </div>
        <div className="col-md-4">
          <div
            style={{ height: "200px", position: "relative" }}
            className="mb-3"
          >
            <img
              src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${details?.image}`}
              alt=""
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "10px" }}
            />
            {/*<div
              style={{
                position: "absolute",
                bottom: 7,
                left: 0,
                background: "#C6A24F",
                color: "white",
                padding: "3px 15px",
                fontWeight: "600",
                borderRadius: "0 5px 5px 0",
              }}
            >
              USE COUPON: rashed
            </div>*/}
          </div>
          <p>{details?.description}</p>
          {details?.amountType == "FIXED" ? (
            <h6>
              Price:{" "}
              {coupon && Object.keys(coupon).length > 0 ? (
                <span>
                  £
                  {getDiscountValue(
                    details?.amount,
                    details?.amountType,
                    coupon?.amount
                  )}
                  <span
                    className="mx-3 text-danger"
                    style={{ fontSize: "13px" }}
                  >
                    <del>£{details?.amount}</del>
                  </span>
                </span>
              ) : (
                `£${details?.amount}`
              )}{" "}
            </h6>
          ) : (
            <h6>
              Price: <span className="text-danger">NEGOTIABLE</span>
            </h6>
          )}
          {details && Object.keys(details).length > 0 && (
            <h6>
              Time: <span className="">{details?.time}</span>
            </h6>
          )}

          {allSchedule && allSchedule.length > 0 && (
            <h6>Available Seat: {details?.availableSeat}</h6>
          )}

          {coupon && Object.keys(coupon).length > 0 && (
            <Fragment>
              <p className="text-danger">
                Discount:{" "}
                {coupon?.amountType == "percent"
                  ? `${coupon?.amount}%`
                  : `£${coupon?.amount}`}{" "}
              </p>
            </Fragment>
          )}

          {details?.amountType == "FIXED" && details?.amount > 0 && (
            <div className="my-4">
              <label>Apply Coupon</label>
              <Space.Compact
                style={{
                  width: "100%",
                }}
              >
                <Input
                  placeholder="COUPON CODE"
                  value={couponCode}
                  onChange={(e) => {
                    setErrorMsg("");
                    setSuccessMsg("");
                    setCoupon();
                    setCouponCode(e.target.value);
                  }}
                />
                <Button
                  type="default"
                  onClick={availCoupon}
                  disabled={!couponCode || couponCode == ""}
                >
                  Apply
                </Button>
              </Space.Compact>
              <small className="text-success">{successMsg}</small>
              <small className="text-danger">{errorMsg}</small>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CourseDetailsModal;
