import React from "react";
import DynamicCarousel from "./DynamicCarousel/DynamicCarousel";
export default function ClientTestimonials({ testimonials }) {
  // console.log("testimonials:", testimonials);
  const testimonialItems = testimonials.list.map((testimonial, index) => (
    <div key={index} className="row">
      <div className="col-md-4">
        {" "}
        <img
          src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${testimonial.url}`}
          alt={testimonial.title}
          style={{
            width: "100%",
            height: "450px",
            borderRadius: "10px",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        className="col-md-8"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px 50px",
        }}
      >
        <p
          style={{
            color: "#C6A34F",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          {testimonial.title}
        </p>
        <div className="quote-container">
          <div className="quote">
            <p style={{ fontSize: "20px" }}>
              <span
                className="quotation-mark"
                style={{
                  color: "#C6A34F",
                  fontSize: "28px",
                  marginRight: "5px",
                }}
              >
                “
              </span>
              {testimonial.subTitle}
              <span
                className="quotation-mark"
                style={{
                  color: "#C6A34F",
                  fontSize: "28px",
                  marginLeft: "5px",
                }}
              >
                ”
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, rgba(247, 241, 229, 0.6) 0%, rgba(247, 241, 229, 0) 100%)",
        padding: "50px 0px",
      }}
    >
      <div className="container mx-auto  mb-5">
        <div className="testimonialTitle text-center">
          <h3 class="text-uppercase">{testimonials.title}</h3>
          <p>{testimonials.subTitle}</p>
        </div>
        <div className="mt-5">
          {testimonialItems.length > 1 ? (
            <DynamicCarousel
              items={testimonialItems}
              slidesToShow={1}
              autoplaySpeed={4000}
            />
          ) : (
            <div className="d-flex">{testimonialItems}</div>
          )}
        </div>
      </div>
    </div>
  );
}
