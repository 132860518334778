import { Modal } from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FiPhoneOutgoing } from "react-icons/fi";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { Link, useNavigate, useParams } from "react-router-dom";
import defaultImage from "../../../assets/images/default.png";
import Loader from "../../../common/Loader";
import { token } from "../../../utils/authentication";
import { getUserCurrency } from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import MediaCarousel from "../../frontend/employee/profile/MediaCarousel";
import verifiedLogo from "./verified.png";
import { useTranslation } from "react-i18next";

const EmployeeProfile = () => {
  const {t} = useTranslation();
  const jwt_decode = jwtTokenDecode();
  const { id } = useParams();
  const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meetData, setMeetData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/meet/get-my-meets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        // console.log(res.data[0]);
        setMeetData(res.data[0]);
        setLoading(false);
      });
  }, []);

  console.log(getSingleEmployeeDetails);

  //Fetch refer person list for dropdown
  const fetchSingleEmployeeData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSingleEmployeeDetails(res?.data?.details);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSingleEmployeeData();
  }, [id]);

  let profileCompletedPercentage = 0;
  if (getSingleEmployeeDetails?.firstName && getSingleEmployeeDetails?.lastName)
    profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.positionId) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.gender) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.presentAddress) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.emmergencyContact)
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.profilePicture)
    profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.bankName) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.cv) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.countryName) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.higherEducation)
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.dateOfBirth) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.phoneNumber) profileCompletedPercentage += 5;
  if (_.size(getSingleEmployeeDetails?.skills)) profileCompletedPercentage += 5;
  if (_.size(getSingleEmployeeDetails?.languages))
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.employeeExperience)
    profileCompletedPercentage += 5;

  const expireTime = new Date(meetData?.expiredTime).getTime();
  const currentTime = new Date().getTime();
  const isExpired = currentTime > expireTime;
  // console.log("isExpired: ", isExpired);
  if (loading) {
    return (
      <h1>
        <Loader />
      </h1>
    );
  }

  return (
    <div className="container px-4 mt-5">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px",
          height: "50px",
          color: "#C6A34F",
        }}
        onClick={goBack}
      >
        <IoChevronBackCircleOutline
          size={25}
          backgroundColor="#A6A6A6"
          color="#C6A34F"
        />
        <span style={{ marginLeft: "10px" }}>{t("go_back")}</span>
      </div>
      <section className="">
        {/* <div className="row">
          <div
            className="col-md-3 card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "left",
              borderRadius: "14.8px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              backgroundColor: " #F6F1E5",
            }}
          >
            <p>HI {getSingleEmployeeDetails?.name},</p>
            <p>Explore the features of MH App here</p>
          </div>
          <div
            className="col-md-9"
            style={{
              marginLeft: "",
            }}
          >
            <div
              className="row row-cols-1 row-cols-md-2 row-cols-lg-3 p-3"
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "14.8px",
                marginLeft: "10px",
              }}
            >
              <div className="col-lg-2">
                <Link to={`/employee-dashboard`} className="customCardLink">
                  <div className="customCard">
                    <img src={dashboard} alt="dashboard" />
                    <p>Dashboard</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link
                  to={`/employee-booked-history`}
                  className="customCardLink"
                >
                  <div className="customCard">
                    <img src={booked} alt="booked" />
                    <p>Booked History</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link to={`/employee-hired-history`} className="customCardLink">
                  <div className="customCard">
                    <img src={hired} alt="" />
                    <p>Hired History</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link to={`/my-calender`} className="customCardLink">
                  <div className="customCard">
                    <img src={calendar} alt="calendar" />
                    <p>Calendar</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link
                  to={`/employee-payment-history`}
                  className="customCardLink"
                >
                  <div className="customCard">
                    <img src={payment} alt="payment" />
                    <p>Payment History</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <div onClick={showModal} className="customCardLink">
                  <div className="customCard">
                    <img src={help} alt="help" />
                    <p>Help & Support</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {loading ? (
          <div className="card-body">
            <Loader /> <br />
          </div>
        ) : (
          <div className="row">
            <div className="col-xl-4 col-md-5">
              <div className="row">
                <div
                  className="selectedEmpProfilePic"
                  style={{
                    height: "400px",
                    width: "100%",
                  }}
                >
                  <img
                    style={{
                      borderRadius: 14,
                      backgroundSize: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    src={
                      getSingleEmployeeDetails?.profilePicture
                        ? process.env.REACT_APP_ASSETs_BASE_URL +
                          "/" +
                          getSingleEmployeeDetails?.profilePicture
                        : defaultImage
                    }
                    className="img-fluid"
                    alt="custom-image1"
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="profileMarker">
                  <img
                    src="/assets/frontend/images/selectedEmployee/profileMarkLogo.png"
                    className="img-fluid"
                    alt="custom-image"
                  />
                </div>
              </div> */}
              <div className="row">
                <div
                  className="selectProfileRowForMargin"
                  style={{ width: "auto", borderRadius: 14 }}
                >
                  <div className="card selectEmpCardCard_body card-body employee-border">
                    <div className="sctEmplTitleBox">
                      <h5 className="card-title slctProfileNameTitle">
                        {getSingleEmployeeDetails?.name}{" "}
                        {getSingleEmployeeDetails?.certified && (
                          <img
                            src={verifiedLogo}
                            alt="verified"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </h5>

                      <div className="slctProfiletotalRating">
                        <img
                          src="/assets/frontend/images/selectedEmployee/Star 1.png "
                          className=""
                          alt="alt-rating"
                        />{" "}
                        &nbsp;
                        {getSingleEmployeeDetails?.rating}
                      </div>
                      <div className="col-xl-12 col-md-12">
                        <div className="selectEmployeeTotalHours">
                          <img
                            src="/assets/frontend/images/selectedEmployee/clock.png"
                            alt="alt-hours"
                          />
                          &nbsp; {t("total_hour")}:{" "}
                          <small className="selectEmployeeTotalHoursValue">
                            {getSingleEmployeeDetails?.totalWorkingHour} H
                          </small>
                        </div>
                      </div>
                      <div className="slctproLine">
                        <img
                          src="/assets/frontend/images/selectedEmployee/Line 1.png"
                          className="img-fluid"
                          alt="alt-line"
                        />
                      </div>
                    </div>
                    <div className="row slctEmpProfileRateRow">
                      <div className="col-xl-12">
                        <div className="sctProfileAge">
                          <img
                            // src={calendar}
                            style={{ height: "18px" }}
                            alt="calender"
                          />
                          &nbsp;{t("date_of_birth")}: &nbsp;
                          <span className="slctprofilePerHourPrice">
                            {moment(getSingleEmployeeDetails?.dateOfBirth)
                              .format("YYYY-MM-DD")
                              .valueOf()}
                          </span>
                        </div>
                        <span className="slctProfileRateName">
                          <img
                            className="img-fluid SlctProrateIcon"
                            src="/assets/frontend/images/selectedEmployee/rate.png"
                            alt="alt-rate"
                          />
                          {t("rate")}:
                        </span>
                        <span className="slctprofilePerHourPrice">
                          {getSingleEmployeeDetails?.contractorHourlyRate?.toFixed(
                            2
                          )}
                          {getUserCurrency()}{t("per_hour")}
                        </span>
                      </div>
                      <div className="">
                        <span className="slctProfileExpName">
                          <img
                            src="/assets/frontend/images/selectedEmployee/experience.png"
                            alt="alt-exp"
                          />
                          &nbsp; {t("experience")}:
                        </span>
                        <span className="slctProfileExpValue">
                          {getSingleEmployeeDetails?.employeeExperience}
                        </span>
                      </div>
                      <div className="">
                        <span className="slctProfileExpName">
                          <img
                            // src={license}
                            style={{ height: "20px" }}
                            alt=""
                          />
                          &nbsp;{
                            t("license_no")
                          }:
                        </span>
                        <span className="slctProfileExpValue">
                          {getSingleEmployeeDetails?.licensesNo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7">
              {getSingleEmployeeDetails?.vlogs && (
                <div
                  style={{
                    // border: "1px solid #E5E5E5",
                    borderRadius: "14px",
                    marginBottom: "20px",
                  }}
                >
                  <MediaCarousel mediaData={getSingleEmployeeDetails?.vlogs} />
                </div>
              )}

              <div className="selectEmpLocationBar employee-border">
                <p>{t("education")}</p>
                <hr />
                <div className="row">
                  <span>
                    <img
                      src="/assets/frontend/images/selectedEmployee/education (2).png"
                      alt="custom-image1"
                    />
                    &nbsp;{getSingleEmployeeDetails?.higherEducation}
                  </span>
                </div>
              </div>
              <div className="selectEmpLocationBar employee-border">
                <p>{t("certificate")}</p>
                <hr />
                {getSingleEmployeeDetails?.certificates?.map((item, index) => (
                  <div className="row" key={index}>
                    <span>
                      <img
                        src="/assets/frontend/images/selectedEmployee/certificateICon.png"
                        alt="custom-image1"
                      />
                      {/* &nbsp;{" "} */}
                      <Link
                        to={`${process.env.REACT_APP_ASSETs_BASE_URL}/${item?.attachment}`}
                        className="text-decoration-none text-muted "
                        target="_blank"
                      >
                        {" "}
                        <span> {item?.certificateName} </span>
                      </Link>
                    </span>
                  </div>
                ))}
              </div>
              <div className="selectEmpLocationBar employee-border">
                <p>{t("language")}</p>
                <hr />
                {getSingleEmployeeDetails?.languages?.map((item, index) => (
                  <div className="row" key={index}>
                    <span>
                      <img
                        src="/assets/frontend/images/selectedEmployee/googleTranslate.png"
                        alt="custom-image1"
                      />
                      &nbsp;{item}
                    </span>
                  </div>
                ))}
              </div>
              <div className="selectEmpLocationBar employee-border">
                <p>{t("skills")} </p>
                <hr />
                <div className="row">
                  <div className="col">
                    {getSingleEmployeeDetails?.skills
                      ?.slice(0, 5)
                      .map((item, index) => (
                        <div key={index}>
                          <span>
                            <img
                              // src={skillImg}
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                              alt="custom-language"
                            />
                            &nbsp;{item.skillName}
                          </span>
                        </div>
                      ))}
                  </div>
                  <div className="col">
                    {getSingleEmployeeDetails?.skills
                      ?.slice(5)
                      .map((item, index) => (
                        <div key={index}>
                          <span>
                            <img
                              // src={skillImg}
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                              alt="custom-language"
                            />
                            &nbsp;{item.skillName}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Modal
        title="Help & Support"
        okButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelText="Close"
        cancelButtonProps={{
          style: { backgroundColor: "#c6a34f", color: "white" },
        }}
      >
        <div className="col-lg-12 mb-4">
          {/* <div className="row"></div> */}
          <div className="socialMediaIcons">
            <a
              target="_blank"
              href="https://www.facebook.com/RecruitmentMirkoHospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116168.png"
                alt="image1"
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/recruitmentmirkohospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116169.png"
                alt="image1"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/mirko-hospitality/mycompany/?viewAsMember=true"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116170.png"
                alt="image1"
              />
            </a>
            <a
              href="https://vm.tiktok.com/ZGJmndX98/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116171.png"
                alt="image1"
              />
            </a>
          </div>
          <div>
            <div className="d-flex mt-4">
              <a href="tel:+4407500146699">
                <FiPhoneOutgoing
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>t("reservation")</p>
                <h5 style={{ fontSize: "20px", color: "#8e6d45" }}>
                  +44 75 001 46699
                </h5>
              </div>
            </div>
            <div className="d-flex mt-4">
              <a href="mailto:info@mhpremierstaffingsolutions.com">
                <TfiEmail
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>Email Info</p>
                <h5 style={{ fontSize: "15px", color: "#8e6d45" }}>
                  info@mhpremierstaffingsolutions.com
                </h5>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeProfile;
