import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtTokenDecode } from "../../utils/jwtDecode";
import ReactFlagsSelect from "react-flags-select";
import { changeLanguageFunc } from "../../utils/commonFunctions";

function Navbar() {
  const jwt_decode = jwtTokenDecode();

  const navigate = useNavigate();
  const [selected, setSelected] = useState(
    localStorage.getItem("countryCode") || "GB"
  );

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginData");

    navigate("/");

    window.location.reload();
  };

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      {/* <!-- Navbar Brand--> */}
      <Link className="navbar-brand ps-3" to="/admin/dashboard">
        MH ADMIN
        {/* <img src="assets/frontend/images/login_page_images/Logo.png" alt="logo" /> */}
      </Link>
      {/* <!-- Sidebar Toggle--> */}
      {/* <button
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        href="#!"
      >
        <i className="fas fa-bars"></i>
      </button> */}
      {/* <!-- Navbar Search--> */}
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group">
          {/* <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button> */}
        </div>
      </form>
      {/* <!-- Navbar--> */}
      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li style={{
          background: "white",
          marginRight: "15px",
        }}>
          <ReactFlagsSelect
            defaultCountry="GB"
            className="menu-flags"
            countries={["GB", "AE", "IT"]}
            selected={selected || "GB"}
            customLabels={{
              US: "English",
              GB: "English",
              AE: "Arabic",
              IT: "Italian",
            }}
            onSelect={(e) => {
              setSelected(e);
              changeLanguageFunc(e);
            }}
          />
        </li>

      </ul>
    </nav>
  );
}

export default Navbar;
