import React, { useState, useEffect } from "react";
import { Table, Button, message, Modal, Select } from "antd";
import axios from "axios";
import { token } from "../../../utils/authentication";
import GoBack from "../../../utils/goBack";

const { Option } = Select;

const PermissionList = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("United Kingdom");
  const [roleOptions, setRoleOptions] = useState([]);
  const [controllerOptions, setControllerOptions] = useState([]);

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedController, setSelectedController] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    fetchRoles(selectedCountry);
  }, [selectedCountry]);
  useEffect(() => {
    fetchControllers(selectedRole);
  }, [selectedRole]);

  const fetchRoles = (country) => {
    // setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/roles/list-for-dropdown?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setRoleOptions(response.data.roles);
          //   setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };

  const fetchControllers = (roleId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/service/controller-dropdown`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setControllerOptions(response.data.services);
        }
      })
      .catch((error) => {
        console.error("Error fetching controllers:", error);
      });
  };
  useEffect(() => {
    loadPermissions();
  }, [pagination.current, selectedRole, selectedController]);

  const loadPermissions = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/permission`, {
        params: {
          role: selectedRole,
          controller: selectedController,
          page: pagination.current,
          limit: pagination.pageSize,
        },
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status === "success") {
          setPermissions(response.data.permissions);
          setPagination({
            ...pagination,
            total: response.data.total,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching permissions:", error);
        message.error("Error fetching permissions");
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleDelete = (permissionId) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this permission?",
      onOk: () => {
        axios
          .delete(
            `${process.env.REACT_APP_API_BASE_URL}/permission/delete/${permissionId}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status === "success") {
              message.success("Permission deleted successfully");
              loadPermissions();
            }
          })
          .catch((error) => {
            console.error("Error deleting permission:", error);
            message.error("Error deleting permission");
          });
      },
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Role",
      dataIndex: ["role", "name"],
      key: "role",
    },
    {
      title: "Controller",
      dataIndex: "controller",
      key: "controller",
    },
    {
      title: "Service",
      dataIndex: ["service", "method"],
      key: "service",
    },
    {
      title: "Created By",
      dataIndex: ["createdBy", "name"],
      key: "createdBy",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button type="link" danger onClick={() => handleDelete(record._id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div>
        <GoBack/>
      <h1>Permission List</h1>
      <div style={{ marginBottom: 16, display: "flex", alignItems: "center" }}>
        <label htmlFor="country-select" style={{ marginRight: 8 }}>
          Select Country:
        </label>
        <Select
          placeholder="Select Country"
          style={{ width: 200, marginRight: 16 }}
          defaultValue={selectedCountry}
          onChange={(value) => setSelectedCountry(value)}
        >
          <Option value="United Kingdom">United Kingdom</Option>
          <Option value="United Arab Emirates">United Arab Emirates</Option>
          <Option value="Italy">Italy</Option>
        </Select>

        <label htmlFor="role-select" style={{ marginRight: 8 }}>
          Select Role:
        </label>
        <Select
          id="role-select"
          placeholder="Select Role"
          style={{ width: 200, marginRight: 16 }}
          value={selectedRole}
          allowClear
          onChange={(value) => setSelectedRole(value)}
        >
          {roleOptions.map((role) => (
            <Option key={role._id} value={role._id}>
              {role.name}
            </Option>
          ))}
        </Select>

        <label htmlFor="controller-select" style={{ marginRight: 8 }}>
          Select Controller:
        </label>
        <Select
          id="controller-select"
          placeholder="Select Controller"
          style={{ width: 200 }}
          value={selectedController}
          allowClear
          onChange={(value) => setSelectedController(value)}
        >
          {controllerOptions.map((controller) => (
            <Option key={controller._id} value={controller.controller}>
              {controller.controller}
            </Option>
          ))}
        </Select>
      </div>

      <Table
        columns={columns}
        dataSource={permissions}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="_id"
      />
    </div>
  );
};

export default PermissionList;
