import { Button, Form, Input, Select, Switch } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import GoBack from "../../../utils/goBack";
import { useParams } from "react-router-dom";
const { Option } = Select;

const EditTermCondition = () => {
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      description: "",
      country: "United Kingdom",
      active: true,
    },
    validate: (values) => {
      const errors = {};

      if (!values.description) {
        errors.description = "Description is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const token = localStorage.getItem("accessToken");

        if (!token) {
          console.error("Access token not available");
          return;
        }

        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/terms-conditions/update/`,
          {id,...values},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("API Response:", response.data);
        toast.success("Term and condition updated successfully");
        window.location.href = "/admin/terms-table";

      } catch (error) {
        console.error("API Error:", error);
        toast.error("Failed to update term and condition");
      }
    },
  });

  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/terms-conditions/${id}`
        );
        const termData = response.data.details;
        formik.setValues({
          description: termData.description || "",
          country: termData.country || "United Kingdom",
          active: termData.active || true,
        });
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Failed to fetch term data");
      }
    };

    fetchTermData();
  }, []);

  return (
    <>
      <GoBack />
      <div
        style={{
          width: "50%",
          margin: "auto",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <Form onFinish={formik.handleSubmit} layout="vertical">
          <Form.Item
            label="Description"
            required
            validateStatus={formik.errors.description ? "error" : ""}
            help={formik.errors.description}
          >
            <Input.TextArea
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>

          <Form.Item label="Country" required>
            <Select
              name="country"
              value={formik.values.country}
              onChange={(value) => formik.setFieldValue("country", value)}
            >
              <Option value="Italy">Italy</Option>
              <Option value="United Kingdom">United Kingdom</Option>
              <Option value="Dubai">Dubai</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Active" valuePropName="checked">
            <Switch
              name="active"
              checked={formik.values.active}
              onChange={(value) => formik.setFieldValue("active", value)}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default EditTermCondition;
