import React, { useRef, useState } from "react";
import PlayButton from "../PlayButton.svg";

const CustomVideoPlayer = ({ videoLink }) => {
  const videoRef = useRef(null);
  const [showIcon, setShowIcon] = useState(true);

  const handlePlay = () => {
    videoRef.current.play().catch((error) => console.error("Error playing video:", error));
    setShowIcon(false);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        borderRadius: "15px",
        overflow: "hidden",
      }}
    >
      <video
        ref={videoRef}
        src={videoLink}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "15px",
          objectFit: "cover",
        }}
      ></video>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)", // Adjust the transparency here (0 is fully transparent, 1 is fully opaque)
          display: showIcon ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "15px",
        }}
        onClick={handlePlay}
      >
        <img
          src={PlayButton}
          alt="Play"
          style={{ width: "64px", height: "64px" }}
        />
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
