import React from "react";
import heroDot from "./HeroDot.svg";
import heroRectangle from "./HeroRectangle.svg";
import CustomVideoPlayer from "./VideoPlayer/VideoPlayer";

export default function HeroSection({
  county,
  title,
  subTitle,
  url,
  contentType,
}) {
  console.log(url);
  const renderContent = () => {
    if (contentType === "image") {
      return (
        <img
          src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${url}`}
          alt="Hero Section"
          className="img-fluid rounded"
        />
      );
    } else if (contentType === "video") {
      return <CustomVideoPlayer videoLink={`${process.env.REACT_APP_ASSETs_BASE_URL}/${url}`} />;
    }

    // Handle other content types if needed
    return null;
  };

  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-md-12">
          <div
            className="upper_image-section"
            style={{
              position: "relative",
              overflow: "hidden",
              height: "100px",
            }}
          >
            <img
              src={heroRectangle}
              style={{
                position: "absolute",
                top: "-70px",
                left: "0",
                height: "200px",
              }}
              alt="heroRectangle"
            />
            <img
              src={heroDot}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                height: "100px",
              }}
              alt="heroRectangle"
            />
          </div>
          <div
            className="text"
            style={{ width: "100%", margin: "auto", height: "450px" }}
          >
            <h3>{title}</h3>
            <p>{subTitle}</p>
          </div>
          <div className="second-div" style={{ backgroundColor: "#FFFFFF" }}>
            <div
              className="upper_image-section"
              style={{
                position: "relative",
                overflow: "hidden",
                height: "100px",
              }}
            >
              <img
                src={heroDot}
                style={{ position: "absolute", left: "0", height: "100px" }}
                alt="heroRectangle"
              />
              <img
                src={heroRectangle}
                style={{
                  position: "absolute",
                  right: "0",
                  top: "-70px",
                  height: "200px",
                }}
                alt="heroRectangle"
              />
            </div>
            <div
              className="text"
              style={{ maxWidth: "900px", margin: "auto", marginTop: "-400px" }}
            >
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
