import { Checkbox, Col, Radio, Row, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { token } from "../../../utils/authentication";
import { fetchPositionListForDropdownHandler } from "../../../api/position";
import { paramsToQueryString } from "../../../utils/commonFunctions";
import BookForClientCalender from "../../frontend/calender/BookForClient";
import { useTranslation } from "react-i18next";

const BookForClient = () => {
  const {t} = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState({
    label: "United Kingdom",
    value: "United Kingdom",
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [employeeName, setEmployeeName] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState([]);
  const [filterPosition, setFilterPosition] = useState(undefined);
  const [positions, setPositions] = useState([]);
  const [status, setStatus] = useState("Direct Booking");
  // client list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users?requestType=CLIENT&skipLimit=YES
      `,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setClients(
          res.data.users.map((item) => ({
            ...item,
            label: item.restaurantName,
            value: item._id,
          }))
        );
      });
  }, []);
  // position list
  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPositions(res?.data?.positions);
    });
  }, []);
  useEffect(() => {
    fetchPositionData();
  }, [fetchPositionData]);
  // employee list
  useEffect(() => {
    const params = {
      active: "YES",
      requestType: "EMPLOYEE",
      skipLimit: "YES",
      positionId: filterPosition !== "all" ? filterPosition : undefined,
    };
    const queryString = paramsToQueryString(params);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        setTotalEmployee(
          res.data.users.map((item) => ({
            ...item,
            label: item.name,
            value: item._id,
          }))
        );
      });
  }, [filterPosition]);
  return (
    <div className="container-fluid px-4">
      <div className="row mt-3">
        <div className="d-flex justify-content-between">
          <h4 className="mb-2 title">{t("book_for_client")}</h4>
        </div>
      </div>
      {/* table here */}
      <div className="card">
        <div className="card-header">
          <Row gutter={[8, 8]}>
            {/* <Col sm={24} md={12} lg={4}>
              <Select
                size="large"
                allowClear
                showSearch={true}
                style={{ width: "100%" }}
                placeholder="Select Country Name"
                value={selectedCountry}
                onChange={(value) => {
                  setSelectedCountry(value);
                }}
              >
                <Select value="United Kingdom">United Kingdom</Select>
                <Select value="United Arab Emirates">
                  United Arab Emirates
                </Select>
              </Select>
            </Col> */}
            <Col sm={24} md={12} lg={4}>
              <Select
                showSearch={true}
                placeholder={t("restaurant")}
                optionFilterProp="children"
                size="large"
                allowClear
                style={{ width: "100%" }}
                value={selectedClient}
                onChange={(value, option) => {
                  setSelectedClient(option?.value);
                  setEmployeeName(undefined);
                  setFilterPosition(undefined);
                }}
              >
                {clients?.map((item, index) => (
                  <Select key={index} value={item?._id}>
                    {item?.label}
                  </Select>
                ))}
              </Select>
            </Col>
            <Col sm={24} md={12} lg={4}>
              <Select
                size="large"
                style={{
                  width: "100%",
                }}
                allowClear={false}
                optionFilterProp="children"
                value={filterPosition}
                showSearch={true}
                placeholder={t("select_position")}
                onChange={(value) => {
                  setFilterPosition(value);
                  setEmployeeName(undefined);
                }}
                defaultValue={"all"}
              >
                <Select value={"all"}>All Positions</Select>

                {positions?.map((item, index) => (
                  <Select key={index} value={item?._id}>
                    {item?.name}
                  </Select>
                ))}
              </Select>
            </Col>
            <Col sm={24} md={12} lg={4}>
              <Select
                showSearch={true}
                placeholder={t("employee")}
                optionFilterProp="children"
                size="large"
                allowClear
                style={{ width: "100%" }}
                value={employeeName}
                onChange={(value, option) => {
                  setEmployeeName(option?.value);
                  console.log("****", option?.value);
                }}
              >
                {totalEmployee?.map((item, index) => (
                  <Select key={index} value={item?._id}>
                    {item?.label}
                  </Select>
                ))}
              </Select>
            </Col>
            {/* //checkbox here */}
            <Col sm={24} md={12} lg={8} className="mt-2">
              <Radio.Group
                onChange={(e) => {
                  console.log(e.target.value);
                  setStatus(e.target.value);
                }}
                value={status}
              >
                <Radio value={"Direct Booking"}>{t("direct_booking")}</Radio>
                <Radio value={"Booking Request"}>{t("booking_request")}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </div>
        {selectedClient && employeeName && (
          <BookForClientCalender
            selectedId={employeeName}
            selectedClient={selectedClient}
            resetForm={() => {
              setSelectedClient(null);
              setEmployeeName(null);
              setFilterPosition(null);
            }}
            allowOrDeny={status === "Direct Booking" ? "ALLOW" : "DENY"}
          />
        )}
      </div>
    </div>
  );
};

export default BookForClient;
