const language = [
  "Afrikaans",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cantonese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Lithuanian",
  "Malay",
  "Malayalam",
  "Panjabi",
  "Tamil",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovene",
  "Spanish",
  "Swedish",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
  "Sign language",
  "Algerian",
  "Aramaic",
  "Armenian",
  "Berber",
  "Burmese",
  "Bosnian",
  "Brazilian",
  "Bulgarian",
  "Cypriot",
  "Corsica",
  "Creole",
  "Scottish",
  "Egyptian",
  "Esperanto",
  "Estonian",
  "Finn",
  "Flemish",
  "Georgian",
  "Hawaiian",
  "Indonesian",
  "Inuit",
  "Irish",
  "Icelandic",
  "Latin",
  "Mandarin",
  "Nepalese",
  "Sanskrit",
  "Tagalog",
  "Tahitian",
  "Tibetan",
  "Gypsy",
  "Wu",
].map((language) => ({ value: language, label: language }));

const allSkills = [
  { value: "Manager", label: "Manager" },
  { value: "Barista", label: "Barista" },
  { value: "Chef", label: "Chef" },
  { value: "Chef_de_partie", label: "Chef De Partie" },
  { value: "Commis_chef", label: "Commis chef" },
  { value: "Commis_pizza_chef", label: "Commis pizza chef" },
  { value: "Commis_waiter", label: "Commis waiter" },
  { value: "Food_runner", label: "Food runner" },
  { value: "Kp", label: "Kp" },
  { value: "Pizza_chef", label: "Pizza chef" },
  { value: "Room_maid", label: "Room maid" },
  { value: "Runner", label: "Runner" },
  { value: "Security", label: "Security" },
  { value: "Toilet_assistant", label: "Toilet assistant" },
  { value: "Waiter", label: "Waiter" },
  { value: "Bartender", label: "Bartender" },
  { value: "Bar_back", label: "Bar back" },
  { value: "Mixologist", label: "Mixologist" },
];

export { language, allSkills };
