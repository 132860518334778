import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BiMap } from "react-icons/bi";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import Loader from "../../../common/Loader";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import CountryWiseValidationRules from "../../../utils/static/countryList";
import MapModal from "../../frontend/profile/MapModal";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function ClientDetails() {
  const {t} = useTranslation();
  const { id } = useParams();

  const [getSingleClientDetails, setSingleClientDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false); //for loader
  const [getError, setError] = useState();
  const [mapModal, setMapModal] = useState(false);
  const [myLocation, setMyLocation] = useState({});
  const [name, setName] = useState("");

  const [form] = Form.useForm();
  const [bankform] = Form.useForm();
  const [bankUpdateLoading, setBankUpdateLoading] = useState(false);

  //Fetch single client information
  const fetchSingleClientData = useCallback(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      form.setFieldsValue({
        clientDiscount: res?.data?.details.clientDiscount,
        vatNumber: res?.data?.details.vatNumber,
        companyRegisterNumber: res?.data?.details.companyRegisterNumber,
        countryName: res?.data?.details.countryName,
        restaurantAddress: res?.data?.details.restaurantAddress,
      });
      bankform.setFieldsValue({
        bankName: res?.data?.details.bankName,
        accountNumber: res?.data?.details.accountNumber,
        routingNumber: res?.data?.details.routingNumber,
        dressSize: res?.data?.details.dressSize,
        additionalOne: res?.data?.details.additionalOne,
        additionalTwo: res?.data?.details.additionalTwo,
        additionalThree: res?.data?.details.additionalThree,
        companyName: res?.data?.details.companyName,
      });
      setMyLocation({
        lat: parseFloat(res?.data?.details.lat),
        lng: parseFloat(res?.data?.details.long),
      });
      setName(res?.data?.details.restaurantAddress);
      setSingleClientDetails(res?.data?.details);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchSingleClientData();
  }, [id]);

  const onFinish = async (values) => {
    const receivedClientFields = {
      id: id,
      clientDiscount: values?.clientDiscount,
      vatNumber: values?.vatNumber,
      companyRegisterNumber: values?.companyRegisterNumber,
      countryName: values?.countryName,
    };
    try {
      setLoading(true);

      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/users/update-client-discount`,
        receivedClientFields,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (res?.data?.statusCode === 200) {
        setError(undefined);
        setLoading(false);
        responseNotification(
          "Client information updated successfully!",
          "success"
        );
      } else if (res?.data?.statusCode === 400) {
        setError(res?.data?.errors?.[0].msg);
        setLoading(false);
      } else if (res?.data?.statusCode === 500) {
        setError(res?.message);
        setLoading(false);
      }
    } catch (error) {
      setError(error?.response?.data?.errors?.[0].msg);
      setLoading(false);
    }
  };

  const onBankFinish = async (values) => {
    const receivedEmployeeFields = {
      id: id,
      bankName: values?.bankName,
      accountNumber: values?.accountNumber,
      routingNumber: values?.routingNumber,
      companyName: values?.companyName,
    };

    if (values?.dressSize) receivedEmployeeFields.dressSize = values?.dressSize;
    // if(values?.companyName) receivedEmployeeFields.companyName = values?.companyName
    if (values?.additionalOne)
      receivedEmployeeFields.additionalOne = values?.additionalOne;
    if (values?.additionalTwo)
      receivedEmployeeFields.additionalTwo = values?.additionalTwo;
    if (values?.additionalThree)
      receivedEmployeeFields.additionalThree = values?.additionalThree;

    try {
      if (id && receivedEmployeeFields) {
        setBankUpdateLoading(true);

        const res = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-bank-dress`,
          receivedEmployeeFields,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (res?.data?.statusCode === 200) {
          setError(undefined);
          setBankUpdateLoading(false);
          responseNotification("Client bank updated successfully!", "success");
        } else if (res?.data?.statusCode === 400) {
          setError(res?.data?.errors?.[0].msg);
          setBankUpdateLoading(false);
        } else if (res?.data?.statusCode === 500) {
          setError(res?.message);
          setBankUpdateLoading(false);
        }
      }
    } catch (error) {
      setError(error?.response?.data?.errors?.[0].msg);
      setBankUpdateLoading(false);
    }
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleUpdateProfile = async () => {
    const values = await form.getFieldsValue(true);
    const updateProfileReceivedFields = {
      id: id,
      restaurantName: getSingleClientDetails?.restaurantName,
      restaurantAddress: values?.restaurantAddress,
      email: getSingleClientDetails?.email,
      phoneNumber: getSingleClientDetails?.phoneNumber,
      lat: values?.lat || myLocation?.lat,
      long: values?.lng || myLocation?.lng,
      password: getSingleClientDetails?.plainPassword,
    };
    if (updateProfileReceivedFields) {
      setLoader(true);
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-client`,
          updateProfileReceivedFields,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        if (res.data) {
          responseNotification(res?.data?.message, "success");
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="row mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "10px",
              height: "50px",
              color: "#C6A34F",
            }}
            onClick={goBack}
          >
            <IoChevronBackCircleOutline
              size={25}
              backgroundColor="#A6A6A6"
              color="#C6A34F"
            />
            <span style={{ marginLeft: "10px" }}>{
              t("go_back")
            }</span>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <h3 className="mb-4 title">{t("client_information")}</h3>
        </div>
      </div>
      <div className="card">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>{t("restaurant_name")}</th>
              <td>
                <input
                  type="text"
                  placeholder={t("enter_resturant_name")}
                  value={getSingleClientDetails?.restaurantName}
                  onChange={(e) => {
                    setSingleClientDetails({
                      ...getSingleClientDetails,
                      restaurantName: e.target.value,
                    });
                  }}
                />
              </td>
              <th>{t("restaurant_address")}</th>
              <td className="d-flex justify-content-between">
                {form.getFieldValue("restaurantAddress")}
                <Button
                  type="default"
                  onClick={() => {
                    setMapModal(true);
                  }}
                >
                  <BiMap />
                </Button>
              </td>
              <th>{t("email")}</th>
              <td>{getSingleClientDetails?.email}</td>
            </tr>
            <tr>
              <th>{t("phone_number")}</th>
              <td>
                <input
                  type="text"
                  placeholder={t("enter_phone_number")}
                  value={getSingleClientDetails?.phoneNumber}
                  onChange={(e) => {
                    setSingleClientDetails({
                      ...getSingleClientDetails,
                      phoneNumber: e.target.value,
                    });
                  }}
                />
              </td>
              <th>{t("client_id_number")}</th>
              <td>{getSingleClientDetails?.userIdNumber}</td>
              <th>{t("source_name")}</th>
              <td>{getSingleClientDetails?.sourceName}</td>
            </tr>
            <tr>
              <th>
                {t("password")}
              </th>
              <td>
                <input
                  type="text"
                  placeholder={t("enter_client_password")}
                  value={getSingleClientDetails?.plainPassword}
                  onChange={(e) => {
                    setSingleClientDetails({
                      ...getSingleClientDetails,
                      plainPassword: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
          </thead>
        </table>
        <div className="col-md-6">
          <Form.Item>
            <button
              disabled={loader}
              className="btn"
              style={{
                background: "#C6A34F",
                color: "white",
                marginLeft: "8px",
              }}
              onClick={handleUpdateProfile}
            >
              {!loader && t("update")}
              {loader && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {t("please_wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </Form.Item>
        </div>
      </div>

      <br />
      <div className="card">
        <div className="card-header">{t("update_client_information")}</div>
        <div className="card-body">
          <div>
            <Form
              className="ant-form ant-form-vertical"
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <div className="col-12">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label={t("discount_amount")}
                      name="clientDiscount"
                      // hasFeedback
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter discount",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder={t("enter_discount")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label={t("vat_number")}
                      name="vatNumber"
                      // hasFeedback
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter vat number",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder={t("enter_vat_number")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label={t("company_register_number")}
                      name="companyRegisterNumber"
                      // hasFeedback
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter company register number",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder={t("enter_company_register_number")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      label={t("country_name")}
                      name="countryName"
                      // hasFeedback
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter country name",
                      //   },
                      // ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_country_name")}
                        optionFilterProp="children"
                      >
                        {CountryWiseValidationRules?.map((item, index) => (
                          <Option key={index} value={item?.name}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              {getError ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="error-message">
                      <p className="text-danger">{getError}</p>
                    </div>
                  </div>
                </div>
              ) : undefined}

              <div className="col-md-6">
                <Form.Item>
                  <button
                    disabled={loading}
                    className="btn"
                    style={{ background: "#C6A34F", color: "white" }}
                    type="submit"
                  >
                    {!loading && t("submit")}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {t("please_wait...")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <br />
      <div className="card">
        <div className="card-header">{t("bank_information")}</div>
        <div className="card-body">
          {loading ? (
            <div className="card-body">
              <Loader /> <br />
              <br />
              <br />
            </div>
          ) : (
            <div>
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onBankFinish}
                form={bankform}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        label={t("bank_name")}
                        name="bankName"
                        // hasFeedback
                        initialValue={getSingleClientDetails?.name}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter bank name",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_bank_name")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("account_number")}
                        name="accountNumber"
                        // hasFeedback
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter account number",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_bank_account_number")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("sort_code")}
                        name="routingNumber"
                        // hasFeedback
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter sort code",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_sort_code")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                    <Form.Item
                        label={t("company_name")}
                        name="companyName"
                        // hasFeedback
                        // rules={[
                        //   {
                        //     message: "Please enter Company Name",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_company_name")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_one")}
                        name="additionalOne"
                        // hasFeedback
                        // rules={[
                        //   {
                        //     message: "Please enter Company Name",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_additional_one")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_two")}
                        name="additionalTwo"
                        // hasFeedback
                        // rules={[
                        //   {
                        //     message: "Please enter additional one",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_additional_two")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_three")}
                        name="additionalThree"
                        // hasFeedback
                        // rules={[
                        //   {
                        //     message: "Please enter additional two",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder={t("enter_additional_three")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="text-danger">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-md-6">
                  <Form.Item>
                    <button
                      disabled={bankUpdateLoading}
                      className="btn"
                      style={{ background: "#C6A34F", color: "white" }}
                      type="submit"
                    >
                      {!bankUpdateLoading && t("update_bank")}
                      {bankUpdateLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
      <MapModal
        isModalOpen={mapModal}
        setIsModalOpen={setMapModal}
        myLocation={myLocation}
        setMyLocation={setMyLocation}
        name={name}
        setName={setName}
        form={form}
      />
    </div>
  );
}

export default ClientDetails;
