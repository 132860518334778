import React from "react";
import noResult from "./no-result.png";

const NotFound = ({ title, description }) => (
  <div className="text-center">
    <h2>{title}</h2>
    <p>{description}</p>
    <img
      src={noResult}
      alt="404"
      style={{ maxWidth: "100%", maxHeight: "400px" }}
    />
  </div>
);

export default NotFound;
