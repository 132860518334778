
import './PreLoader.css';

const PreLoader = () => {
    return (
        <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default PreLoader;
