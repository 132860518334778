import { Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { token } from "../../../utils/authentication";
import GoBack from "../../../utils/goBack";

const { Column } = Table;
const { Option } = Select;

const MenuPermissionList = () => {
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [menus, setMenus] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingMenus, setLoadingMenus] = useState(false);

  useEffect(() => {
    // Fetch roles based on the selected country
    if (country) {
      fetchRoles(country);
    }
  }, [country]);

  useEffect(() => {
    // Fetch menus based on the selected role
    if (role) {
      fetchMenus(role);
    }
  }, [role]);

  const fetchRoles = (selectedCountry) => {
    setLoadingRoles(true);
    // Fetch roles based on the selected country
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/roles/list-for-dropdown?country=${selectedCountry}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setRoles(data.roles);
        } else {
          console.error("Failed to fetch roles:", data.message);
        }
        setLoadingRoles(false);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
        setLoadingRoles(false);
      });
  };

  const fetchMenus = (selectedRole) => {
    setLoadingMenus(true);
    // Fetch menus based on the selected role
    // Use the selectedRole value to fetch menus
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/menu-permission?role=${role}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setMenus(data.menuPermissions);
        } else {
          console.error("Failed to fetch menus:", data.message);
        }
        setLoadingMenus(false);
      })
      .catch((error) => {
        console.error("Error fetching menus:", error);
        setLoadingMenus(false);
      });
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    setRole(""); // Reset role when country changes
  };

  const handleRoleChange = (value) => {
    setRole(value);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Menu",
      dataIndex: "menu",
      key: "menu",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      // format timestamp
      render: (text, record) => {
        return new Date(record.createdAt).toLocaleString();
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text, record) => {
        return new Date(record.updatedAt).toLocaleString();
      },
    },
  ];
  return (
    <div>
      <GoBack />
      <h2>Menu Permissions</h2>
      <div style={{ marginBottom: 16, display: "flex", alignItems: "center" }}>
        <label htmlFor="country-select" style={{ marginRight: 8 , marginTop:"-10px"}}>
          Select Country:
        </label>
        <Select
          value={country}
          onChange={handleCountryChange}
          style={{ marginBottom: 16, width: "200px" }}
        >
          <Option value="Italy">Italy</Option>
          <Option value="United Kingdom">United Kingdom</Option>
          <Option value="United Arab Emirates">United Arab Emirates</Option>
        </Select>
        <label htmlFor="country-select" style={{ marginRight: 8 , marginTop:"-10px",marginLeft:"10px"}}>
          Select Role:
        </label>
        <Select
          value={role}
          onChange={handleRoleChange}
          style={{ marginBottom: 16, width: "200px" }}
        >
          {roles.map((role) => (
            <Option key={role._id} value={role._id}>
              {role.name}
            </Option>
          ))}
        </Select>
      </div>

      <Table
        dataSource={menus}
        columns={columns}
        loading={loadingMenus}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default MenuPermissionList;
