import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { token } from "../../../utils/authentication";
import IconSelectorModal from "./IconSelectorModal"; // Assuming you have the IconSelectorModal component

import toast from "react-hot-toast";
const { Option } = Select;
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  path: Yup.string().required("Path is required"),
});

export default function CreateMenu({ fetchMenuData }) {
  const [showIconSelectorModal, setShowIconSelectorModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    // Fetch menu list for dropdown
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menu/list-for-dropdown`, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setMenuList(data.menus);
        } else {
          console.error("Failed to fetch menu list:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching menu list:", error);
      });
  }, [fetchMenuData]);

  const handleIconSelect = (icon) => {
    setSelectedIcon(icon);
    setShowIconSelectorModal(false);
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const payload = {
      ...values,
      parentId: values.parentId,
      icon: selectedIcon,
    };
    // Post payload to create menu
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/menu/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Menu created successfully");
          fetchMenuData();
          resetForm();
          setSubmitting(true);
        } else {
          toast.error("Failed to create menu");
          setSubmitting(false);
          resetForm();
        }
      });
  };

  return (
    <div>
      <div className="">
        <Formik
          initialValues={{ name: "", path: "", parentId: "", icon: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, resetForm }) => (
            <Form onFinish={handleSubmit}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Name is required" }]}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label="Path"
                name="path"
                rules={[{ required: true, message: "Path is required" }]}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Input
                  name="path"
                  value={values.path}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label="Sort Order"
                name="sortOrder"
                rules={[{ required: true, message: "Sort Order is required" }]}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Input
                  name="sortOrder"
                  type="number"
                  value={values.sortOrder}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label="Parent Menu"
                name="parentId"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  name="parentId"
                  value={values.parentId}
                  onChange={(value) => handleChange("parentId")(value)}
                >
                  <Option value="0">No Parent</Option>
                  {menuList.map((menu) => (
                    <Option key={menu._id} value={menu._id}>
                      {menu.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Icon"
                name="icon"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Input
                  className="icon-input"
                  value={selectedIcon}
                  onClick={() => setShowIconSelectorModal(true)}
                />
                <IconSelectorModal
                  visible={showIconSelectorModal}
                  onCancel={() => setShowIconSelectorModal(false)}
                  onSelect={handleIconSelect}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24, offset: 6 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  // disabled={isSubmitting}
                >
                  Create Menu
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

function IconInput({ value, onClick }) {
  return (
    <div className="icon-input" onClick={onClick}>
      {value && <i className={`icon ${value}`} />}
      {<span className="placeholder">Select Icon</span>}
    </div>
  );
}
