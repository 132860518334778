import React, { useCallback, useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import AcademyCard from "./AcademyCard";
import path2 from "./images/path219.png";

import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";

const CourseCategoryDetails = () => {
  const [details, setDetails] = useState();
  const [courses, setCourses] = useState([]);
  const { id } = useParams();

  const getCategoryDetails = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/course-category/${id}`)
      .then((res) => {
        if (res.data?.statusCode == 200) {
          setDetails(res.data?.details?.courseCategoryDetails);
          setCourses(res.data?.details?.courses);
        }
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      getCategoryDetails();
    }
  }, [id]);

  return (
    <div
      className="card pb-5 pt-3 container my-5"
      style={{ borderRadius: "20px" }}
    >
      <div className="row align-items-center">
        <div className="col-md-5">
          <div style={{ height: "300px", position: "relative" }}>
            <Link
              to="/mh-academy"
              style={{
                textDecoration: "none",
                position: "absolute",
                top: "10px",
                left: "20px",
                background: "#fff",
                height: "30px",
                borderRadius: "3px",
              }}
            >
              <p className="">
                <HiOutlineArrowSmLeft
                  style={{ color: "#000", fontSize: "30px" }}
                />
              </p>
            </Link>
            <img
              src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${details?.image}`}
              alt=""
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "20px", objectFit: "cover" }}
            />
          </div>
        </div>
        <div className="col-md-7">
          <div>
            <div className="d-flex align-items-center">
              <img
                src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${details?.logo}`}
                alt="the"
                height={"36px"}
                width={"36px"}
              />
              <p
                className="mt-3 mx-3"
                style={{
                  fontSize: "25px",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {details?.name}
              </p>
            </div>
            <p
              style={{
                color: "#7B7B7B",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "31px",
              }}
            >
              {details?.description}
            </p>
          </div>
        </div>
      </div>

      <div className="row gy-5 mt-5">
        {courses &&
          courses.length > 0 &&
          courses.map((item, i) => (
            <div class="col-lg-4" key={i}>
              <AcademyCard
                comming={""}
                description={item?.description}
                course={item?.title}
                id={item?._id}
                price={
                  <div className="d-flex" style={{ marginLeft: "15%" }}>
                    <img src={path2} alt="the" height={15} className="mt-1" />
                    <p
                      style={{
                        color: "#7B7B7B",
                        font: "Montserrat",
                        fontWeight: 400,
                        fontSize: "15px",
                        marginLeft: "8%",
                        marginRight: "10%",
                      }}
                    >
                      Price:{" "}
                      {item?.amountType == "NEGOTIABLE" ? (
                        <strong>NEGOTIABLE</strong>
                      ) : (
                        <strong>£{item?.amount}</strong>
                      )}
                    </p>
                  </div>
                }
                // duration={
                //   <div className="d-flex" style={{ marginLeft: "15%" }}>
                //     <img src={path1} alt="the" height={15} className="mt-1" />
                //     <p
                //       style={{
                //         color: "#7B7B7B",
                //         font: "Montserrat",
                //         fontWeight: 400,
                //         fontSize: "15px",
                //         marginLeft: "10%",
                //         marginRight: "10%",
                //       }}
                //     >
                //       Duration: <span style={{
                //         fontWeight: 600,
                //       }}>30 HOURS </span>

                //     </p>
                //   </div>
                // }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseCategoryDetails;
