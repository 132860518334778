import { Button, message,Popconfirm } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiOutlineDrag,
} from "react-icons/ai";
import Nestable from "react-nestable";
import { token } from "../../../utils/authentication";
import GoBack from "../../../utils/goBack";
import CreateMenu from "./CreateMenu";
const styles = {
  position: "relative",
  background: "WhiteSmoke",
  display: "flex",
};
const cssCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const handlerStyles = {
  width: "2rem",
  height: "100%",
  cursor: "pointer",

  borderRight: "1px solid Gainsboro",
};

const ListMenus = () => {
  // State for holding menu items
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    fetchMenuData();
  }, []);

  const updateIdsRecursively = (items) => {
    return items.map((item) => {
      const newItem = { ...item, id: item._id };
      if (newItem.children && newItem.children.length > 0) {
        newItem.children = updateIdsRecursively(newItem.children);
      }
      return newItem;
    });
  };

  const updatedMenuItems = updateIdsRecursively(menuItems);
  // console.log(updatedMenuItems);

  useEffect(() => {}, []);
  const fetchMenuData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menu`, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setMenuItems(data.data);
        } else {
          console.error("Failed to fetch menu data:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  };

  const handleOnChange = (items) => {
    // Update sort order for each item
    const updatedItems = items.items.map((item, index) => {
      return {
        ...item,
        sortOrder: index,
      };
    });

    console.log("Updated menu items:", updatedItems);

    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/menu/update-sort`,
        { menu: updatedItems },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        // Handle response if needed
        console.log("Menu items updated successfully:", response.data);
        toast.success(response.data.message);
      })
      .catch((error) => {
        // Handle error if the request fails
        console.error("Error updating menu items:", error);
        toast.error("Failed to update menu items");
      });
  };

  const handleEdit = (record) => {
    // Implement logic to handle edit action
    console.log("Editing menu:", record);
  };

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/menu/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((response) => {
        message.success(response.data.message);
        fetchMenuData();
      })
      .catch((error) => {
        console.error("Error deleting menu item:", error);
        message.error("Failed to delete menu item");
      });
  };

  const renderItem = (props) => {
    const { item, index, collapseIcon, handler } = props;

    return (
      <div
        style={{ ...styles, fontWeight: item.children.length ? "700" : "400" }}
      >
        {handler}
        {collapseIcon}

        <div
          style={{
            padding: ".5rem",
            flex: 1,
            border: "1px solid #f0f0f0",
          }}
        >
          {item.name}
        </div>
        <div
          style={{
            padding: ".5rem",
            // width: "4rem",
          }}
        >
           {/* <Button type="link" onClick={() => handleEdit(item)}>Edit</Button> */}
          <Popconfirm
            title="Are you sure you want to delete this menu item?"
            onConfirm={() => handleDelete(item._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="btn btn-danger" danger>Delete</Button>
          </Popconfirm>
        </div>
      </div>
    );
  };
  const [collapseAll, setCollapseAll] = useState(true);
  const Handler = () => {
    return (
      <div style={{ ...cssCenter, ...handlerStyles }}>
        <AiOutlineDrag />
      </div>
    );
  };
  const Collapser = ({ isCollapsed }) => {
    return (
      <div style={{ ...cssCenter, ...handlerStyles }}>
        {isCollapsed ? <AiFillCaretRight /> : <AiFillCaretDown />}
      </div>
    );
  };
  return (
    <div>
      <GoBack />
      <h3>Menu List</h3>
      <div className="p-5">
        <Row>
          <Col md={5}>
            <CreateMenu fetchMenuData={fetchMenuData} />
          </Col>
          <Col
            md={7}
            style={{
              border: "1px solid #f0f0f0",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Button
              className="mb-2"
              onClick={() => setCollapseAll(!collapseAll)}
            >
              {collapseAll ? "expand all" : "collapse all"}
            </Button>
            <Nestable
              items={updatedMenuItems}
              renderItem={renderItem}
              handler={<Handler />}
              renderCollapseIcon={({ isCollapsed }) => (
                <Collapser isCollapsed={isCollapsed} />
              )}
              collapsed={collapseAll}
              onChange={(items) => handleOnChange(items)}
            />{" "}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ListMenus;
