// import React from "react";
// import { NavLink } from "react-router-dom";
// import { jwtTokenDecode } from "../../utils/jwtDecode";

// function Sidebar() {
//   const jwt_decode = jwtTokenDecode();

//   return (
//     <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
//       <div className="sb-sidenav-menu">
//         <div className="nav">
//           <div className="sb-sidenav-menu-heading">Dashboard</div>
//           <NavLink className="nav-link" to="/admin/dashboard">
//             <div className="sb-nav-link-icon">
//               <i className="fas fa-tachometer-alt" />
//             </div>
//             Dashboard
//           </NavLink>

//           {jwt_decode?.menuPermission?.position && (
//             <NavLink className="nav-link" to="/admin/position">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Position
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.skill && (
//             <NavLink className="nav-link" to="/admin/skill">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Skill
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.source && (
//             <NavLink className="nav-link" to="/admin/source">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Source
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.employeeList && (
//             <NavLink className="nav-link" to="/admin/employee-list">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Employee List
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.clientList && (
//             <NavLink className="nav-link" to="/admin/client-list">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Client List
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.clientList && (
//             <NavLink className="nav-link" to="/admin/create-meet">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Create Meet
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.clientList && (
//             <NavLink className="nav-link" to="/admin/get-meets">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               View Meets
//             </NavLink>
//           )}
//           {jwt_decode?.menuPermission?.clientList && (
//             <NavLink className="nav-link" to="/admin/employee-payment-history">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Employee Payment History
//             </NavLink>
//           )}
//           {jwt_decode?.menuPermission?.clientList && (
//             <NavLink className="nav-link" to="/admin/manage-review">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Manage Review
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.addMhEmployee &&
//             jwt_decode?.superAdmin && (
//               <NavLink className="nav-link" to="/admin/add-mh-employee">
//                 <div className="sb-nav-link-icon">
//                   <i className="fas fa-table" />
//                 </div>
//                 Add MH Employee
//               </NavLink>
//             )}
//           {jwt_decode?.menuPermission?.mhEmployeeList &&
//             jwt_decode?.superAdmin && (
//               <NavLink className="nav-link" to="/admin/mh-employee-list">
//                 <div className="sb-nav-link-icon">
//                   <i className="fas fa-table" />
//                 </div>
//                 MH Employee List
//               </NavLink>
//             )}

//           {jwt_decode?.menuPermission?.invoiceList && (
//             <NavLink className="nav-link" to="/admin/invoice-list">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Invoice List
//             </NavLink>
//           )}

//           {jwt_decode?.menuPermission?.notificationList && (
//             <NavLink className="nav-link" to="/admin/notification-list">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Notification List
//             </NavLink>
//           )}
//           {jwt_decode?.menuPermission?.contactList && (
//             <NavLink className="nav-link" to="/admin/contact-list">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Contact List
//             </NavLink>
//           )}

//           {/* COurse */}
//           {jwt_decode?.menuPermission?.contactList && (
//             <NavLink className="nav-link" to="/admin/course-category">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Course Category
//             </NavLink>
//           )}
//           {jwt_decode?.menuPermission?.contactList && (
//             <NavLink className="nav-link" to="/admin/course-list">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Course List
//             </NavLink>
//           )}
//           {jwt_decode?.menuPermission?.contactList && (
//             <NavLink className="nav-link" to="/admin/course-payment">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Course Payment
//             </NavLink>
//           )}
//           {jwt_decode?.menuPermission?.contactList && (
//             <NavLink className="nav-link" to="/admin/course-promotion">
//               <div className="sb-nav-link-icon">
//                 <i className="fas fa-table" />
//               </div>
//               Course Promotion
//             </NavLink>
//           )}
//         </div>
//       </div>
//       <div className="sb-sidenav-footer">
//         <div className="small">Logged in as:</div>
//         {jwt_decode?.name}
//       </div>
//     </nav>
//   );
// }

// export default Sidebar;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { jwtTokenDecode } from "../../utils/jwtDecode";

function Sidebar() {
  const jwt_decode = jwtTokenDecode();
  // console.log(jwt_decode);

  // Define state variables for submenu visibility
  const [courseSubMenuVisible, setCourseSubMenuVisible] = useState(false);
  const [aboutSubMenuVisible, setAboutSubMenuVisible] = useState(false);
  const [termsSubMenuVisible, setTermsSubMenuVisible] = useState(false);
  const [roleSubMenuVisible, setRoleSubMenuVisible] = useState(false);
  const [serviceSubMenuVisible, setServiceSubMenuVisible] = useState(false);
  const [permissionSubMenuVisible, setPermissionSubMenuVisible] =
    useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPermissionVisible, setMenuPermissionVisible] = useState(false);

  // Function to toggle the visibility of the Course submenu
  const toggleCourseSubMenu = () => {
    setCourseSubMenuVisible(!courseSubMenuVisible);
  };
  const toggleAboutSubMenu = () => {
    setAboutSubMenuVisible(!aboutSubMenuVisible);
  };
  const toggleTermsSubMenu = () => {
    setTermsSubMenuVisible(!termsSubMenuVisible);
  };

  const toggleRoleSubMenu = () => {
    setRoleSubMenuVisible(!roleSubMenuVisible);
  };
  const toggleServiceSubMenu = () => {
    setServiceSubMenuVisible(!serviceSubMenuVisible);
  };
  const togglePermissionSubMenu = () => {
    setPermissionSubMenuVisible(!permissionSubMenuVisible);
  };
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const toggleMenuPermission = () => {
    setMenuPermissionVisible(!menuPermissionVisible);
  };

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading">Dashboard</div>
          <NavLink className="nav-link" to="/admin/dashboard">
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt" />
            </div>
            Dashboard
          </NavLink>

          {jwt_decode?.role === "SUPER_ADMIN" && (
            <NavLink className="nav-link" to="/admin/position">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Position
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.skill && (
            <NavLink className="nav-link" to="/admin/skill">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Skill
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.source && (
            <NavLink className="nav-link" to="/admin/source">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Source
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.employeeList && (
            <NavLink className="nav-link" to="/admin/employee-portfolio">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Employee Portfolio
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.employeeList && (
            <NavLink className="nav-link" to="/admin/employee-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Employee List
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.employeeList && (
            <NavLink className="nav-link" to="/admin/others-employee-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Others Employee List
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.clientList && (
            <NavLink className="nav-link" to="/admin/client-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Client List
            </NavLink>
          )}
          {/* {jwt_decode?.menuPermission?.clientList && (
            <NavLink className="nav-link" to="/admin/create-meet">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Create Meet
            </NavLink>
          )} */}
          {/* {jwt_decode?.menuPermission?.clientList && (
            <NavLink className="nav-link" to="/admin/get-meets">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              View Meets
            </NavLink>
          )} */}
          {jwt_decode?.menuPermission?.employeePaymentHistory && (
            <NavLink className="nav-link" to="/admin/booking-history">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Booking History
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.employeePaymentHistory && (
            <NavLink className="nav-link" to="/admin/book-for-client">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Book For Client
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.employeePaymentHistory && (
            <NavLink className="nav-link" to="/admin/employee-payment-history">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Employee Payment History
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.clientList && (
            <NavLink className="nav-link" to="/admin/manage-review">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Manage Review
            </NavLink>
          )}

          {jwt_decode?.menuPermission?.addMhEmployee &&
            jwt_decode?.superAdmin && (
              <NavLink className="nav-link" to="/admin/add-mh-employee">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Add MH Employee
              </NavLink>
            )}
          {jwt_decode?.menuPermission?.mhEmployeeList &&
            jwt_decode?.superAdmin && (
              <NavLink className="nav-link" to="/admin/mh-employee-list">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                MH Employee List
              </NavLink>
            )}
          {jwt_decode?.menuPermission?.invoiceList && (
            <NavLink className="nav-link" to="/admin/invoice-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Invoice List
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.notificationList && (
            <NavLink className="nav-link" to="/admin/notification-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Notification List
            </NavLink>
          )}
          {jwt_decode?.menuPermission?.contactList && (
            <NavLink className="nav-link" to="/admin/contact-list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-table" />
              </div>
              Contact List
            </NavLink>
          )}
          {/* Render the Course submenu conditionally */}
          {(jwt_decode?.menuPermission?.mhAcademy ||
            jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleCourseSubMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Course Management
                {courseSubMenuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {courseSubMenuVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/course-category">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Course Category
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/course-list">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Course List
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/course-schedule">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Course Schedule
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/course-payment">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Course Payment
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/course-promotion">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Course Promotion
                  </NavLink>
                </div>
              )}
            </div>
          )}

          {(jwt_decode?.menuPermission?.mhAbout || jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleAboutSubMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                About Management
                {aboutSubMenuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {aboutSubMenuVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/create-about">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create About
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/about-table">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    About List
                  </NavLink>
                </div>
              )}
            </div>
          )}
          {(jwt_decode?.menuPermission?.mhAbout || jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleRoleSubMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Role Management
                {roleSubMenuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {roleSubMenuVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/create-role">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create Role
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/role-list">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Role List
                  </NavLink>
                </div>
              )}
            </div>
          )}

          {(jwt_decode?.menuPermission?.mhRole || jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleTermsSubMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Terms Management
                {termsSubMenuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {termsSubMenuVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/create-terms">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create Terms
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/terms-table">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Terms List
                  </NavLink>
                </div>
              )}
            </div>
          )}

          {(jwt_decode?.menuPermission?.mhService ||
            jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleServiceSubMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Service Management
                {serviceSubMenuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {serviceSubMenuVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/create-service">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create Service
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/service-list">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Service List
                  </NavLink>
                </div>
              )}
            </div>
          )}
          {(jwt_decode?.menuPermission?.mhService ||
            jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={togglePermissionSubMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Permission Management
                {permissionSubMenuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {permissionSubMenuVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/create-permission">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create Permission
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/permission-list">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Permission List
                  </NavLink>
                </div>
              )}
            </div>
          )}
          {(jwt_decode?.menuPermission?.mhService ||
            jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleMenu}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Menu Management
                {menuVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {menuVisible && (
                <div className="pl-3">
                  {/* <NavLink className="nav-link" to="/admin/create-menu">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create Menu
                  </NavLink> */}
                  <NavLink className="nav-link" to="/admin/menu-list">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Menu List
                  </NavLink>
                </div>
              )}
            </div>
          )}
          {(jwt_decode?.menuPermission?.mhService ||
            jwt_decode?.superAdmin) && (
            <div>
              <div className="nav-link" onClick={toggleMenuPermission}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-table" />
                </div>
                Menu Permission Management
                {menuPermissionVisible ? (
                  <i className="fas fa-chevron-up float-end"></i>
                ) : (
                  <i className="fas fa-chevron-down float-end"></i>
                )}
              </div>

              {/* Conditionally render subitems based on visibility */}
              {menuPermissionVisible && (
                <div className="pl-3">
                  <NavLink className="nav-link" to="/admin/add-menu-permission">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Create Menu Permission
                  </NavLink>
                  <NavLink className="nav-link" to="/admin/menu-permission-list">
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-table" />
                    </div>
                    Menu Permission List
                  </NavLink>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">Logged in as:</div>
        {jwt_decode?.name}
      </div>
    </nav>
  );
}

export default Sidebar;
