import { Button, DatePicker, Popconfirm } from "antd";
import Axios from "axios";
import { eachDayOfInterval, startOfMonth } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { token } from "../../../utils/authentication";
import "./course-schedule.scss";
import _ from "lodash";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const CourseSchedule = () => {
  const {t} = useTranslation();
  const [columns, setColumns] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [startMonth, setStartMonth] = useState();
  const [loading, setLoading] = useState(false);

  const generateUniqueID = () => {
    // Replace this with your own logic to generate unique IDs
    return Math.random().toString(36).substring(2, 9);
  };

  const onDragEnd = (result, columns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      let destItems = [...destColumn.items];

      if (destColumn.name == "courses") {
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        let uniqueObjArray = [
          ...new Map(
            destItems.map((item) => [item["courseId"], item])
          ).values(),
        ];
        destItems = uniqueObjArray;
      } else {
        if (destItems && destItems.length == 0) {
          const copiedItem = {
            ...sourceItems[source.index],
            id: generateUniqueID(),
          };
          destItems.splice(destination.index, 0, copiedItem);
        } else {
          const copiedItem = {
            ...sourceItems[source.index],
            id: generateUniqueID(),
          };
          destItems.splice(destination.index, 0, copiedItem);
          destItems = _.uniqBy(destItems, "courseId");
        }
      }

      const data = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(data);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const data = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      setColumns(data);
    }
  };

  const currentMonth = startMonth ? new Date(startMonth) : new Date();

  const renderWeekdayLabels = () => {
    const weekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return (
      <div className="weekday-labels">
        {weekdayLabels.map((label) => (
          <div key={label} className="weekday-label">
            {label}
          </div>
        ))}
      </div>
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    const allItems = [];

    for (const key in columns) {
      if (columns.hasOwnProperty(key)) {
        const dayObject = columns[key];
        if (dayObject.name != "courses" && dayObject.items.length > 0) {
          for (let j = 0; j < dayObject.items?.length; j++) {
            const schedules = {
              courseId: dayObject.items[j]?.courseId,
              courseTitle: dayObject.items[j]?.name,
              time: dayObject.items[j]?.time,
              date: dayObject?.name,
            };
            allItems.push(schedules);
          }
        }
      }
    }

    if (!allItems || allItems.length == 0) {
      setLoading(false);
      return toast.error("Change Schedule is Empty");
    }

    const payload = {
      month: startMonth,
      schedule: allItems,
    };

    await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/course/shedule-create`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    )
      .then((res) => {
        toast.success("Course Schedule Set Successfully");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("something went wrong");
      });

    setLoading(false);
  };

  const handleClearAll = async () => {
    setLoading(true);

    const allItems = [];

    for (const key in columns) {
      if (columns.hasOwnProperty(key)) {
        const dayObject = columns[key];
        if (dayObject.name != "courses" && dayObject.items.length > 0) {
          for (let j = 0; j < dayObject.items?.length; j++) {
            const schedules = {
              courseId: dayObject.items[j]?.courseId,
              courseTitle: dayObject.items[j]?.name,
              time: dayObject.items[j]?.time,
              date: dayObject?.name,
            };
            allItems.push(schedules);
          }
        }
      }
    }

    if (!allItems || allItems.length == 0) {
      setLoading(false);
      return toast.error("Calender is Empty");
    }

    const payload = {
      month: startMonth,
      schedule: [],
    };

    await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/course/shedule-create`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    )
      .then(async (res) => {
        toast.success("Clear All Successfully");
        await handleDate(startMonth);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("something went wrong");
      });

    setLoading(false);
  };

  const handleDate = async (date) => {
    setColumns([]);
    const startMonth = date;
    setStartMonth(date);
    const schedules = await getScheduleInfo(date);

    const formatedCourses = courseList?.map((item) => {
      return {
        id: generateUniqueID(),
        courseId: item?._id,
        name: item?.title,
        time: item?.time,
        colorCode: "#C6A24F",
      };
    });

    const currentMonth = startMonth ? new Date(startMonth) : new Date();

    const daysInMonth = eachDayOfInterval({
      start: startOfMonth(currentMonth),
      end: new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0),
    });
    const data = [];
    data.push({
      name: `courses`,
      items: formatedCourses,
    });
    for (let i = 0; i < daysInMonth.length; i++) {
      data.push({
        name: `${daysInMonth[i]}`,
        items: [],
      });
    }

    if (schedules && schedules.length > 0) {
      for (let i = 0; i < daysInMonth.length; i++) {
        for (let j = 0; j < schedules.length; j++) {
          if (
            moment(daysInMonth[i]).format("YYYY-MM-DD") ==
            moment(schedules[j].date).format("YYYY-MM-DD")
          ) {
            data[i + 1].items.push(schedules[j]);
          }
        }
      }
    }
    setColumns(data);
  };

  const renderDayCells = () => {
    const firstDayOfWeek = startOfMonth(currentMonth).getDay(); // Get the index of the first day of the month

    return (
      <div className="row">
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result, columns);
          }}
        >
          <div className="col-md-2">
            {Object.entries(columns)
              .filter(([columnId, column]) => column.name == "courses")
              .map(([columnId, column], index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "1px solid #000",
                      marginRight: `${column.name == "courses" ? "80px" : "0"}`,
                    }}
                    key={columnId}
                  >
                    <div
                      style={{
                        background: "#C6A24F",
                        width: "100%",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      <h2 className="text-white text-capitalize">
                        {column.name}
                      </h2>
                    </div>
                    <div style={{ width: "100%", background: "#FFF" }}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#FFF",
                                padding: 10,
                                width: "100%",
                                minHeight: 300,
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: "0 0 8px 0",
                                            minHeight: "50px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#263B4A"
                                              : item?.colorCode
                                              ? item?.colorCode
                                              : "#456C86",
                                            color: "white",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {item.name}
                                          <p
                                            className="p-0 m-0"
                                            style={{ fontSize: "10px" }}
                                          >
                                            {item?.time}
                                          </p>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="col-md-10">
            {renderWeekdayLabels()} {/* Render the weekday labels */}
            <div className="calendar-grid">
              {/* Empty cells for days before the first day of the month */}
              {Array.from({ length: firstDayOfWeek }, (_, index) => (
                <div key={`empty-${index}`} className="calendar-day empty" />
              ))}
              {/* Render day cells */}
              {Object.entries(columns)
                .filter(([columnId, column]) => column.name != "courses")
                .map(([columnId, column], index) => {
                  return (
                    <div key={columnId} className={`calendar-day`}>
                      <h6>{new Date(column.name).getDate()}</h6>
                      <Droppable
                        droppableId={columnId}
                        key={columnId}
                        // isDropDisabled={isWeekend ? true : false}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#fff",
                                padding: 0,
                                marginTop: 40,
                                marginBottom: 10,
                                width: "100%",
                                minHeight: "40px",
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: "0 5px",
                                            margin: "0 0 8px 0",
                                            minHeight: "10px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#263B4A"
                                              : item?.colorCode
                                              ? item?.colorCode
                                              : "#456C86",
                                            color: "white",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  );
                })}
            </div>
          </div>
        </DragDropContext>
      </div>
    );
  };

  const getCourseList = async () => {
    await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/course/list-for-dropdown`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    ).then((res) => {
      setCourseList(res.data?.courses);
    });
  };

  const getScheduleInfo = async (month) => {
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/course/shedule-info`,
        {
          month: month,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const schedule = data?.data?.schedule?.map((item) => {
        return {
          id: generateUniqueID(),
          courseId: item?.courseId,
          name: item?.courseTitle,
          date: item?.date,
          time: item?.time,
          colorCode: "#C6A24F",
        };
      });

      return schedule;
    } catch (error) {
      const data = false;
      return data;
    }
  };

  useEffect(() => {
    let mount = true;
    getCourseList();
    return () => {
      mount = false;
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="mb-4 title">{t("course_schedule")}</h3>
        </div>
      </div>

      <div className="card px-5 py-3">
        <div className="row">
          <div className="col-md-4 mb-4">
            <label>{t("select_month")}</label>
            <DatePicker
              format={"YYYY-MM"}
              placeholder={t("select_month")}
              picker="month"
              style={{ width: "100%" }}
              onChange={(val, month) => {
                handleDate(month);
              }}
            />
          </div>
          {columns && Object.keys(columns).length > 0 && (
            <div className="col-md-12">{renderDayCells()}</div>
          )}
        </div>
        {columns && Object.keys(columns).length > 0 && (
          <div className="modal-footer mt-5">
            <Popconfirm
              title={t("clear_all_for_this_month")}
              description={t("are_you_sure_to_clear_this_calender")}
              onConfirm={() => {
                handleClearAll();
              }}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Button danger>{t("delete")}</Button>
            </Popconfirm>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={loading}
              className="btn"
              style={{
                backgroundColor: "#C6A34F",
                color: "white",
                border: "1px solid #C6A34F",
                textDecoration: "none",
                padding: "5px 20px",
              }}
            >
              {t("save")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseSchedule;
