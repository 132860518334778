import React from "react";
import techDriven from "./techDriven.png";
import techQr from "./techQr.png";
export default function TechDriverApproach() {
  return (
    <div
      className=""
      style={{
        backgroundColor: "#FAFAFA",
        marginTop: "100px",
        // marginBottom: "100px",
        padding: "20px",
      }}
    >
      <div className="container mx-auto mt-5 mb-5">
        <div className="row">
          <div className="col-md-9">
            <img className="img-fluid" src={techDriven} alt="techDriven" />
          </div>
          <div className="col-md-3">
            <h3>TECH-DRIVEN APPROACH</h3>
            <p>
              Our commitment to innovation extends beyond on the conventional
              methods. By incorporating QR codes, Dashboards, and a Dedicated
              App, we revolutionize the talent search, setting new standards in
              the industry.
            </p>
            <div
              className="image-rq-div"
              style={{
                display: "flex",
                justifyContent: "end",
                // alignItems: "center",
              }}
            >
              <img className="img-fluid" src={techQr} alt="techQr" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
