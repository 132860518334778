import React from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import AcademyCard from "./AcademyCard";
import barista from "./images/barista.png";

const Barista = () => {
  return (
    <div
      className="card  pb-5 pt-3 container my-5 "
      style={{ borderRadius: "20px",
      // backgroundColor: "#f6f1e5",

    }}
    >
      <div className="d-flex justify-content-between my-5">
        <Link
          to="/mh-academy"
          style={{ textDecoration: "none", color: "gray" }}
        >
          <p className="mt-3">
            <BsFillArrowLeftCircleFill
              style={{ color: "#C6A34F", fontSize: "30px" }}
            />{" "}
            Back
          </p>
        </Link>

        <div className="d-flex">
          <img src={barista} alt="the" height={50} />
          <p
            className="mt-3 mx-1"
            style={{ fontSize: "20px", fontWeight: 500 }}
          >
           BARISTA ESSENTIALS
          </p>
        </div>
        <p></p>
      </div>

      <p style={{ color: "#7B7B7B", 
    }} className="text-center">
        Welcome to our comprehensive and dynamic Barista Course! Whether you are
        an aspiring hospitality professional or looking to enhance your existing
        skills, our course is designed to equip you with the knowledge and
        expertise needed to excel in the exciting world of waitstaff and
        hospitality. Our Barista Course is a carefully crafted program that
        covers a wide range of essential topics to prepare you for a successful
        career in the food and beverage industry.
      </p>

      <div class="row gy-5 mt-5">
        {/* <div class="col-lg-4 ">
          <AcademyCard
            comming={"Details Comming Soon"}
            course={"Basic Course"}
          />
        </div> */}
        <div class="col-lg-4 mx-auto">
          <AcademyCard
            comming={"Details Coming Soon"}
            course={"Barista Essentials"}
          />
        </div>
        {/* <div class="col-lg-4 ">
          <AcademyCard
            comming={"Details Comming Soon"}
            course={"Advance Course"}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Barista;
