import { Card, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function SuccessfullMessage() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
    >
      <Card
        style={{
          width: "80%",
          maxWidth: "600px",
          textAlign: "center",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px gray",
        }}
        bordered={false}
        hoverable
      >
        <Typography.Title level={2} style={{ color: "#C6A34F" }}>
          Success!
        </Typography.Title>
        <Typography.Paragraph>
          Our authority will contact you within 1 hour for further execution.
        </Typography.Paragraph>
        <div className="d-flex justify-content-center">
          <Typography.Title level={4} style={{ margin: "20px 0" }}>
            Download our app to get real experience of our website
          </Typography.Title>
        </div>
        <div className="d-flex justify-content-center">
          <a href="https://apps.apple.com/us/app/mh/id6446052294">
            <img
              src="https://mhpremierstaffingsolutions.com/static/media/Appstore.1c390a6e42d70aa48407.png"
              alt="Download from App Store"
              style={{ width: "150px", margin: "20px" }}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.invain.mh">
            <img
              src="https://mhpremierstaffingsolutions.com/static/media/Playstore.55dd4d86ecf14fc0e7b3.png"
              alt="Download from Play Store"
              style={{ width: "150px", margin: "20px" }}
            />
          </a>
        </div>
        <Typography.Paragraph>
          Or proceed to your dashboard for more information and updates.
        </Typography.Paragraph>
        <Link to="/client-dashboard">
          <button className="btn btn-primary">Go to Dashboard</button>
        </Link>
      </Card>
    </div>
  );
}
