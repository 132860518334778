import {
  GoogleMap,
  LoadScript,
  Marker
} from "@react-google-maps/api";
import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { fromLatLng, setKey, setLocationType } from "react-geocode";
import { responseNotification } from "../../../utils/notifcation";
import LocationSearch from "./LocationSearch";
import "./google-map.scss";
import pin from "./mapMarker.png";
setKey(`AIzaSyDj8C1VcZWPlnRrHMc_2VjMLVZ3HmVxdWw`);
setLocationType("ROOFTOP");

const containerStyle = {
  width: "100%",
  height: "500px",
  borderRadius: "10px",
  boxShadow: "0 0 6px rgb(0 0 0 / 30%)",
};

const MapModal = ({
  isModalOpen,
  setIsModalOpen,
  myLocation,
  setMyLocation,
  name,
  setName,
  form,
}) => {
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinate] = useState({});
  const [maploaded, setMapLoaded] = useState(false);

  const handleOk = () => {
    if(!name){
      responseNotification("Address is required", "error");
      return;
    }
    else if (loading) {
      responseNotification("Please move to add a valid location", "error");
      return;
    }
    form.setFieldsValue({
      restaurantAddress: name,
      lat: parseFloat(myLocation.lat),
      lng: parseFloat(myLocation.lng),
    });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDrag = (e) => {
    fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        console.log({ address });
        console.log({
          lat: parseFloat(e.latLng.lat()),
          lng: parseFloat(e.latLng.lng()),
        });
        setName(address);
        setLoading(false);
        setMyLocation({
          lat: parseFloat(e.latLng.lat()),
          lng: parseFloat(e.latLng.lng()),
        });
        setCoordinate({
          lat: parseFloat(e.latLng.lat()),
          lng: parseFloat(e.latLng.lng()),
        });
      },
      (error) => {
        setLoading(true);
        responseNotification("You select an invalid location", "error");
        setName("");
        console.error(error);
      }
    );
  };

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setMyLocation({ lat: latitude, lng: longitude });
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  return (
    <Modal
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"900px"}
    >
      <div className="my-4">
        <label>Address</label>
        <Input
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
      </div>

      <div className="relative">
        {maploaded && (
          <LocationSearch
            setMyLocation={setMyLocation}
            myLocation={myLocation}
            setCoordinate={setCoordinate}
            setName={setName}
            setLoading={setLoading}
          />
        )}
        <LoadScript
          googleMapsApiKey={"AIzaSyDj8C1VcZWPlnRrHMc_2VjMLVZ3HmVxdWw"}
          loadingElement={<div>Loading...</div>}
          libraries={["places"]}
        >
          <GoogleMap
            id="marker-example"
            onLoad={() => setMapLoaded(true)}
            mapContainerStyle={containerStyle}
            zoom={15}
            onZoomChanged={() => {}}
            center={myLocation}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              gestureHandling: "greedy",
            }}
          >
            <>
              <Marker
                position={myLocation}
                animation="BOUNCE"
                icon={{
                  url: pin,
                  scaledSize: { width: 30, height: 45 },
                }}
                onDragEnd={(e) => {
                  handleDrag(e);
                }}
                draggable={true}
                title="Move Pin"
                shape="MarkerShapeCircle"
              ></Marker>
            </>
          </GoogleMap>
        </LoadScript>
      </div>
    </Modal>
  );
};

export default MapModal;
