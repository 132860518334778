import { addMonths, format, subMonths } from "date-fns";
import React, { useState } from "react";
import { FaMinus } from "react-icons/fa";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { getUserCurrencyByCountry } from "../../../utils/commonFunctions";
import "./daycss.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Calendar({
  bookedDates,
  pendingDates,
  unavailableDates,
  selectedDays,
  ranges,
  handleDayClick,
  isSameDay,
  goBack,
  today,
  isDateWithinRange,
  state,
}) {
  const {t} = useTranslation();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [prevButtonClicked, setPrevButtonClicked] = useState(true);

  // console.log("bookedDates", bookedDates);
  // console.log("pendingDates in new calendar", pendingDates);
  // console.log("unavailableDates", unavailableDates);

  console.log("selectedDays", state?.positionId);

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();

  const calendarDays = [];

  const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // get the months daysName
  // const dayNames = [];
  // first one will be date 1th's day name
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  let firstDayOfMonthIndex = firstDayOfMonth.getDay() - 1;
  // console.log("firstDayOfMonthIndex", firstDayOfMonthIndex);
  // console.log("firstDayOfMonth", firstDayOfMonth);
  // console.log("firstDayOfMonth.getDay()", firstDayOfMonth.getDay());
  // now make the date 1th's day name as the first day of the week in the calendar
  // const daysInWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  // for (let i = 0; i < daysInWeek.length; i++) {
  //   dayNames?.push(daysInWeek[(i + firstDayOfMonthIndex) % 7]);
  // }
  // console.log("dayNames", dayNames);

  const getDayStatusClass = (date) => {
    const isBooked = bookedDates.some((range) => {
      const withinRange = isDateWithinRange(date, range);
      return withinRange;
    });
    const isPending = pendingDates.some((range) => {
      const withinRange = isDateWithinRange(date, range);
      return withinRange;
    });
    const isSelected = selectedDays.some((selectedDate) => {
      const withinRange = isSameDay(selectedDate, date);
      return withinRange;
    });
    const isUnavailable = unavailableDates.some((range) => {
      const withinRange = isDateWithinRange(date, range);
      return withinRange;
    });
    const isInRange = ranges.some((range) => isDateWithinRange(date, range));
    const isItFistAnyRange = ranges.some((range) => {
      return (
        range.from.getMonth() === date.getMonth() &&
        range.from.getDate() === date.getDate()
      );
    });
    const isItLastAnyRange = ranges.some((range) => {
      return (
        range.to.getMonth() === date.getMonth() &&
        range.to.getDate() === date.getDate()
      );
    });

    const isItFirstLastAnyRange = ranges.some((range) => {
      return (
        range.from.getMonth() === date.getMonth() &&
        range.from.getDate() === date.getDate() &&
        range.to.getMonth() === date.getMonth() &&
        range.to.getDate() === date.getDate()
      );
    });

    if (date < today) {
      return "disabled1";
    }
    if (isBooked) {
      return "red"; // Booked
    } else if (isPending) {
      return "yellow"; // Pending
    } else if (isSelected) {
      return "select"; // Selected
    } else if (isUnavailable) {
      return "blue"; // Unavailable
    } else if (isInRange && !isItFistAnyRange && !isItLastAnyRange) {
      return "within-range"; // Date is part of a range
    } else if (isItFirstLastAnyRange) {
      return "single-range"; // From and to are the same
    } else if (isItFistAnyRange) {
      return "range-start"; // class for the first day of the range
    } else if (isItLastAnyRange) {
      return "range-end"; // class for the last day of the range
    }
    // Default: Available
    return "green";
  };

  while (firstDayOfMonthIndex > 0) {
    calendarDays.push(<div key={`empty-${firstDayOfMonthIndex}`}>{null}</div>);
    firstDayOfMonthIndex--;
  }

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    const dayStatusClass = getDayStatusClass(date); // Get the CSS class based on status
    calendarDays.push(
      <div>
        <div
          key={day}
          className={`calendars-day ${dayStatusClass}`}
          onClick={() =>
            handleDayClick(date, { selected: selectedDays.includes(date) })
          }
        >
          {format(date, "dd")}
        </div>
      </div>
    );
  }

  const handlePrevMonth = () => {
    // Move to the previous month
    setCurrentDate(subMonths(currentDate, 1));
    setNextButtonClicked(false); // Reset the next button
    setPrevButtonClicked(true); // Set the prev button as clicked
  };

  const handleNextMonth = () => {
    // Move to the next month
    setCurrentDate(addMonths(currentDate, 1));
    setNextButtonClicked(true); // Set the next button as clicked
    setPrevButtonClicked(false); // Reset the prev button
  };

  return (
    <div className="calendars">
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            height: "50px",
            color: "#C6A34F",
          }}
        >
          <IoChevronBackCircleOutline
            onClick={goBack}
            size={25}
            backgroundColor="#A6A6A6"
            color="#C6A34F"
          />
          {state?.positionId && (
            <>
              <div className="dashboard2TotalHourwrapper">
                <span className="dashboard2totalhourspan">{t("name")}: </span>
                <Link
                  className="text-decoration-none"
                  style={{
                    cursor: "pointer",
                    color: "#C6A34F",
                  }}
                  to={`/employee-view-details/${state?.positionId?._id}`}
                >
                <span>{state?.positionId?.name}</span>
                </Link>
              </div>
              <div className="dashboard2TotalHourwrapper">
                <span className="dashboard2totalhourspan">Position:</span>
                <span> {state?.positionId?.positionName}</span>
              </div>
              <div className="dashboard2TotalHourwrapper">
                <span className="dashboard2totalhourspan">{t("rate")}:</span>
                <span>
                  {" "}
                  {state?.positionId?.hourlyRate}{" "}
                  {getUserCurrencyByCountry(state?.positionId?.countryName)}{
                    t("per_hour")
                  }
                </span>{" "}
                {}
              </div>
            </>
          )}
        </div>
      </>
      <div>
        <p
          style={{
            color: "#C6A34F",
            fontWeight: "bold",
          }}
        >
{t("double_click_if_you_wish_to_select_different_days_not_in_a_row.")}        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          height: "50px",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
          className="month-year"
        >
          <div>
            {!prevButtonClicked && (
              <span onClick={handlePrevMonth}>
                <MdNavigateBefore
                  style={{
                    color: "#C6A34F",
                    fontSize: "30px",
                    cursor: "pointer",
                    marginTop: "-2px",
                  }}
                />
              </span>
            )}
            <span>{format(currentDate, "MMMM yyyy")}</span>
            {!nextButtonClicked && (
              <span onClick={handleNextMonth}>
                <MdNavigateNext
                  style={{
                    color: "#C6A34F",
                    // backgroundColor: "#C6A34F",
                    fontSize: "30px",
                    cursor: "pointer",
                    marginTop: "-2px",
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="first">
            {" "}
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                // width: "180px", // Adjust the width as needed
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginLeft: "100px",
                  width: "120px", // Adjust the width as needed
                }}
              >
                <div className="circle green1"></div>
                <span>{t("available")}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "120px", // Adjust the width as needed
                }}
              >
                <div className="circle blue1"></div>
                <span>{t("unavailable")}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "120px", // Adjust the width as needed
                }}
              >
                <div className="circle red1"></div>
                <span>{t("booked")}</span>
              </div>
            </div>
          </div>
          <div className="first">
            {" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // width: "10px", // Adjust the width as needed
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "120px", // Adjust the width as needed
                }}
              >
                <div className="circle yellow1"></div>
                <span>{t("pending")}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "120px", // Adjust the width as needed
                }}
              >
                <div className="circle select1"></div>
                <span>{t("selected")}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "120px", // Adjust the width as needed
                }}
              >
                <div className="circle disabled"></div>
                <span>{t("disabled")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="calendars-day-name">
        <div className="calendars-header">
          {dayNames.map((dayName, index) => (
            <div key={index} className="day-name">
              {dayName}
            </div>
          ))}
        </div>
      </div>
      <div className="calendars-grid">{calendarDays}</div>
      <div className="calendars-footer">
        <span
          onClick={prevButtonClicked ? null : handlePrevMonth} // Disable onClick when prevButtonClicked is true
        >
          <FaMinus
            style={{
              color: nextButtonClicked ? "" : "#C6A34F",
              fontSize: "20px",
              // cursor: "pointer",
              cursor: nextButtonClicked ? "pointer" : "not-allowed", // Change cursor based on prevButtonClicked
            }}
          />
        </span>
        <span
          onClick={nextButtonClicked ? null : handleNextMonth} // Disable onClick when nextButtonClicked is true
        >
          <FaMinus
            style={{
              color: nextButtonClicked ? "#C6A34F" : "",
              fontSize: "20px",
              cursor: nextButtonClicked ? "not-allowed" : "pointer", // Change cursor based on nextButtonClicked
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default Calendar;
