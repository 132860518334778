// DynamicCarousel.jsx
import React from "react";
import Slider from "react-slick";

const DynamicCarousel = ({ items, slidesToShow, autoplaySpeed }) => {
  const shouldAutoplay = items.length > 4;

  const settings = {
    infinite: true,
    slidesToShow: slidesToShow || 4,
    slidesToScroll: 1,
    autoplay: shouldAutoplay,
    autoplaySpeed: autoplaySpeed || 2000,
    arrows: true,
    prevArrow: (
      <button type="button" className="slick-prev">
        Previous
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        Next
      </button>
    ),
  };

  return (
    <Slider
      {...settings}
      style={{
        display: "flex",
      }}
    >
      {items.map((item, index) => (
        <div key={index} className="carousel-item">
          {item}
        </div>
      ))}
    </Slider>
  );
};

export default DynamicCarousel;
