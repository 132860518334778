import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, Checkbox, Input } from "antd";
import { token } from "../../../utils/authentication";
import toast from "react-hot-toast";
import GoBack from "../../../utils/goBack";
export default function CreateService() {
  const handleSubmit = async (values, { resetForm }) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/service/create`;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      //   console.log("Service created successfully");
      // Handle success, maybe redirect or show a success message
      toast.success("Service created successfully");
      resetForm();
    } catch (error) {
      console.error("There was a problem creating the service:", error.message);
      // Handle error, maybe show an error message to the user
    }
  };

  return (
    <div className="container mx-auto">
      <GoBack />
      <h1>Create Service</h1>
      <Formik
        initialValues={{
          name: "",
          method: "",
          controller: "",
          url: "",
          path: "",
          active: true,
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <label htmlFor="name">Name:</label>
              <Field as={Input} type="text" name="name" />
            </div>
            <div>
              <label htmlFor="method">Method:</label>
              <Field as={Input} type="text" name="method" />
            </div>
            <div>
              <label htmlFor="controller">Controller:</label>
              <Field as={Input} type="text" name="controller" />
            </div>
            <div>
              <label htmlFor="url">URL:</label>
              <Field as={Input} type="text" name="url" />
            </div>
            <div>
              <label htmlFor="path">Path:</label>
              <Field as={Input} type="text" name="path" />
            </div>
            <div>
              <label htmlFor="active">Active:</label>
              <Field as={Checkbox} type="checkbox" name="active" />
            </div>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
