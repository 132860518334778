import { DatePicker, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { token } from "../../../../utils/authentication";
import { getUserCurrencyByCountry } from "../../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

function EmployeeDashboard() {
  const { t } = useTranslation();
  const [getEmployee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageSize, setpageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const [dateFilter, setDateFilter] = useState(null);
  const handleDateFilter = (date, dateString) => {
    setDateFilter(dateString);
    console.log("date", dateString);
  };

  const minutestoHours = (secs) => {
    const isMinus = secs < 0 ? true : false;
    const sec = isMinus ? secs * -1 : secs;
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = (sec - hours * 3600 - minutes * 60).toFixed(0);
    return (
      (isMinus ? "-" : "") +
      (hours < 10 ? "0" : "") +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds +
      " H"
    );
  };

  //Set filter data

  const fetchEmployees = useCallback(async () => {
    setLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories/list?page=${currentPage}&limit=${pageSize}` +
          `${dateFilter ? `&filterDate=${dateFilter}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        },
      );

      if (res && res?.data.statusCode === 200) {
        const checkInCheckOutHistories = res.data.checkInCheckOutHistory.map(
          (item) => {
            const finalCheckIn =
              item?.checkInCheckOutDetails?.clientCheckInTime ||
              item?.checkInCheckOutDetails?.checkInTime;
            const finalCheckOut =
              item?.checkInCheckOutDetails?.clientCheckOutTime ||
              item?.checkInCheckOutDetails?.checkOutTime;
            const finalBreakTime =
              item?.checkInCheckOutDetails?.clientBreakTime ||
              item?.checkInCheckOutDetails?.breakTime;
            const isEditCheckIn = item?.checkInCheckOutDetails
              ?.clientCheckInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                  "YYYY-MM-DD HH:mm:ss",
                ) ===
                dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                  "YYYY-MM-DD HH:mm:ss",
                )
                ? false
                : true
              : false;
            const isEditCheckOut = item?.checkInCheckOutDetails
              ?.clientCheckOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                  "YYYY-MM-DD HH:mm:ss",
                ) ===
                dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                  "YYYY-MM-DD HH:mm:ss",
                )
                ? false
                : true
              : false;
            const isEditBreakTime = item?.checkInCheckOutDetails
              ?.clientBreakTime
              ? item?.checkInCheckOutDetails?.breakTime ===
                item?.checkInCheckOutDetails?.clientBreakTime
                ? false
                : true
              : false;
            console.log(item?.checkInCheckOutDetails?.checkInTime);
            return {
              date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
              id: item?._id,
              country: item?.restaurantDetails?.countryName,
              restaurantName: item?.restaurantDetails?.restaurantName,
              employeeName: item?.employeeDetails?.name,
              position: item?.employeeDetails?.positionName,
              restaurantRate:
                item?.employeeDetails?.hourlyRate +
                " " +
                getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
                "/Hour",
              customerRate:
                item?.employeeDetails?.contractorHourlyRate +
                " " +
                getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
                "/Hour",
              checkIn: item?.checkInCheckOutDetails?.checkInTime
                ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalCheckIn: finalCheckIn
                ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
                : null,
              isEditCheckIn: isEditCheckIn,

              checkOut: item?.checkInCheckOutDetails?.checkOutTime
                ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalcheckOut: finalCheckOut
                ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
                : null,
              isEditCheckOut: isEditCheckOut,

              break: item?.checkInCheckOutDetails?.breakTime,
              finalBreakTime: finalBreakTime ? finalBreakTime : 0,
              isEditBreakTime: isEditBreakTime,

              totalHours: finalCheckOut
                ? minutestoHours(
                    dayjs(finalCheckOut)
                      .diff(dayjs(finalCheckIn), "seconds", true)
                      .toFixed(0) -
                      finalBreakTime * 60,
                  )
                : null,

              totalAmount: finalCheckOut
                ? (
                    (dayjs(finalCheckOut)
                      .diff(dayjs(finalCheckIn), "seconds", true)
                      .toFixed(0) -
                      finalBreakTime * 60) *
                    (item?.employeeDetails?.hourlyRate / 3600)
                  ).toFixed(2) +
                  " " +
                  getUserCurrencyByCountry(item?.restaurantDetails?.countryName)
                : null,
              clientComment: item?.checkInCheckOutDetails?.clientComment,
              mainItem: item,
            };
          },
        );

        setEmployee(checkInCheckOutHistories || []);
        setTotalData(res.data.total);
        setLoading(false);
      } else if (res && res?.data.statusCode === 400) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  }, [currentPage, pageSize, dateFilter]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  // console.log("getEmployee", getEmployee);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/employee-dashboard?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // render upto totalData
      render: (value, record, index) => {
        return (
          <span>
            {(currentPage - 1) * pageSize + (index + 1)}
            {/* {index + 1} */}
          </span>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: t("resturant_name"),
      dataIndex: "restaurantName",
    },
    {
      title: t("check_in"),
      dataIndex: "checkIn",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckIn && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkIn}
              </div>
            )}
            <div
              style={
                record?.isEditCheckIn
                  ? { color: "#c6a34f", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckIn}
            </div>
          </div>
        );
      },
    },
    {
      title: t("check_out"),
      dataIndex: "checkOut",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckOut && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkOut}
              </div>
            )}
            <div
              style={
                record?.isEditCheckOut
                  ? { color: "#c6a34f", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalcheckOut}
            </div>
          </div>
        );
      },
    },

    {
      title: t("break"),
      dataIndex: "break",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditBreakTime && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.break} {t("min")}
              </div>
            )}
            <div
              style={
                record?.isEditBreakTime
                  ? { color: "#c6a34f", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalBreakTime} {t("min")}
            </div>
          </div>
        );
      },
    },
    {
      title: t("total_hours"),
      dataIndex: "totalHours",
    },
    {
      title: t("client_complain"),
      dataIndex: "clientComment",
    },
  ];

  return (
    <div>
      {/* Inner Dashboard Search Part Start */}
      <section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/employee-home">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                <img
                  src="assets/frontend/images/InnerDashboard/mapSearch.png"
                  className="img-fluid"
                  alt="mapSearch"
                />
                <span className="innerDashSearchItemsSpan">
                  {t("dashboard")}
                </span>
                <div
                  className="d-flex"
                  style={{
                    width: "800px",
                    // border: "1px solid #C6A34F",
                  }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    size="large"
                    onChange={handleDateFilter}
                    placeholder={t("select_date")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="InnnerDashboardTable">
        <div className="container">
          <Table
            columns={columns}
            dataSource={getEmployee}
            // loading={loading}
            pagination={{
              pageSize: Number(pageSize),
              current: Number(currentPage),
              showSizeChanger: false,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setpageSize(pageSize);
                navigate(`?page=${page}&limit=${pageSize}`);
              },
              total: totalData,
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default EmployeeDashboard;
