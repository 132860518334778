import React from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function GoBack() {
  const navigate = useNavigate();

  const gotoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px",
          height: "50px",
          color: "#C6A34F",
        }}
        onClick={gotoBack}
      >
        <IoChevronBackCircleOutline
          size={25}
          backgroundColor="#A6A6A6"
          color="#C6A34F"
        />
        <span style={{ marginLeft: "10px" }}>Go Back</span>
      </div>
    </>
  );
}
