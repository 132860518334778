import React, { useState } from "react";
import Slider from "react-slick";

const REACT_APP_ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_FRONTEND_URL;

export default function PositionCarousel({
  positions,
  setPositionfromCarousel,
}) {
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  console.log("selected position", selectedCardIndex);

  const sliderSettings = {
    slidesToShow: 8,
    slidesToScroll: 3,
    dots: false,
    arrows: true,
    className: "product-slick",
    swipe: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            },
        },
    ]
  };

  const cardDesign = {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "15px",
    width: "50px",
    height: "50px",
    margin: "0 auto",
    cursor: "pointer",
    border: "2px solid transparent",
  };

  const handleCardClick = (position, index) => {
    // Toggle the selection
    setPositionfromCarousel(position);
    setSelectedCardIndex(index);
  };

  const handleDeselectClick = (event) => {
    event.stopPropagation();
    setSelectedCardIndex(null);
  };
  console.log("selected position", selectedCardIndex);

  const partnerItems = positions.map((position, index) => (
    <div key={index} onClick={() => handleCardClick(position, index)}>
      <img
        style={cardDesign}
        src={`${REACT_APP_ASSETS_BASE_URL}/${position.logo}`}
        alt={position.name}
      />
      <div
        style={{
          textAlign: "center",
          fontSize: "12px",
          marginTop: "10px",
          fontWeight: "500",
        }}
      >
        {selectedCardIndex === index && (
          <span
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#FF0000",
              color: "white",
              borderRadius: "50%",
              padding: "4px 7px",
              fontSize: "18px",
              fontWeight: "600",
            }}
            onClick={(event) => handleDeselectClick(event)}
          >
            X
          </span>
        )}

        {position.name}
      </div>
    </div>
  ));

  return (
    <>
      <Slider {...sliderSettings}>{partnerItems}</Slider>
    </>
  );
}
