import { Button, Checkbox, Input, Modal, Radio, Table } from "antd";
import axios from "axios";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";

export default function CoursePayment() {
  const {t} = useTranslation();
  const [coursePayments, setCoursePayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0); // Change to 'total'
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const componentRef = React.createRef();

  const handleViewModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  console.log(selectedRecord);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");
    const myParam2 = urlParams.get("limit");

    if (myParam === null || myParam2 === null) {
      navigate(`/admin/course-payment?page=1&limit=10`);
      // window.location.reload();
    } else {
      setPageSize(parseInt(myParam2, 10));
      setCurrentPage(parseInt(myParam, 10));
    }
  }, [navigate]);
  const columns = [
    {
      title: t("serial_no"),
      dataIndex: "serial", // You need to include a "serial" field in your data
      key: "serial",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: t("transaction_id"),
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: t("course_title"),
      dataIndex: "courseTitle",
      key: "courseTitle",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("student_id"),
      dataIndex: "studentId",
      key: "studentId",
    },
    {
      title: t("phone"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>£{text}</span>,
    },
    {
      title: t("enroll_date"),
      dataIndex: "enrolledDate",
      key: "enrolledDate",
      render: (text, record) => <span>{new Date(text).toDateString()}</span>,
    },
    {
      title: t("action"),
      dataIndex: "action",
      // render a view button
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{
              backgroundColor: "#C6A34F",
              color: "#fff",
              textAlign: "center",
            }}
            onClick={() => handleViewModal(record)}
          >
            {t("view")}
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function fetchCoursePayments() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/course-payment?&page=${currentPage}&limit=${pageSize}`
        );
        setCoursePayments(response.data.CoursePayments);
        setTotal(response.data.total);
      } catch (error) {
        console.error("Error fetching course payments:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchCoursePayments();
  }, [currentPage, pageSize]);

  return (
    <div>
      <h1>{t("course_payments")}</h1>
      <Table
        columns={columns}
        dataSource={coursePayments}
        loading={loading}
        rowKey="_id"
        pagination={{
          pageSize: Number(pageSize),
          current: Number(currentPage),
          total: Number(total),
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            navigate(`?page=${page}&limit=${pageSize}`);
          },
          showSizeChanger: true, // Add this line to enable the option
          pageSizeOptions: ["10", "20", "50", "100"], // Add this line to customize the options
        }}
      />
      <></>
      <Modal
        title={t("payment_details")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // width="1200px"
        width="210mm"
      >
        <div>
          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                className="mt-5 px-4"
                style={{
                  backgroundColor: "#C6A34F",
                  color: "white",
                  border: "1px solid #C6A34F",
                  textDecoration: "none",
                }}
              >
                {t("print_now")}
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <div
          className="container pb-5 pt-3 my-5"
          id="printContent"
          style={{
            width: "100%",
            // width: "1200px",  // Set the width to 1200px

            border: "1px solid #C6A24F",
            borderRadius: "10px",
          }}
          ref={componentRef}
        >
          <div className="">
            <Formik
              initialValues={selectedRecord}
              enableReinitialize={true}
              //   validationSchema={validationSchema}
              onSubmit={(values) => {}}
            >
              {({ values, setFieldValue, touched, errors, handleSubmit }) => (
                <Form>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h3 style={{ fontSize: "20px" }} className="ms-5">
                        MH TRAINING ACAMEDY
                      </h3>
                      <h1
                        style={{ fontSize: "50px", fontWeight: "800" }}
                        className="ms-5"
                      >
                        REGISTRATION
                      </h1>
                      <h6
                        style={{ background: "#C6A24F", padding: "5px 10px" }}
                      >
                        To Complete the registration, please answer the
                        following questions:
                      </h6>
                    </div>
                    <div
                      style={{ width: "120px", height: "120px" }}
                      className="me-5"
                    >
                      <img
                        src={"/logo.png"}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                  </div>
                  <div className="row px-5 py-3">
                    <div className="col-md-6 mb-3">
                      <label>Applicant Full Name:</label>
                      <Input
                        placeholder="Full Name"
                        value={values.name}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                      />
                      {errors.name && touched.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Phone Number</label>
                      <Input
                        placeholder="Phone"
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue("phone", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="row px-3 py-3 mx-0"
                    style={{ background: "#C6A24F" }}
                  >
                    <div className="col-md-6" style={{ fontWeight: "600" }}>
                      Course Title: {values?.courseTitle}
                    </div>
                    <div className="col-md-6" style={{ fontWeight: "600" }}>
                      Month: {values?.month}
                    </div>
                  </div>
                  <div className="row px-5 py-3">
                    <div className="col-md-6 mb-3">
                      <label>Nationality</label>
                      <Input
                        placeholder="Nationality"
                        type="text"
                        value={values.nationality}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Email Address</label>
                      <Input
                        placeholder="Email"
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                      />
                      {errors.email && touched.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Address</label>
                      <Input
                        placeholder="Address"
                        value={values.address}
                        onChange={(e) => {
                          setFieldValue("address", e.target.value);
                        }}
                      />
                      {errors.address && touched.address && (
                        <div className="text-danger">{errors.address}</div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Postcode</label>
                      <Input
                        placeholder="Postcode"
                        value={values.postcode}
                        onChange={(e) => {
                          setFieldValue("postcode", e.target.value);
                        }}
                      />
                      {errors.postcode && touched.postcode && (
                        <div className="text-danger">{errors.postcode}</div>
                      )}
                    </div>
                  </div>
                  <div className="row px-4 mt-4">
                    <div className="col-md-2 mb-3">
                      <label>Emergency Contact</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <Input
                        placeholder="Emergency Contact"
                        value={values.emmergencyNumber}
                        onChange={(e) => {
                          setFieldValue("emmergencyNumber", e.target.value);
                        }}
                      />
                      {errors.emmergencyNumber && touched.emmergencyNumber && (
                        <div className="text-danger">
                          {errors.emmergencyNumber}
                        </div>
                      )}
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>Date Of Birth</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      {moment(values.dob).format("YYYY-MM-DD")}
                      {errors.dob && touched.dob && (
                        <div className="text-danger">{errors.dob}</div>
                      )}
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>Gender</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <Radio.Group
                        value={values.gender}
                        onChange={(e) => {
                          setFieldValue("gender", e.target.value);
                        }}
                      >
                        <Radio value={"male"}>Male</Radio>
                        <Radio value={"female"}>Female</Radio>
                        <Radio value={"other"}>Other</Radio>
                      </Radio.Group>
                      {errors.gender && touched.gender && (
                        <div className="text-danger">{errors.gender}</div>
                      )}
                    </div>
                  </div>
                  <div className="row px-4 mx-4 mt-4">
                    <div
                      className="col-md-12 mb-3 py-2"
                      style={{ background: "#C6A24F" }}
                    >
                      <div className="row">
                        <div className="col-10">Questions</div>
                        <div className="col-1">Yes</div>
                        <div className="col-1">No</div>
                      </div>
                    </div>
                    <div className="col-10 mb-3">
                      1. Are you currently working?{" "}
                      {errors.currentlyWorking && touched.currentlyWorking && (
                        <div className="text-danger">
                          {errors.currentlyWorking}
                        </div>
                      )}
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.currentlyWorking == true ? true : false}
                        onChange={(e) => {
                          setFieldValue("currentlyWorking", true);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={
                          values.currentlyWorking == false ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("currentlyWorking", false);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-10 mb-3">
                      2. Have you got more than 2 years experience in
                      hospitality?
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={
                          values.experienceInHospitality == true ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("experienceInHospitality", true);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={
                          values.experienceInHospitality == false ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("experienceInHospitality", false);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-10 mb-3">
                      3. Do you have the right permit to work in UK?
                      {errors.workPermitInUK && touched.workPermitInUK && (
                        <div className="text-danger">
                          {errors.workPermitInUK}
                        </div>
                      )}
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.workPermitInUK == true ? true : false}
                        onChange={(e) => {
                          setFieldValue("workPermitInUK", true);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.workPermitInUK == false ? true : false}
                        onChange={(e) => {
                          setFieldValue("workPermitInUK", false);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-10 mb-3">
                      4. Have you got any criminal record?
                      {errors.criminalRecord && touched.criminalRecord && (
                        <div className="text-danger">
                          {errors.criminalRecord}
                        </div>
                      )}
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.criminalRecord == true ? true : false}
                        onChange={(e) => {
                          setFieldValue("criminalRecord", true);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.criminalRecord == false ? true : false}
                        onChange={(e) => {
                          setFieldValue("criminalRecord", false);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-10 mb-3">
                      5. Are you aware of anyone who may be interested in our
                      courses?
                      {errors.courseInterest && touched.courseInterest && (
                        <div className="text-danger">
                          {errors.courseInterest}
                        </div>
                      )}
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.courseInterest == true ? true : false}
                        onChange={(e) => {
                          setFieldValue("courseInterest", true);
                        }}
                      ></Checkbox>
                    </div>
                    <div className="col-1 mb-3">
                      <Checkbox
                        checked={values.courseInterest == false ? true : false}
                        onChange={(e) => {
                          setFieldValue("courseInterest", false);
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
