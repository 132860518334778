import { Button, Select } from "antd";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { token } from "../../../utils/authentication";

const { Option } = Select;

const initialValues = {
  name: "",
  country: "United Arab Emirates",
};

const CreateRole = () => {
  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/roles/create`,
        {
          name: values.name,
          country: values.country,
          slug: values.name.toUpperCase().replace(/ /g, "_"),
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      console.log(response.data);
      toast.success("Role created successfully");
    } catch (error) {
      console.log(error.data);
      toast.error("Error creating role , " + error.response.data.message);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Create Role</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <Field
                type="text"
                className="form-control"
                id="name"
                name="name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                Country
              </label>
              <Field
                as="select"
                className="form-select"
                id="country"
                name="country"
              >
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="Italy">Italy</option>
                <option value="United Kingdom">United Kingdom</option>
              </Field>
            </div>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateRole;
