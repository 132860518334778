import React, { useState, useEffect } from "react";
import { Form, Select, Button } from "antd";
import { token } from "../../../utils/authentication";
import GoBack from "../../../utils/goBack";
import toast from "react-hot-toast";

import {
  Col,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Table,
  TimePicker,
  Tooltip,
} from "antd";
const { Option } = Select;
const CreateMenuPermission = () => {
  const [country, setCountry] = useState("");
  const [roles, setRoles] = useState([]);
  const [menus, setMenus] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedMenus, setSelectedMenus] = useState([]);

  useEffect(() => {
    // Fetch roles based on country
    if (country) {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/roles/list-for-dropdown?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setRoles(data.roles);
          } else {
            console.error("Failed to fetch roles:", data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching roles:", error);
        });
    }
  }, [country]);

  useEffect(() => {
    // Fetch menus
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menu/list-for-dropdown`, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setMenus(data.menus);
        } else {
          console.error("Failed to fetch menus:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching menus:", error);
      });
  }, []);

  const handleSubmit = () => {
    // Send a POST request to create menu permission
    const payload = {
      role: selectedRole,
      menu: selectedMenus,
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/menu-permission/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success("Menu permission created successfully");
        } else {
          console.error("Failed to create menu permission:", data.message);
          toast.error("Failed to create menu permission");
        }
      })
      .catch((error) => {
        console.error("Error creating menu permission:", error);
        // Optionally, handle error
      });
  };

  return (
    <div>
      <GoBack />
      <div className="container mx-auto">
        <h2>Create Menu Permission</h2>
        <div className="d-flex">
          <p>Country:</p>
          <Select
            value={country}
            onChange={setCountry}
            style={{
              width: "300px",
              marginRight: "40px",
            }}
          >
            <Option value="Italy">Italy</Option>
            <Option value="United Kingdom">United Kingdom</Option>
            <Option value="United Arab Emirates">United Arab Emirates</Option>
          </Select>
          <p>Role:</p>
          <Select
            value={selectedRole}
            onChange={setSelectedRole}
            style={{
              width: "200px",
            }}
          >
            {roles.map((role) => (
              <Option key={role._id} value={role._id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </div>
        <Row>
          <Col span={12}>
            <div className="mt-4">
              <Form>
                <Form.Item label="Menus">
                  <Select
                    mode="multiple"
                    value={selectedMenus}
                    onChange={setSelectedMenus}
                  >
                    {menus.map((menu) => (
                      <Option key={menu._id} value={menu._id}>
                        {menu.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={handleSubmit}>
                    Create Menu Permission
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateMenuPermission;
