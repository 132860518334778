import { Button, Form, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../../utils/commonFunctions";
import axios from "axios";
import { token } from "../../../utils/authentication";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { io } from "socket.io-client";
import Loader from "../../loadar/Loader";

export default function ClientChat() {
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null); // NEW: separate ref for message container
  const [loading, setLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const [hasScrolledUp, setHasScrolledUp] = useState(false);
  const scrollToBottom = () => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    if (!hasScrolledUp) {
      scrollToBottom();
    }
  }, [chatMessages, hasScrolledUp]);

  const handleScroll = () => {
    if (messagesEndRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;
      setHasScrolledUp(scrollTop < scrollHeight - clientHeight);
    }
  };

  const handleSendMessage = () => {
    if(!message) {
      notification.error({ message: "Please enter a message" });
      return;
    }
    setSendMessageLoading(true);
    const payload = {
      senderId: getUserId(),
      conversationId: conversationId,
      dateTime: new Date(),
      text: message,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        setSendMessageLoading(false);
        setMessage("");
      })
      .catch((err) => {
        setSendMessageLoading(false);
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#f6f1e5",
    height: "620px",
    position: "relative",
  };

  const innerContainerStyle = {
    height: "calc(720px - 180px)", // Reserve 100px for the form, based on your container height of 600px
    overflowY: "auto", // Make it scrollable
    paddingBottom: "100px", // Bottom padding equal to the form height
  };

  const backButtonStyle = {
    backgroundColor: "#C6a34f",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
  };

  const adminMessageStyle = {
    textAlign: "left",
    backgroundColor: "#6DACE4",
    minwidth: "150px",
    color: "white",
    margin: "5px 0px",
    padding: "8px 10px",
    borderRadius: "10px",
    // display: "inline-block",
  };

  const clientMessageStyle = {
    textAlign: "right",
    backgroundColor: "#C6a34f",
    minwidth: "100px",
    color: "white",
    margin: "5px 0px",
    padding: "8px 10px",
    borderRadius: "10px",
    // display: "inline-block",
  };

  const formStyle = {
    backgroundColor: "#f2f2f2",
    width: "100%",
    borderRadius: "10px",
    position: "absolute",
    bottom: "0",
    zIndex: 1,
  };

  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState([]); // messages
  const [previousMessages, setPreviousMessages] = useState(false); // previous messages
  const [page, setPage] = useState(1); // page
  const [limit, setLimit] = useState(10); // limit
  const [socketData, setSocketData] = useState(null);

  // get conversation id
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setConversationId(res.data.details._id); // set conversation id
      });
  }, []);

  // get messages by conversation id
  useEffect(() => {
    if (conversationId) {
      setPreviousMessages(false);
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${conversationId}&limit=${limit}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const descendingMessages = res.data.messages.reverse();
          const modifiedMessages = descendingMessages.map((message) => {
            return {
              myMessage:
                message.senderDetails?.role === "ADMIN" ||
                message.senderDetails?.role === "SUPER_ADMIN"
                  ? false
                  : true,
              senderName: message.senderDetails?.name,
              message: message.text,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          setPreviousMessages(res.data.next);
          setMessages([...modifiedMessages, ...messages]);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, limit, page]);

  // load previous messages
  const loadPreviousMessages = () => {
    if (previousMessages) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.on("connect", () => {
      console.log("Socket connected");
    });
    socket.on("new_message", (data) => {
      setSocketData(data);
    });
    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    return () => {
      console.log("Socket disconnecting");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    console.log("Socket data", socketData);
    if (socketData && socketData?.message?.conversationId === conversationId) {
      setMessages([
        ...messages,
        {
          myMessage:
            socketData.message.senderDetails?.role === "ADMIN" ||
            socketData.message.senderDetails?.role === "SUPER_ADMIN"
              ? false
              : true,
          senderName: socketData.message.senderDetails?.name,
          message: socketData.message.text,
          time: moment(socketData.message.dateTime).format(
            "DD MMM YY @ h:mm A"
          ),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  return (
    <div style={containerStyle}>
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <button style={backButtonStyle} onClick={goBack}>
          <MdArrowBackIos />
        </button>
        <span
          style={{
            color: "black",
            fontSize: "20px",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          Admin
        </span>
      </div>
      <div
        style={{
          textAlign: "center",
          color: "blue",
          cursor: previousMessages ? "pointer" : "not-allowed",
          marginBottom: "10px",
          backgroundColor: "lightgray",
        }}
        onClick={loadPreviousMessages}
      >
        Load previous messages
      </div>
      {loading && messages.length === 0 ? (
        <Loader />
      ) : (
        <div
          style={innerContainerStyle}
          ref={messagesContainerRef}
          onScroll={handleScroll}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: msg.myMessage ? "flex-end" : "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px 0px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "gray",
                      margin: "0px 10px",
                    }}
                  >
                    {msg.time}
                  </span>
                </div>
                <div
                  style={msg.myMessage ? clientMessageStyle : adminMessageStyle}
                >
                  {msg.message}
                </div>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef}></div> {/* NEW: empty div at the end */}
        </div>
      )}
      <Form layout="inline" onFinish={handleSendMessage} style={formStyle}>
        <Form.Item style={{ flex: 1 }}>
          <TextArea
            style={{ resize: "none" }}
            rows={4}
            type="textArea"
            placeholder="Type a message"
            value={message} // bind value to state
            onChange={(e) => setMessage(e.target.value)} // update state on change
          />
        </Form.Item>
        <Form.Item>
          <Button loading={sendMessageLoading} type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

// {
//     https://chat.openai.com/c/fc8a426f-a851-4e32-acb9-c2ebdbbfe2d6
// }
