import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import React, { useState } from "react";
import { token } from "../../../utils/authentication";

const FileUpload = ({ onUpload, cropRatio, contentType }) => {
  console.log("contentType", contentType);
  const [fileUrl, setFileUrl] = useState(null);

  const customRequest = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/commons/upload-file`, formData, {
        headers: {
          Authorization: `Bearer ${token()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("File Upload Response:", response.data);
        setFileUrl(response.data.data.file);
        onUpload(response.data.data.file);
        onSuccess();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        onError(error);
      });
  };

  return (
    <>
      {contentType === "image" ? (
        <ImgCrop rotate aspect={cropRatio}>
          <Upload
            customRequest={customRequest}
            listType="picture"
            className="upload-list-inline"
            accept={
              contentType === "image" ? ".jpg, .jpeg, .png" : ".mp4, .mkv"
            }
            maxCount={1}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </ImgCrop>
      ) : (
        <Upload
          customRequest={customRequest}
          listType="picture"
          className="upload-list-inline"
          accept={contentType === "image" ? ".jpg, .jpeg, .png" : ".mp4, .mkv"}
          maxCount={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button icon={<UploadOutlined />}>Select Video</Button>
        </Upload>
      )}
    </>
  );
};

export default FileUpload;
