import { Button, Form, Input, Select, Switch } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import GoBack from "../../../utils/goBack";
const { Option } = Select;

const CreateTermCondition = () => {
  const formik = useFormik({
    initialValues: {
      description: "",
      country: "United Kingdom",
      active: true,
    },
    validate: (values) => {
      const errors = {};

      if (!values.description) {
        errors.description = "Description is required";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const token = localStorage.getItem("accessToken");

        if (!token) {
          // Handle the case where there is no token available
          console.error("Access token not available");
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/terms-conditions/create`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Handle success, show message, or redirect
        console.log("API Response:", response.data);
        toast.success("Term and condition created successfully");
        resetForm();
      } catch (error) {
        // Handle error, show error message
        console.error("API Error:", error);
        toast.error("Failed to create term and condition");
      }
    },
  });

  return (
    <>
      <GoBack />
      <div
        style={{
          width: "50%",
          margin: "auto",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <Form onFinish={formik.handleSubmit} layout="vertical">
          <Form.Item
            label="Description"
            required
            validateStatus={formik.errors.description ? "error" : ""}
            help={formik.errors.description}
          >
            <Input.TextArea
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>

          <Form.Item label="Country" required>
            <Select
              name="country"
              value={formik.values.country}
              onChange={(value) => formik.setFieldValue("country", value)}
            >
              <Option value="Italy">Italy</Option>
              <Option value="United Kingdom">United Kingdom</Option>
              <Option value="Dubai">Dubai</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Active" valuePropName="checked">
            <Switch
              name="active"
              checked={formik.values.active}
              onChange={(value) => formik.setFieldValue("active", value)}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CreateTermCondition;
