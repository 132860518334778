import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { BsFillBookmarkFill } from "react-icons/bs";
import { IoNotificationsSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { ContextApi } from "../../../hooks/ContextApi";
import {
  changeLanguageFunc,
  getUserId,
  getUserInfo,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import Notification from "./Notification/Notification";
import notificationSound from "./Notification/notification-sound.mp3";
function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = getUserInfo();

  const jwtDecode = jwtTokenDecode();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(
    localStorage.getItem("countryCode") || "GB"
  );

  const {
    getShortList,
    notificationCount,
    setNotificationCount,
    loadingShortList,
  } = useContext(ContextApi);

  // const socket = io("wss://52.86.43.146:8000", {
  //   withCredentials: false,
  //   transports: ["websocket", "polling", "flashsocket"],
  // });
  // useEffect(() => {
  //   // Listen for the 'eventName' event (replace with your specific event name)
  //   socket.on("notification", (dataFromServer) => {
  //     // Handle the data received from the server
  //     // setData(dataFromServer);
  //     const logedInUserId = getUserId();
  //     if (dataFromServer.userInfo._id === logedInUserId) {
  //       setNotificationCount(notificationCount + 1);
  //       responseNotification(dataFromServer.body, "success");
  //     }
  //   });

  //   // Clean up the socket event listener when the component unmounts
  //   return () => {
  //     socket.off("notification");
  //   };
  // }, []);

  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    withCredentials: false,
    transports: ["websocket", "polling", "flashsocket"],
  });

  useEffect(() => {
    console.log("WebSocket connection initiated.");

    socket.on("notification", (dataFromServer) => {
      console.log("Received notification:", dataFromServer);

      const logedInUserId = getUserId();
      if (dataFromServer.userInfo._id === logedInUserId) {
        setNotificationCount(notificationCount + 1);
        responseNotification(dataFromServer.body, "success");
        new Audio(notificationSound).play();
      }
    });

    socket.on("new_message", (data) => {
      var currentUrl = window.location.href;
      // CHECK IF THE MESSAGE IS FROM THE ADMIN
      if (
        data.message.receiverDetails?.length > 1 &&
        data.message.receiverDetails
          .map((item) => item.receiverId)
          .includes(getUserId()) &&
        !currentUrl.includes("/client-help-support")
      ) {
        const message = `Admin: ${data.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
        return;
      }

      // CHECK IF THE MESSAGE IS FROM THE USER
      if (data.message.receiverDetails[0].receiverId === getUserId()) {
        const message = `${data.message.senderDetails.name} : ${data.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
      }
    });

    return () => {
      socket.off("notification");
      socket.off("new_message");
      console.log("WebSocket connection closed.");
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginData");

    navigate("/");

    window.location.reload();
  };
  const notificationMenu = (
    <Notification setNotificationCount={setNotificationCount} />
  );
  const [prevCount, setPrevCount] = useState(0);
  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (notificationCount > prevCount) {
      setShake(true);
      const timer = setTimeout(() => setShake(false), 500); // 500ms for the shaking duration
      return () => clearTimeout(timer);
    }
    setPrevCount(notificationCount);
  }, [notificationCount, prevCount]);

  const shakingStyle = {
    animation: shake ? "shake 0.5s cubic-bezier(.36,.07,.19,.97) both" : "",
  };
  // console.log(notificationCount);
  return (
    <section className="Dashboardheader sticky-top">
      <div className="container header_container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar DashboardNavban navbar-expand-md navbar-light">
              <a className="navbar-brand " href="/">
                <img
                  className="img-fluid Dashboard_nav_logo"
                  src="/assets/frontend/images/Dashboardimages/logo.png"
                  alt="Default"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="Dash_navb_custom collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">
                  <li>
                    <ReactFlagsSelect
                      defaultCountry="GB"
                      className="menu-flags"
                      countries={["GB", "AE", "IT"]}
                      selected={selected || "GB"}
                      customLabels={{
                        US: "English",
                        GB: "English",
                        AE: "Arabic",
                        IT: "Italian",
                      }}
                      onSelect={(e) => {
                        setSelected(e);
                        changeLanguageFunc(e);
                      }}
                    />
                  </li>
                  <li className="nav-item">
                    <Link
                      className="Dashboard_navbar_custom_hover nav-link"
                      aria-current="page"
                      to="/short-list"
                    >
                      <div className="navLinkImageTextWraper">
                        <BsFillBookmarkFill
                          style={{
                            fontSize: "22px",
                            color: getShortList?.total > 0 ? "#C6a34f" : "",
                          }}
                        />
                        <span className="DashboardBookmarkNavSpan mx-1">
                          {getShortList ? getShortList.total : 0}
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Dropdown overlay={notificationMenu} trigger={["click"]}>
                      <a
                        className="Dashboard_navbar_custom_hover nav-link"
                        href="#"
                      >
                        <div className="navLinkImageTextWraper">
                          <IoNotificationsSharp
                            style={{
                              fontSize: "25px",
                              color: notificationCount > 0 ? "red" : "black",
                              marginLeft: "-20px",

                              ...shakingStyle,
                            }}
                          />
                          <span
                            className="DashboardNotificationNavSpan"
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {notificationCount > 20
                              ? "20+"
                              : notificationCount
                              ? notificationCount
                              : 0}
                          </span>
                        </div>
                      </a>
                    </Dropdown>
                  </li>

                  <li className="nav-item">
                    {!jwtDecode ? (
                      <a
                        className="Dashboard_navbar_custom_hover nav-link"
                        href="#"
                      >
                        <img
                          src="/assets/frontend/images/Dashboardimages/navbar/CONTACT.png"
                          alt="Default"
                        />
                      </a>
                    ) : (
                      // <button
                      //   style={{ color: "#ffffff" }}
                      //   className=" btn btn-sm btn-danger nav-link"
                      //   onClick={handleLogout}
                      // >
                      //   Logout
                      // </button>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Link
                              to="/profile"
                              className="text-decoration-none"
                            >
                              <Menu.Item className="">{t("profile")}</Menu.Item>
                            </Link>
                            <Link
                              to="/setting"
                              className="text-decoration-none"
                            >
                              <Menu.Item className="">{t("setting")}</Menu.Item>
                            </Link>
                            <Menu.Item
                              className="bg-danger text-white"
                              onClick={handleLogout}
                            >
                              {t("logout")}
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        {userInfo?.profilePicture ? (
                          <img
                            style={{
                              height: 40,
                              width: 40,
                              padding: "1px",
                              border: "1px solid #C6a34f",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            src={
                              userInfo?.profilePicture
                                ? process.env.REACT_APP_ASSETs_BASE_URL +
                                  "/" +
                                  userInfo?.profilePicture
                                : null
                            }
                            className="Dashboard2-card-img-top"
                            alt="custom-profile"
                          />
                        ) : (
                          <Avatar
                            style={{
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                            icon={<UserOutlined />}
                          />
                        )}
                      </Dropdown>
                    )}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
