import React, { useState, useEffect } from "react";
import { Table, Switch, Pagination, Button, Modal, Form, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios"; // You may need to install axios: npm install axios
import toast from "react-hot-toast";
import GoBack from "../../../utils/goBack";

const { Item } = Form;

export default function ServiceList() {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingService, setEditingService] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchServices(currentPage);
  }, [currentPage]);

  const fetchServices = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/service?page=${page}&limit=${itemsPerPage}`
      );
      const { services: fetchedServices, total } = response.data;
      setServices(fetchedServices);
      setTotalItems(total);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusChange = async (id, active) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/service/update-status`,
        {
          id,
          active,
        }
      );
      toast.success("Status updated successfully");
      // Refresh the service list after updating status
      fetchServices(currentPage);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };

  const handleEdit = (record) => {
    setEditingService(record);
    form.setFieldsValue(record);
    setEditModalVisible(true);
  };

  const handleEditSubmit = async (values) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/service/update`, {
        id: editingService._id,
        ...values,
      });
      toast.success("Service updated successfully");
      setEditModalVisible(false);
      fetchServices(currentPage);
    } catch (error) {
      console.error("Error updating service:", error);
      toast.error("Failed to update service");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Controller",
      dataIndex: "controller",
      key: "controller",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
    },
    // {
    //   title: "Active",
    //   dataIndex: "active",
    //   key: "active",
    //   render: (active, record) => (
    //     <Switch
    //       checked={active}
    //       onChange={(checked) => handleStatusChange(record._id, checked)}
    //     />
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => handleEdit(record)}
          icon={<EditOutlined />}
        />
      ),
    },
  ];

  return (
    <div>
      <GoBack />
      <h1>Service List</h1>
      <Table dataSource={services} columns={columns} pagination={false} />
      <Pagination
        current={currentPage}
        total={totalItems}
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        showSizeChanger={false}
        style={{ marginTop: "20px", float: "right" }}
      />
      <Modal
        title="Edit Service"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditSubmit}>
          <Item label="Name" name="name">
            <Input />
          </Item>
          <Item label="Method" name="method">
            <Input readOnly className="not-allowed-cursor" />
          </Item>
          <Item label="Controller" name="controller">
          <Input readOnly className="not-allowed-cursor" />
          </Item>
          <Item label="URL" name="url">
          <Input readOnly className="not-allowed-cursor" />
          </Item>
          <Item label="Path" name="path">
          <Input readOnly className="not-allowed-cursor" />
          </Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
