import React from "react";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import PrivateRoute from "./routes/PrivateRoute";

import Dashboard from "./components/admin/Dashboard";
import ClientDetails from "./components/admin/client/ClientDetails";
import ClientList from "./components/admin/client/ClientList";
import EmployeeDetails from "./components/admin/employee/EmployeeDetails";
import EmployeeList from "./components/admin/employee/EmployeeList";
import AddMHEmployee from "./components/admin/mhEmployee/AddMHEmployee";
import EditMHEmployee from "./components/admin/mhEmployee/EditMHEmployee";
import Position from "./components/admin/position/Position";
import Skill from "./components/admin/skill/Skill";
import Source from "./components/admin/source/Source";
import AdminLogin from "./components/frontend/auth/AdminLogin";
import RegisterWelcome from "./components/frontend/profile/RegisterWelcome";
import EmployeeRegister from "./components/frontend/register/EmployeeRegister";
import MasterLayout from "./layouts/admin/MasterLayout";

//For client
import ChangePassword from "./components/admin/ChangePassword";
import BookForClient from "./components/admin/bookForClient/BookForClient";
import BookingHistory from "./components/admin/bookingHistory/BookingHistory";
import ContactList from "./components/admin/contact/ContactList";
import CourseCategory from "./components/admin/course/CourseCategory";
import CourseList from "./components/admin/course/CourseList";
import CoursePayment from "./components/admin/course/CoursePayment";
import CoursePromotion from "./components/admin/course/CoursePromotion";
import CourseSchedule from "./components/admin/course/CourseSchedule";
import EmployeePortfolio from "./components/admin/employee/EmployeePortfolio";
import EmployeePortfolioDetails from "./components/admin/employee/EmployeePortfolioDetails";
import OthersEmployeeList from "./components/admin/employee/OthersEmployeeList";
import ViewCertificate from "./components/admin/employee/ViewCertificate";
import ViewEmployeeDetails from "./components/admin/employee/ViewEmployeeDetails";
import EmployeePaymentHistory from "./components/admin/employeePayment/EmployeePaymentHistory";
import AddInvoice from "./components/admin/invoice/AddInvoice";
import InvoiceList from "./components/admin/invoice/InvoiceList";
import AddMeet from "./components/admin/meet/AddMeet";
import SingleMeet from "./components/admin/meet/SingleMeet";
import ViewMeet from "./components/admin/meet/ViewMeet";
import MHEmployeeList from "./components/admin/mhEmployee/MHEmployeeList";
import NotificationList from "./components/admin/notification/NotificationList";
import ManageReview from "./components/admin/review/ManageReview";
import ClientEmployeeList from "./components/client/employee/EmployeeList";

import AboutTable from "./components/admin/about/AboutTable";
import CreateAbout from "./components/admin/about/CreateAbout";
import EditAbout from "./components/admin/about/EditAbout";
import CreateTermCondition from "./components/admin/termsconditions/CreateTermsConditions";
import AboutUs from "./components/frontend/AboutUs/AboutUs";
import CleintChat from "./components/frontend/Chat/CleintChat";
import DubaiDetails from "./components/frontend/Dubai/DubaiDetails";
import Home from "./components/frontend/Home/Home";
import BarTending from "./components/frontend/MhAcademy/BarTending";
import Chief from "./components/frontend/MhAcademy/Chief";
import CourseCategoryDetails from "./components/frontend/MhAcademy/CourseCategoryDetails";
import Expensive from "./components/frontend/MhAcademy/Expensive";
import MHAcademy from "./components/frontend/MhAcademy/MHAcademy";
import CoursePaymentError from "./components/frontend/MhAcademy/PaymentError";
import CoursePaymentSuccess from "./components/frontend/MhAcademy/PaymentSuccess";
import School from "./components/frontend/MhAcademy/School";
import StudentForm from "./components/frontend/MhAcademy/StudentForm";
import Waiter from "./components/frontend/MhAcademy/Waiter";
import About from "./components/frontend/about/About";
import Blog from "./components/frontend/blog/Blog";
import BlogDetails from "./components/frontend/blog/BlogDetails";
import BookFromCalender from "./components/frontend/calender/BookFromCalender";
import EmployeeCalendarView from "./components/frontend/calender/ClientCalender";
import HireEmployeeCalender from "./components/frontend/calender/HireEmployeeCalender";
import EmployeeCalendar from "./components/frontend/calender/calender";
import CheckInCheckOut from "./components/frontend/client/CheckInCheckOut";
import Livelocation from "./components/frontend/client/LiveLocation/Livelocation";
import MyEmployee from "./components/frontend/client/MyEmployee";
import Contact from "./components/frontend/contact/Contact";
import Customer from "./components/frontend/customer/Customer";
import ClientDashboard from "./components/frontend/dashboard/ClientDashboard";
import EmployeeViewDetails from "./components/frontend/dashboard/EmployeeViewDetails";
import JobDetails from "./components/frontend/employee/jobs/JobDetails";
import EmployeeMeeting from "./components/frontend/employee/meeting/EmployeeMeeting";
import EmployeePdf from "./components/frontend/employee/pdf/EmployeePdf";
import EmployeeBookedHistory from "./components/frontend/employee/profile/EmployeeBookedHistory";
import EmployeeDashboard from "./components/frontend/employee/profile/EmployeeDashboard";
import EmployeeHiredHistory from "./components/frontend/employee/profile/EmployeeHiredHistory";
import EmployeeHome from "./components/frontend/employee/profile/EmployeeHome";
import EmployeePaymentHistoryForProfile from "./components/frontend/employee/profile/EmployeePaymentHistoryForProfile";
import EmployeeProfile from "./components/frontend/employee/profile/EmployeeProfile";
import Faq from "./components/frontend/faq/Faq";
import ForgotPassword from "./components/frontend/forgotpassword/ForgotPassword";
import PaymentError from "./components/frontend/invoicePayment/ErrorPayment";
import InvoiceDetails from "./components/frontend/invoicePayment/InvoiceDetails";
import InvoiceDetails2 from "./components/frontend/invoicePayment/InvoiceDetails2";
import PaymentInvoice from "./components/frontend/invoicePayment/PaymentInvoice";
import PaymentSuccess from "./components/frontend/invoicePayment/SuccessPayment";
import CreateJobPost from "./components/frontend/job/create/CreateJobPost";
import JobRequest from "./components/frontend/job/jobRequest/JobRequest";
import CorporateInformation from "./components/frontend/legal/CorporateInformation";
import Privacy from "./components/frontend/legal/Privacy";
import TermsOfUse from "./components/frontend/legal/TermsOfUse";
import Login from "./components/frontend/login/Login";
import Places from "./components/frontend/map/Places";
import MeetTheTeam from "./components/frontend/meetTeam/MeetTheTeam";
import Mission from "./components/frontend/mission/Mission";
import Profile from "./components/frontend/profile/profile";
import ChangeSetting from "./components/frontend/profile/setting";
import Translate from "./components/frontend/profile/translate";
import ClientRegister from "./components/frontend/register/ClientRegister";
import Payroll from "./components/frontend/services/Payroll";
import Recruiting from "./components/frontend/services/Recruiting";
import Services from "./components/frontend/services/Services";
import StrategyConsultancy from "./components/frontend/services/StrategyConsultancy";
import ShortList from "./components/frontend/shortList/ShortList";
import SuccessfullMessage from "./components/frontend/shortList/SuccessfullMessage";
import ClientMasterLayout from "./layouts/frontend/ClientMasterLayout";
import InvoiceLayout from "./layouts/frontend/InvoiceLayout";
import DashboardLayout from "./layouts/frontend/dashboard/DashboardLayout";
import TermsTable from "./components/admin/termsconditions/TermsTable";
import EditTerms from "./components/admin/termsconditions/editTerms";
import CreateRole from "./components/Dynamicdashboard/Roles/CreateRole";
import RoleList from "./components/Dynamicdashboard/Roles/AllRoles";
import CreateService from "./components/Dynamicdashboard/Service/CreateService";
import ServiceList from "./components/Dynamicdashboard/Service/ServiceList";
import CreatePermission from "./components/Dynamicdashboard/Permission/CreatePermission";
import PermissionList from "./components/Dynamicdashboard/Permission/PermissionList";
import CreateMenu from "./components/Dynamicdashboard/Menu/CreateMenu";
import MenuLists from "./components/Dynamicdashboard/Menu/MenuLists";
import MenuPermission from "./components/Dynamicdashboard/MenuPermission/MenuPermission";
import MenuPermissionList from "./components/Dynamicdashboard/MenuPermission/MenuPermissionList";
import CardValidation from "./components/frontend/paymentGateway/CardValidation";
import ComplainList from "./components/admin/complain/ComplainList";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/card-verify" element={<CardValidation />} />
          {/* <Route path="/admin-login" element={<AdminLogin />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/employee-register" element={<EmployeeRegister />} /> */}
          {/* <Route path="/client-register" element={<ClientRegister />} /> */}
          {/* <Route path="/forget-Password" element={<ForgotPassword />} /> */}
          {/* <Route path="/register-welcome" element={<RegisterWelcome />} /> */}

          <Route path="/" element={<DashboardLayout />}>
            <Route
              index
              path="client-dashboard"
              element={<ClientDashboard />}
            />
            <Route index path="setting" element={<ChangeSetting />} />
            <Route index path="profile" element={<Profile />} />
            <Route path="client-myemployee" element={<MyEmployee />} />
            <Route
              path="client-myemployee/live-location/:employee"
              element={<Livelocation />}
            />
            <Route path="dashboard-history" element={<CheckInCheckOut />} />
            <Route
              path="employee-view-details/:id"
              element={<EmployeeViewDetails />}
            />
            <Route path="short-list" element={<ShortList />} />
            <Route path="success-message" element={<SuccessfullMessage />} />

            <Route path="invoice/payment-error" element={<PaymentError />} />
            <Route
              path="invoice/payment-success"
              element={<PaymentSuccess />}
            />
            <Route path="payment-invoice" element={<PaymentInvoice />} />
            <Route path="translate" element={<Translate />} />
            <Route path="create-job" element={<CreateJobPost />} />
            <Route path="edit-job/:id" element={<CreateJobPost />} />
            <Route path="job-requests" element={<JobRequest />} />

            <Route
              path="/hiring-calender/:id"
              element={<HireEmployeeCalender />}
            />
            <Route path="/booknow/:id" element={<BookFromCalender />} />
            <Route
              path="/view-calender/:id"
              element={<EmployeeCalendarView />}
            />
            <Route path="/client-help-support" element={<CleintChat />} />
          </Route>

          {/* Invoice layout */}
          <Route path="/" element={<InvoiceLayout />}>
            <Route
              path="payment-invoice-details2/:id"
              element={<InvoiceDetails />}
            />
            <Route
              path="payment-invoice-details/:id"
              element={<InvoiceDetails2 />}
            />
          </Route>

          <Route path="/" element={<ClientMasterLayout />}>
            <Route index path="/" element={<Home />} />
            <Route path="/mh-academy" element={<MHAcademy />} />
            <Route
              path="/course-category/:id"
              element={<CourseCategoryDetails />}
            />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route path="/expensive" element={<Expensive />} />
            <Route path="/bar" element={<BarTending />} />
            <Route path="/school" element={<School />} />
            <Route
              path="/course-enroll/student-form/:id"
              element={<StudentForm />}
            />
            <Route
              path="/course/payment-success"
              element={<CoursePaymentSuccess />}
            />
            <Route
              path="/course/payment-error"
              element={<CoursePaymentError />}
            />
            <Route path="/chief" element={<Chief />} />
            <Route path="/foh" element={<Waiter />} />
            <Route path="/about" element={<About />} />
            <Route path="/about2/:slug" element={<AboutUs />} />
            {/* <Route path="/about2/" element={<AboutUs />} /> */}
            <Route path="/mh-dubai" element={<DubaiDetails />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogDetails />} />

            <Route path="/customer" element={<Customer />} />
            {/* <Route path='/career' element={<Career />} /> */}
            <Route path="/our-services" element={<Services />} />
            <Route path="/our-mission" element={<Mission />} />
            <Route path="/payroll-services" element={<Payroll />} />
            <Route path="/recruiting-services" element={<Recruiting />} />

            <Route
              path="/strategy-consultancy-services"
              element={<StrategyConsultancy />}
            />
            <Route path="/meet-the-team" element={<MeetTheTeam />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route
              path="/corporate-information"
              element={<CorporateInformation />}
            />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy" element={<Privacy />} />

            {/* //For Employee Section */}
            <Route path="/employee-home" element={<EmployeeHome />} />
            <Route path="/employee-profile" element={<EmployeeProfile />} />
            <Route path="/my-calender" element={<EmployeeCalendar />} />
            <Route path="/employee-meeting" element={<EmployeeMeeting />} />
            <Route path="/employee-pdf" element={<EmployeePdf />} />
            <Route path="/employee-dashboard" element={<EmployeeDashboard />} />
            <Route
              path="/employee-booked-history"
              element={<EmployeeBookedHistory />}
            />
            <Route
              path="/employee-hired-history"
              element={<EmployeeHiredHistory />}
            />
            <Route
              path="/employee-payment-history"
              element={<EmployeePaymentHistoryForProfile />}
            />
          </Route>

          {/* Google Map */}
          <Route path="/google-map-view" element={<Places />} />

          {/* admin routes here */}
          <Route path="/admin" element={<MasterLayout />}>
            <Route
              index
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="position"
              element={
                <PrivateRoute>
                  <Position />
                </PrivateRoute>
              }
            />
            <Route
              path="skill"
              element={
                <PrivateRoute>
                  <Skill />
                </PrivateRoute>
              }
            />
            <Route
              path="source"
              element={
                <PrivateRoute>
                  <Source />
                </PrivateRoute>
              }
            />
            <Route
              path="employee-portfolio"
              element={
                <PrivateRoute>
                  <EmployeePortfolio />
                </PrivateRoute>
              }
            />
            <Route
              path="employee-portfolio-details/:id"
              element={
                <PrivateRoute>
                  <EmployeePortfolioDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="employee-list"
              element={
                <PrivateRoute>
                  <EmployeeList />
                </PrivateRoute>
              }
            />
            <Route
              path="others-employee-list"
              element={
                <PrivateRoute>
                  <OthersEmployeeList />
                </PrivateRoute>
              }
            />
            <Route
              path="course-schedule"
              element={
                <PrivateRoute>
                  <CourseSchedule />
                </PrivateRoute>
              }
            />
            <Route
              path="employee-details/:id"
              element={
                <PrivateRoute>
                  <EmployeeDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="view-employee-details/:id"
              element={
                <PrivateRoute>
                  <ViewEmployeeDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="view-certificate/:id"
              element={
                <PrivateRoute>
                  <ViewCertificate />
                </PrivateRoute>
              }
            />
            <Route
              path="client-list"
              element={
                <PrivateRoute>
                  <ClientList />
                </PrivateRoute>
              }
            />
            <Route
              path="client-details/:id"
              element={
                <PrivateRoute>
                  <ClientDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="create-meet"
              element={
                <PrivateRoute>
                  <AddMeet />
                </PrivateRoute>
              }
            />
            <Route
              path="get-meets"
              element={
                <PrivateRoute>
                  <ViewMeet />
                </PrivateRoute>
              }
            />
            <Route
              path="view-meet/:id"
              element={
                <PrivateRoute>
                  <SingleMeet />
                </PrivateRoute>
              }
            />
            <Route
              path="booking-history"
              element={
                <PrivateRoute>
                  <BookingHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="book-for-client"
              element={
                <PrivateRoute>
                  <BookForClient />
                </PrivateRoute>
              }
            />
            <Route
              path="employee-payment-history"
              element={
                <PrivateRoute>
                  <EmployeePaymentHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="manage-review"
              element={
                <PrivateRoute>
                  <ManageReview />
                </PrivateRoute>
              }
            />
            {/* mh employee register from admin route here */}
            <Route
              path="add-mh-employee"
              element={
                <PrivateRoute>
                  <AddMHEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="mh-employee-list"
              element={
                <PrivateRoute>
                  <MHEmployeeList />
                </PrivateRoute>
              }
            />
            <Route
              path="edit-mh-employee/:id"
              element={
                <PrivateRoute>
                  <EditMHEmployee />
                </PrivateRoute>
              }
            />

            <Route
              path="contact-list"
              element={
                <PrivateRoute>
                  <ContactList />
                </PrivateRoute>
              }
            />
            <Route
              path="invoice-list"
              element={
                <PrivateRoute>
                  <InvoiceList />
                </PrivateRoute>
              }
            />
            <Route
              path="course-list"
              element={
                <PrivateRoute>
                  <CourseList />
                </PrivateRoute>
              }
            />
            <Route
              path="course-category"
              element={
                <PrivateRoute>
                  <CourseCategory />
                </PrivateRoute>
              }
            />
            <Route
              path="course-payment"
              element={
                <PrivateRoute>
                  <CoursePayment />
                </PrivateRoute>
              }
            />

            <Route
              path="course-promotion"
              element={
                <PrivateRoute>
                  <CoursePromotion />
                </PrivateRoute>
              }
            />
            <Route
              path="create-about"
              element={
                <PrivateRoute>
                  <CreateAbout />
                </PrivateRoute>
              }
            />
            <Route
              path="about-table"
              element={
                <PrivateRoute>
                  <AboutTable />
                </PrivateRoute>
              }
            />
            <Route
              path="edit-about/:slug"
              element={
                <PrivateRoute>
                  <EditAbout />
                </PrivateRoute>
              }
            />
            <Route
              path="create-terms"
              element={
                <PrivateRoute>
                  <CreateTermCondition />
                </PrivateRoute>
              }
            />
            <Route
              path="terms-table"
              element={
                <PrivateRoute>
                  <TermsTable />
                </PrivateRoute>
              }
            />
            <Route
              path="terms-edit/:id"
              element={
                <PrivateRoute>
                  <EditTerms />
                </PrivateRoute>
              }
            />
            <Route
              path="create-role"
              element={
                <PrivateRoute>
                  <CreateRole />
                </PrivateRoute>
              }
            />
            <Route
              path="role-list"
              element={
                <PrivateRoute>
                  <RoleList />
                </PrivateRoute>
              }
            />
            <Route
              path="create-service"
              element={
                <PrivateRoute>
                  <CreateService />
                </PrivateRoute>
              }
            />
            <Route
              path="create-menu"
              element={
                <PrivateRoute>
                  <CreateMenu />
                </PrivateRoute>
              }
            />
            <Route
              path="service-list"
              element={
                <PrivateRoute>
                  <ServiceList />
                </PrivateRoute>
              }
            />
            <Route
              path="menu-list"
              element={
                <PrivateRoute>
                  <MenuLists />
                </PrivateRoute>
              }
            />
            <Route
              path="add-menu-permission"
              element={
                <PrivateRoute>
                  <MenuPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="complain"
              element={
                <PrivateRoute>
                   <ComplainList/>
                </PrivateRoute>
              }
            />
            <Route
              path="menu-permission-list"
              element={
                <PrivateRoute>
                  <MenuPermissionList />
                </PrivateRoute>
              }
            />
            <Route
              path="create-permission"
              element={
                <PrivateRoute>
                  <CreatePermission />
                </PrivateRoute>
              }
            />
            <Route
              path="permission-list"
              element={
                <PrivateRoute>
                  <PermissionList />
                </PrivateRoute>
              }
            />

            <Route
              path="add-invoice"
              element={
                <PrivateRoute>
                  <AddInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="notification-list"
              element={
                <PrivateRoute>
                  <NotificationList />
                </PrivateRoute>
              }
            />
            <Route
              path="change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
          </Route>

          {/* for client routes */}
          <Route path="/client" element={<ClientMasterLayout />}>
            <Route
              path="employee-list"
              element={
                <PrivateRoute>
                  <ClientEmployeeList />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
