import { Button, Input, InputNumber, Modal, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { token } from "../../../utils/authentication";
import { useTranslation } from "react-i18next";

export default function ManageReview() {
  const {t} = useTranslation();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [ratingValue, setRatingValue] = useState(null);
  const [commentValue, setCommentValue] = useState("");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/review`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        const data = await response.json();
        setReviews(data.result);
      } catch (error) {
        console.error("Error fetching review data:", error);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  const updateReviewStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/review/update-status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify({ id, status }),
        }
      );
      setReviews((prevData) =>
        prevData.map((review) =>
          review._id === id ? { ...review, status } : review
        )
      );
    } catch (error) {
      console.error("Error updating review status:", error);
    }
  };

  const showModal = (review) => {
    setCurrentReview(review);
    setRatingValue(review.rating);
    setCommentValue(review.comment);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      // Get values from the form
      const ratingValue = document.getElementById("reviewRatingInput").value;
      const commentValue = document.getElementById(
        "reviewCommentTextarea"
      ).value;
      const updateData = {
        id: currentReview._id,
        rating: ratingValue,
        comment: commentValue,
      };
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/review/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(updateData),
      });

      setReviews((prevData) =>
        prevData.map((review) =>
          review._id === currentReview._id
            ? { ...review, ...updateData }
            : review
        )
      );

      setIsModalVisible(false);
      setCurrentReview(null);
    } catch (error) {
      console.error("Error updating review:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentReview(null);
    setRatingValue(null);
    setCommentValue("");
  };

  const columns = [
    {
      title: t("reviewer"),
      dataIndex: "reviewerName",
      //   sorter: (a, b) => a.reviewerName.localeCompare(b.reviewerName),
    },
    {
      title: t("review_for"),
      dataIndex: "reviewForName",
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      sorter: (a, b) => a.rating - b.rating,
      defaultSortOrder: "descend",
    },
    {
      title: t("comment"),
      dataIndex: "comment",
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) =>
        new Date(createdAt).toLocaleString(undefined, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
    },
    {
      title: t("updated_at"),
      dataIndex: "updatedAt",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (updatedAt) =>
        new Date(updatedAt).toLocaleString(undefined, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (_, review) => (
        <Switch
          checked={review.status}
          onChange={(checked) => updateReviewStatus(review._id, checked)}
        />
      ),
    },
    {
      title: t("action"),
      dataIndex: "action",
      render: (_, review) => (
        <Button onClick={() => showModal(review)}>{t("edit_review")}</Button>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <div>{t("loading...")}</div>
      ) : (
        <Table columns={columns} dataSource={reviews} rowKey="_id" />
      )}
      <Modal
        title={t("update_review")}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("update")}
        cancelText={t("cancel")}
        width={500}
        centered
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div>
            <label style={{ display: "block", marginBottom: "5px" }}>
            {t("rating")}
            </label>
            <InputNumber
              id="reviewRatingInput"
              min={0}
              max={5}
              value={ratingValue}
              onChange={setRatingValue}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <label style={{ display: "block", marginBottom: "5px" }}>
            {t("comment")}
            </label>
            <Input.TextArea
              id="reviewCommentTextarea"
              placeholder={t("enter_your_comment")}
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
