import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import axios from "axios";
import _, { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchEmployeeListHandler,
  useFetchEmployeeList,
  userDeleteHandler,
} from "../../../api/employee";
import { fetchPositionListForDropdownHandler } from "../../../api/position/index.js";
import { token } from "../../../utils/authentication";
import { getPage } from "../../../utils/getPage";
import { responseNotification } from "../../../utils/notifcation";
import { donwloadCSV } from "../../../utils/static/donwloadCSV.js";
import Loader from "../../loadar/Loader";
import { getUserInfo } from "../../../utils/commonFunctions.js";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

function OtherEmployeeList() {
  const { t } = useTranslation();
  const loc = useLocation();
  const userInfo = getUserInfo();
  const [limit] = useState(10);
  const [getEmployee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getName, setName] = useState(undefined);
  const [getStatus, setStatus] = useState(undefined);
  const [getCountryName, setCountryName] = useState("OTHERS");
  const [getFilterFromDate, setFilterFromDate] = useState(undefined);
  const [getFilterToDate, setFilterToDate] = useState(undefined);
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterPosition, setFilterPosition] = useState(undefined);
  const [positions, setPositions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(false);
  const [total, setTotal] = useState(0);

  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      title: "#",
      dataIndex: "key",
    },
    {
      title: t("registration_date"),
      dataIndex: "createdAt", // Add the Registration Date (createdAt) here
      render: (text) => {
        return moment(text)?.format("YYYY-MM-DD");
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone_number"),
      dataIndex: "phoneNumber",
    },
    {
      title: t("employee_id"),
      dataIndex: "userIdNumber",
    },
    {
      title: t("country_name"),
      dataIndex: "countryName",
    },
    {
      title: t("profile_completed"),
      dataIndex: "profileCompleted",
    },
    {
      title: t("password"),
      dataIndex: "plainPassword",
    },
    // {
    //   title: "Active",
    //   dataIndex: "active",
    //   sorter: (a, b) => a.active.length - b.active.length,
    // },
    {
      title: t("position"),
      dataIndex: "position",
    },
    {
      title: t("mh_certified"),
      dataIndex: "certified",
    },
    {
      title: t("status"),
      dataIndex: "status",
    },
    {
      title: t("action"),
      dataIndex: "action",
    },
  ];

  const handleSearch = () => {
    // Perform search functionality here using the searchText state
    // console.log("Searching for:", searchText);
    setName(searchText);
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  };

  const navigate = useNavigate();

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/admin/others-employee-list?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  // const fetchEmployee = useCallback(async () => {
  //   setLoading(true);

  //   await fetchEmployeeListHandler(
  //     limit,
  //     getName,
  //     getStatus,
  //     getCountryName,
  //     loc?.search,
  //     filterPosition,
  //     currentPage,
  //   ).then((res) => {
  //     if (res?.status === 200) {
  //       setLoading(false);
  //       setEmployee(res?.data?.users);
  //       console.log(res?.data);
  //       setTotal(res?.data?.total);
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // }, [
  //   limit,
  //   loc?.search,
  //   getName,
  //   getStatus,
  //   getCountryName,
  //   filterPosition,
  //   currentPage,
  // ]);

  // useEffect(() => {
  //   fetchEmployee();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchEmployee, getPage(loc.search)]);

  const {
    isLoading,
    data: responseData,
    error,
  } = useFetchEmployeeList(
    limit,
    getName,
    getStatus,
    getCountryName,
    loc?.search,
    filterPosition,
    currentPage,
  );

  useEffect(() => {
    if (!isLoading && responseData) {
      setLoading(false);
      setEmployee(responseData?.users);
      setTotal(responseData?.total);
    }
  }, [isLoading, responseData]);

  //search
  const handleSearchkeywordOnChange = (e) => {
    debounce(() => {
      setName(e?.target?.value);
    }, 1000)();
  };
  // position list
  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPositions(res?.data?.positions);
    });
  }, []);

  useEffect(() => {
    fetchPositionData();
  }, []);

  // const data1 = [];
  // _.map(getEmployee, (item, index) => {
  //   let profileCompletedPercentage = 0;
  //   if (item?.firstName && item?.lastName) profileCompletedPercentage += 10;
  //   if (item?.positionId) profileCompletedPercentage += 10;
  //   if (item?.gender) profileCompletedPercentage += 5;
  //   if (item?.presentAddress) profileCompletedPercentage += 5;
  //   if (item?.emmergencyContact) profileCompletedPercentage += 5;
  //   if (item?.profilePicture) profileCompletedPercentage += 10;
  //   if (item?.bankName) profileCompletedPercentage += 10;
  //   if (item?.cv) profileCompletedPercentage += 10;
  //   if (item?.countryName) profileCompletedPercentage += 5;
  //   if (item?.higherEducation) profileCompletedPercentage += 5;
  //   if (item?.dateOfBirth) profileCompletedPercentage += 5;
  //   if (item?.phoneNumber) profileCompletedPercentage += 5;
  //   if (_.size(item?.skills)) profileCompletedPercentage += 5;
  //   if (_.size(item?.languages)) profileCompletedPercentage += 5;
  //   if (item?.employeeExperience) profileCompletedPercentage += 5;

  //   let countryName = "";

  //   if (item?.countryName === "United Kingdom") {
  //     countryName = "United Kingdom";
  //   } else if (item?.countryName === "United Arab Emirates") {
  //     countryName = "United Arab Emirates";
  //   } else {
  //     countryName = "Others";
  //   }

  //   data1.push({
  //     key: index + 1,
  //     name: item?.firstName + " " + item?.lastName,
  //     email: item?.email,
  //     phoneNumber: item?.phoneNumber,
  //     userIdNumber: item?.userIdNumber,
  //     countryName: countryName,
  //     profileCompleted: (
  //       <>
  //         <div className="text-center">{profileCompletedPercentage}%</div>
  //       </>
  //     ),
  //     plainPassword: item?.plainPassword,
  //     active: item.active ? "YES" : "NO",
  //     certified: (
  //       <>
  //         <Switch
  //           size="small"
  //           defaultChecked={item?.certified === true}
  //           onChange={(e) => {
  //             onEmployeeCertifiedChange(item?._id, e);
  //           }}
  //         />
  //       </>
  //     ),
  //     status: (
  //       <>
  //         <Switch
  //           size="small"
  //           defaultChecked={item?.active === true}
  //           onChange={(e) => {
  //             onEmployeeStatusChange(item?._id, e);
  //           }}
  //         />
  //       </>
  //     ),
  //     action: (
  //       <>
  //         <div className="btn-group">
  //           <Link
  //             to={`/admin/view-employee-details/${item._id}`}
  //             style={{ background: "#C6A34F", color: "white" }}
  //             className="btn btn-sm"
  //           >
  //             View
  //           </Link>
  //           <Link
  //             to={`/admin/employee-details/${item._id}`}
  //             className="btn btn-sm btn-warning"
  //           >
  //             Edit
  //           </Link>
  //         </div>
  //       </>
  //     ),
  //   });
  // });

  const data1 = [];
  _.map(getEmployee, (item, index) => {
    let profileCompletedPercentage = 0;
    if (item?.firstName && item?.lastName) profileCompletedPercentage += 10;
    if (item?.positionId) profileCompletedPercentage += 10;
    if (item?.gender) profileCompletedPercentage += 5;
    if (item?.presentAddress) profileCompletedPercentage += 5;
    if (item?.emmergencyContact) profileCompletedPercentage += 5;
    if (item?.profilePicture) profileCompletedPercentage += 10;
    if (item?.bankName) profileCompletedPercentage += 10;
    if (item?.cv) profileCompletedPercentage += 10;
    if (item?.countryName) profileCompletedPercentage += 5;
    if (item?.higherEducation) profileCompletedPercentage += 5;
    if (item?.dateOfBirth) profileCompletedPercentage += 5;
    if (item?.phoneNumber) profileCompletedPercentage += 5;
    if (_.size(item?.skills)) profileCompletedPercentage += 5;
    if (_.size(item?.languages)) profileCompletedPercentage += 5;
    if (item?.employeeExperience) profileCompletedPercentage += 5;

    let countryName = "";

    if (item?.countryName === "United Kingdom") {
      countryName = "United Kingdom";
    } else if (item?.countryName === "United Arab Emirates") {
      countryName = "United Arab Emirates";
    } else if (item?.countryName === "Italy") {
      countryName = "Italy";
    } else {
      countryName = "Others";
    }

    data1.push({
      key: index + 1,
      name: item?.firstName + " " + item?.lastName,
      email: item?.email,
      phoneNumber: item?.phoneNumber,
      userIdNumber: item?.userIdNumber,
      countryName: countryName,
      profileCompleted: (
        <>
          <div className="text-center">{profileCompletedPercentage}%</div>
        </>
      ),
      plainPassword: item?.plainPassword,
      active: item.active ? "YES" : "NO",
      certified: (
        <>
          <Switch
            size="small"
            defaultChecked={item?.certified === true}
            onChange={(e) => {
              onEmployeeCertifiedChange(item?._id, e);
            }}
          />
        </>
      ),
      position: item?.positionName,
      status: (
        <>
          <Switch
            size="small"
            defaultChecked={item?.active === true}
            onChange={(e) => {
              onEmployeeStatusChange(item?._id, e);
            }}
          />
        </>
      ),
      createdAt: item?.createdAt, // Add the createdAt field here
      action: (
        <>
          <div className="btn-group">
            <Link
              to={`/admin/view-employee-details/${item._id}`}
              style={{ background: "#C6A34F", color: "white" }}
              className="btn btn-sm"
            >
              {t("view")}
            </Link>
            <Link
              to={`/admin/employee-details/${item._id}`}
              className="btn btn-sm btn-warning"
            >
              {t("edit")}
            </Link>
            <Button
              onClick={() => {
                setSelectedEmployee(item?._id);
              }}
              className="btn btn-sm btn-danger"
            >
              {t("delete")}
            </Button>
          </div>
        </>
      ),
    });
  });

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const handleChangeCountryName = (value) => {
    setCountryName(value);
  };

  const onEmployeeStatusChange = useCallback(
    async (value, e) => {
      const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;
      const status = e === true ? true : false;
      const id = value;

      if (true) {
        await fetch(`${unicodeUri}/users/update-status`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            active: status,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res?.statusCode === 200) {
              responseNotification(
                "Employee status updated successfully",
                "success",
              );
              // fetchEmployee();
            } else if (res?.statusCode === 400) {
              responseNotification("Bad request", "danger");
            }
          });
      }
    },
    // [fetchEmployee],
    [],
  );

  const onEmployeeCertifiedChange = useCallback(
    async (value, e) => {
      const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;
      const certified = e === true ? true : false;
      const id = value;

      if (true) {
        await fetch(`${unicodeUri}/users/update-certified`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            certified: certified,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res?.statusCode === 200) {
              responseNotification(
                "Employee status updated successfully",
                "success",
              );
              // fetchEmployee();
            } else if (res?.statusCode === 400) {
              responseNotification("Bad request", "danger");
            }
          });
      }
    },
    // [fetchEmployee],
    [],
  );

  const handleExportData = async () => {
    try {
      const responseData = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users?skipLimit=YES&requestType=EMPLOYEE` +
          (getFilterFromDate ? `&fromDate=${getFilterFromDate}` : ``) +
          (getFilterToDate ? `&toDate=${getFilterToDate}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        },
      );

      const data = responseData?.data?.users?.map((item) => {
        return {
          FirstName: item?.firstName,
          LastName: item?.lastName,
          Email: item?.email,
          PhoneNumber: item?.phoneNumber,
          Position: item?.positionName,
          Gender: item?.gender,
          DateOfBirth: item?.dateOfBirth,
          PresentAddress: item?.presentAddress,
          PermanentAddress: item?.permanentAddress,
          CountryName: item?.countryName,
          HigherEducation: item?.higherEducation,
          LicensesNo: item?.licensesNo,
          EmmergencyContact: item?.emmergencyContact,
          EmployeeExperience: item?.employeeExperience,
          PerHourRate: item?.hourlyRate,
          Languages: item?.languages,
        };
      });

      donwloadCSV(data, "Employee List");
    } catch (error) {}
  };

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="title mb-4">{t("employee_list")}</h3>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="col-12">
            <div className="row">
              <div className="col-11">
                <Row gutter={[8, 8]}>
                  <Col
                    sm={24}
                    md={12}
                    lg={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      placeholder={t("enter_name")}
                      allowClear
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleSearchkeywordOnChange}
                    />
                    <Button
                      style={{
                        background: "#C6A34F",
                        marginLeft: "-10px",
                        color: "white",
                      }}
                      size="large"
                      onClick={handleSearch}
                    >
                      {t("search")}
                    </Button>
                  </Col>
                  <Col sm={24} md={12} lg={4}>
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      allowClear={true}
                      optionFilterProp="children"
                      value={filterPosition}
                      showSearch={true}
                      placeholder={t("select_position")}
                      onChange={(value) => {
                        setFilterPosition(value);
                      }}
                    >
                      {positions?.map((item, index) => (
                        <Select key={index} value={item?._id}>
                          {item?.name}
                        </Select>
                      ))}
                    </Select>
                  </Col>
                  <Col sm={24} md={12} lg={2}>
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      allowClear
                      showSearch={true}
                      placeholder={t("active")}
                      onChange={handleChangeStatus}
                    >
                      <Option value="YES">YES</Option>
                      <Option value="NO">NO</Option>
                    </Select>
                  </Col>
                  <Col sm={24} md={12} lg={4}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      id="fromDate"
                      placeholder={t("from_date")}
                      onChange={(value) => {
                        setFilterFromDate(
                          moment(value).format("YYYY-MM-DD").valueOf(),
                        );
                      }}
                    />
                  </Col>
                  <Col sm={24} md={12} lg={4}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      id="toDate"
                      placeholder={t("to_date")}
                      onChange={(value) => {
                        setFilterToDate(
                          moment(value).format("YYYY-MM-DD").valueOf(),
                        );
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="col-1">
                <button
                  style={{ background: "#C6A34F", color: "white" }}
                  onClick={handleExportData}
                  className="btn float-end"
                >
                  {t("export")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="m-2">
            <Table
              columns={columns}
              dataSource={data1}
              pagination={{
                pageSize: Number(pageSize),
                current: Number(currentPage),
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setpageSize(pageSize);
                  navigate(`?page=${page}&limit=${pageSize}`);
                },
                total: total,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        title={t("are_you_sure_to_delete_this_employee")}
        open={selectedEmployee}
        cancelText={t("no")}
        okText={t("yes")}
        onOk={async () => {
          try {
            await userDeleteHandler(selectedEmployee).then((res) => {
              if (res?.status === 200) {
                responseNotification(
                  t("employee_deleted_successfully"),
                  "success",
                );
                setSelectedEmployee(false);
                // fetchEmployee();
              } else {
                responseNotification(t("something_went_wrong"), "danger");
                setSelectedEmployee(false);
              }
            });
          } catch (error) {}
        }}
        onCancel={() => {
          setSelectedEmployee(false);
        }}
      >
        <p>{t("this_will_delete_the_employee_permanently")}</p>
      </Modal>
    </div>
  );
}

export default OtherEmployeeList;
