// MediaCarousel.jsx

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./MediaCarousel.css";

const MediaCarousel = ({ mediaData }) => {
  const assetsFrontendUrl = process.env.REACT_APP_ASSETS_FRONTEND_URL || "";

  const carouselSettings = {
    infiniteLoop: false,
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    autoPlay: true,
    showArrows: true,
  };

  return (
    <Carousel {...carouselSettings}>
      {mediaData?.map((media, index) => (
        <div key={index} className="media-container">
          {media.type === "image" && (
            <>
              <img
                src={`${assetsFrontendUrl}/${media.link}`}
                alt={media.title}
                className="media-image"
              />
             {
                media?.title && <div className="title-overlay">{media?.title}</div>
             }
            </>
          )}
          {media.type === "video" && (
            <div className="video-container">
              <video
                autoPlay
                controls={true}
                // width="100%"
                // height="400px"
                style={{ maxWidth: "100%" }}
              >
                <source
                  src={`${assetsFrontendUrl}/${media.link}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              {media?.title && (
                <div className="title-overlay">{media?.title}</div>
              )}
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default MediaCarousel;
