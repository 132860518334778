import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Popconfirm, Space, Switch, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { token } from "../../../utils/authentication";
const AboutTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  console.log("Token:", token);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/about-us?page=1&limit=10`,
        {
          headers: headers,
        }
      );
      setData(response.data.aboutus);
      //   console.log(response.data.aboutus);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Fetch data on component mount

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/about-us/delete/${id}`,
        {
          headers: headers,
        }
      );
      message.success("Record deleted successfully");
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting record:", error);
      message.error("Error deleting record");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (id, active) => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/about-us/update`,
        {
          id: id,
          active: active,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Record updated successfully");
      toast.success("Record updated successfully");
      fetchData();
    } catch (error) {
      console.error("Error updating record:", error);
      message.error("Error updating record");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Country Name",
      dataIndex: "countryName",
      key: "countryName",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => handleUpdate(record._id, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/about2/${record.slug}`} target="_blank">
            <EyeOutlined style={{ color: "#C6A34E" }} />
          </Link>{" "}
          <Link to={`/admin/edit-about/${record.slug}`}>
            <EditOutlined style={{ color: "green" }} />
          </Link>{" "}
          <Popconfirm
            title="Are you sure to delete this About Record?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={{ pageSize: 10 }}
      style={{
        padding: "20px",
        borderRadius: "10px",
      }}
    />
  );
};

export default AboutTable;
