import { Modal } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FiPhoneOutgoing } from "react-icons/fi";
import { IoPersonSharp } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { token } from "../../../../utils/authentication";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import Loader from "../../../loadar/Loader";
import { io } from "socket.io-client";
import AllJobs from "../jobs/AllJobs";
import "./EmployeeProfile.css";
import booked from "./booked.png";
import calendar from "./calander.png";
import dashboard from "./dashboard.png";
import help from "./help.png";
import hired from "./hired.png";
import payment from "./payment.png";
import { useTranslation } from "react-i18next";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import moment from "moment";
import NewChat from "../../client/NewChat";
import { getUserId } from "../../../../utils/commonFunctions";
import notificationSound from "../../../../layouts/frontend/dashboard/Notification/notification-sound.mp3";
import { responseNotification } from "../../../../utils/notifcation";

const EmployeeProfile = () => {
  const { t } = useTranslation();
  const jwt_decode = jwtTokenDecode();
  const id = jwt_decode?._id;
  const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meetData, setMeetData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unavailableDatesFromAPI, setUnavailableDatesFromAPI] = useState([]);
  const [bookedHistory, setBookedHistory] = useState([]);
  const [EmployeeHiredHistory, setEmployeeHiredHistory] = useState([]);
  const [EmployeePaymentHistory, setEmployeePaymentHistory] = useState([]);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [tempUserInfo, setTempUserInfo] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/meet/get-my-meets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        // console.log(res.data[0]);
        setMeetData(res.data[0]);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    try {
      axios
        .get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/check-in-check-out-histories/today-work-place?currentDate=${moment().format(
            "YYYY-MM-DD"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setUserInfo({
            _id: res?.data?.result?._id,
            employeeId: res?.data?.result?.restaurantDetails?.hiredBy,
            name: res?.data?.result?.restaurantDetails?.restaurantName,
          });
          setTempUserInfo({
            _id: res?.data?.result?._id,
            employeeId: res?.data?.result?.restaurantDetails?.hiredBy,
            name: res?.data?.result?.restaurantDetails?.restaurantName,
          });
          const payload = {
            senderId: getUserId(),
            receiverId: res?.data?.result?.restaurantDetails?.hiredBy,
            bookedId : res?.data?.result?._id,
            isAdmin: false,
          };
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token()}`,
                },
              }
            )
            .then((res) => {
              const conversationId = res.data.details._id;
              axios
                .get(
                  `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token()}`,
                    },
                  }
                )
                .then((res) => {
                  setRestaurantMessage(res.data.details.count);
                });
            });
        });
    } catch (err) {
      console.log(err);
    }
  }, [isModalOpen]);

  // console.log(getSingleEmployeeDetails);

  //Fetch refer person list for dropdown
  const fetchSingleEmployeeData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSingleEmployeeDetails(res?.data?.details);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSingleEmployeeData();
  }, [id]);
  const fetchData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/working-history/${id}`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      console.log(data);

      // Existing code for unavailableDates
      const unavailableDatesFromAPI = data.result.unavailableDates.map(
        (dateRange) => ({
          from: new Date(dateRange.startDate),
          to: new Date(dateRange.endDate),
        })
      );
      setUnavailableDatesFromAPI(unavailableDatesFromAPI);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  // Function to calculate the total interval count
  const getTotalIntervalCount = (dateRanges) => {
    let totalIntervals = 0;

    dateRanges.forEach((range) => {
      const fromDate = new Date(range.from);
      const toDate = new Date(range.to);

      // Calculate the difference in milliseconds
      const interval = toDate - fromDate;

      // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
      const intervalInDays = interval / (24 * 60 * 60 * 1000);

      // Add the interval count to the total
      totalIntervals += intervalInDays + 1; // Adding 1 to include the last day
    });

    return totalIntervals;
  };

  // Get the total interval count
  const totalUnavailable = getTotalIntervalCount(unavailableDatesFromAPI);

  // console.log(totalUnavailable);
  // console.log(unavailableDatesFromAPI);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/notifications/details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('received data: ', data.details);
        setBookedHistory(data.details);
      });
  }, []);
  // console.log(bookedHistory);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/hired-history`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEmployeeHiredHistory(data?.bookedHistory);
      });
  }, []);
  // console.log(EmployeeHiredHistory);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/employee-invoices?employeeId=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        const data = await response.json();
        console.log(data);
        // Count the number of invoices with status "DUE"
        const dueCount = data?.invoices.filter(
          (invoice) => invoice.status === "DUE"
        ).length;
        setEmployeePaymentHistory(dueCount);
      } catch (error) {
        // console.error("Error fetching invoice data:", error);
      }

      setLoading(false);
    };

    fetchData();
  }, [id]);

  const [message, setMessage] = useState(0);
  const [restaurantMessage, setRestaurantMessage] = useState(0);
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        const conversationId = res.data.details._id;
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((res) => {
            setMessage(res.data.details.count);
          });
      });
  }, []);

  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    withCredentials: false,
    transports: ["websocket", "polling", "flashsocket"],
  });
  useEffect(() => {
    socket.on("new_message", (data) => {
      var currentUrl = window.location.href;
      // CHECK IF THE MESSAGE IS FROM THE ADMIN
      if (
        data.message.receiverDetails?.length > 1 &&
        data.message.receiverDetails
          .map((item) => item.receiverId)
          .includes(getUserId()) &&
        !currentUrl.includes("/client-help-support")
      ) {
        const message = `Admin: ${data.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
        return;
      }

      // CHECK IF THE MESSAGE IS FROM THE USER
      if (data.message.receiverDetails[0].receiverId === getUserId()) {
        const message = `${data.message.senderDetails.name} : ${data.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
      }
    });

    return () => {
      socket.off("new_message");
    };
  }, []);

  let profileCompletedPercentage = 0;
  if (getSingleEmployeeDetails?.firstName && getSingleEmployeeDetails?.lastName)
    profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.positionId) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.gender) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.presentAddress) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.emmergencyContact)
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.profilePicture)
    profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.bankName) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.cv) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.countryName) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.higherEducation)
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.dateOfBirth) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.phoneNumber) profileCompletedPercentage += 5;
  if (_.size(getSingleEmployeeDetails?.skills)) profileCompletedPercentage += 5;
  if (_.size(getSingleEmployeeDetails?.languages))
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.employeeExperience)
    profileCompletedPercentage += 5;

  const expireTime = new Date(meetData?.expiredTime).getTime();
  const currentTime = new Date().getTime();
  const isExpired = currentTime > expireTime;
  // console.log("isExpired: ", isExpired);
  if (loading) {
    return (
      <h1>
        <Loader />
      </h1>
    );
  }
  return (
    <div className="container mx-auto">
      <section className="m-2">
        <div className="row">
          <div
            className="col-md-3 card"
            style={{
              display: "flex !important",
              justifyContent: "center",
              alignItems: "left",
              borderRadius: "14.8px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              backgroundColor: " #F6F1E5",
            }}
          >
            <div className="row">
              <div className="col-md-3">
                {getSingleEmployeeDetails.profilePicture && (
                  <img
                    src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleEmployeeDetails.profilePicture}`}
                    alt="Profile"
                    style={{
                      width: "100px", // Adjust the width as needed
                      height: "100px", // Adjust the height as needed
                      borderRadius: "50%", // Makes it round
                    }}
                  />
                )}
              </div>
              <div className="col-md-9 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    marginLeft: "10px",
                    lineHeight: "0.9",
                  }}
                >
                  <p className="fw-bold">
                    Hi, {getSingleEmployeeDetails?.name}
                  </p>
                  <p className="fw-bold text-muted">
                    <IoPersonSharp
                      style={{
                        fontSize: "20px",
                        marginRight: "5px",
                        marginTop: "-6px",
                      }}
                    />
                    {getSingleEmployeeDetails?.positionName}
                  </p>
                </div>
              </div>
            </div>
            <p className="mt-2">{t("explore_the_features_of_mh_app_here")}</p>
          </div>

          <div
            className="col-md-9"
            style={{
              marginLeft: "",
            }}
          >
            <div
              className="row row-cols-1 row-cols-md-2 row-cols-lg-3 p-3"
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "14.8px",
                marginLeft: "10px",
              }}
            >
              {/* Use Bootstrap grid classes for column layout and spacing */}
              <div className="col-lg-2">
                <Link to={`/employee-dashboard`} className="customCardLink">
                  <div className="customCard">
                    <img src={dashboard} alt="dashboard" />
                    <p>{t("dashboard")}</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link
                  to={`/employee-booked-history`}
                  className="customCardLink"
                >
                  <div className="customCard">
                    {bookedHistory?.length > 0 && (
                      <span className="resultCount">
                        {bookedHistory?.length || 0}
                      </span>
                    )}
                    <img src={booked} alt="booked" />
                    <p>{t("booked_history")}</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link to={`/employee-hired-history`} className="customCardLink">
                  <div className="customCard">
                    {EmployeeHiredHistory?.length > 0 && (
                      <span className="resultCount">
                        {EmployeeHiredHistory?.length || 0}
                      </span>
                    )}
                    <img src={hired} alt="" />
                    <p>{t("hired_history")}</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link to={`/my-calender`} className="customCardLink">
                  <div className="customCard">
                    {totalUnavailable > 0 && (
                      <span className="resultCount">{totalUnavailable}</span>
                    )}
                    <img src={calendar} alt="calendar" />
                    <p>{t("calendar")}</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link
                  to={`/employee-payment-history`}
                  className="customCardLink"
                >
                  <div className="customCard">
                    {/* old  */}
                    {/* {EmployeePaymentHistory > 0 && (
                      <span className="resultCount">
                        {EmployeePaymentHistory}
                      </span>
                    )} */}
                    <img src={payment} alt="payment" />
                    <p>{t("payment_history")}</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <div
                  onClick={showModal}
                  className="customCardLink"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="customCard">
                    <img src={help} alt="help" />
                    <p>{t("help_support")}</p>
                  </div>
                  {message > 0 ? (
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "15px",
                        backgroundColor: "#FF0000",
                        color: "white",
                        borderRadius: "50%",
                        padding: message <= 9 ? "0px 8px" : "4px 7px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {message + restaurantMessage}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* job section */}
      <AllJobs />

      <Modal
        title={t("help_support")}
        okButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelText={t("close")}
        cancelButtonProps={{
          style: { backgroundColor: "#c6a34f", color: "white" },
        }}
      >
        <div className="col-lg-12 mb-4">
          {/* <div className="row"></div> */}
          <div className="socialMediaIcons">
            <a
              target="_blank"
              href="https://www.facebook.com/RecruitmentMirkoHospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116168.png"
                alt="image"
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/recruitmentmirkohospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116169.png"
                alt="image"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/mirko-hospitality/mycompany/?viewAsMember=true"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116170.png"
                alt="image"
              />
            </a>
            <a
              href="https://vm.tiktok.com/ZGJmndX98/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116171.png"
                alt="image"
              />
            </a>
          </div>
          <div>
            <div className="d-flex mt-4">
              <a href="tel:+4407500146699">
                <FiPhoneOutgoing
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>{t("reservation")}</p>
                <h5 style={{ fontSize: "20px", color: "#8e6d45" }}>
                  +44 75 001 46699
                </h5>
              </div>
            </div>
            <div className="d-flex mt-4">
              <a href="mailto:info@mhpremierstaffingsolutions.com">
                <TfiEmail
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>{t("email_info")}</p>
                <h5 style={{ fontSize: "15px", color: "#8e6d45" }}>
                  info@mhpremierstaffingsolutions.com
                </h5>
              </div>
            </div>
          </div>
          <div>
            {tempUserInfo?.employeeId ? (
              <div className="d-flex mt-4">
                <div
                  className="d-flex"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleCancel();
                    setOpen(true);
                  }}
                >
                  <BsFillChatLeftTextFill
                    style={{
                      color: "#C6a34f",
                      fontSize: "45px",
                    }}
                  />
                  <div className="mx-5">
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Chat With {tempUserInfo?.name} - {restaurantMessage}
                    </p>
                    <h5
                      style={{
                        fontSize: "20px",
                        color: "#8e6d45",
                        textDecoration: "none",
                      }}
                    >
                      Live Chat Now with Restaurant 
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="d-flex mt-4">
              <Link to="/client-help-support">
                <div className="d-flex">
                  <BsFillChatLeftTextFill
                    style={{
                      color: "#C6a34f",
                      cursor: "pointer",
                      fontSize: "45px",
                    }}
                  />
                  <div className="mx-5">
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Chat Now - {message}
                    </p>
                    <h5
                      style={{
                        fontSize: "20px",
                        color: "#8e6d45",
                        textDecoration: "none",
                      }}
                    >
                      Live Chat Now with our team
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Modal>

      <NewChat
        setOpen={setOpen}
        open={open}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
    </div>
  );
};

export default EmployeeProfile;
