import { Result } from "antd";
import React, { useEffect } from "react";
import { decryptData } from "../../../utils/secure-stoarage";

const CoursePaymentError = () => {
  const data = localStorage.getItem("regData");
  const payload = data && decryptData(data);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (payload && Object.keys(payload).length > 0) {
        localStorage.removeItem("regData");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="container">
      <div className="card my-5 py-5">
        <Result
          status="warning"
          title="There are some problems with your operation."
          subTitle="We apologize, but it seems there was an issue with your purchase. 😔 Please review your information and try again. If the problem persists, don't hesitate to contact our support team for assistance. We're here to help you resolve any issues and ensure your shopping experience is smooth and enjoyable. Thank you for your patience!"
        />
      </div>
    </div>
  );
};

export default CoursePaymentError;
