import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const DynamicCarousel2 = ({ items, slidesToShow, autoplaySpeed }) => {
  const shouldAutoplay = items.length > 4;
  const [dynamicSlidesToShow, setDynamicSlidesToShow] = useState(
    slidesToShow || 4
  );

  const settings = {
    infinite: true,
    slidesToShow: dynamicSlidesToShow,
    slidesToScroll: 1,
    autoplay: shouldAutoplay,
    autoplaySpeed: autoplaySpeed || 2000,
    centerMode: shouldAutoplay && dynamicSlidesToShow === 1,
    arrows: true,
    prevArrow: (
      <button type="button" className="slick-prev">
        Previous
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        Next
      </button>
    ),
  };

  const updateSlidesToShow = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 576) {
      setDynamicSlidesToShow(1);
    } else if (screenWidth <= 768) {
      setDynamicSlidesToShow(2);
    } else if (screenWidth <= 992) {
      setDynamicSlidesToShow(3);
    } else {
      setDynamicSlidesToShow(4);
    }
  };

  useEffect(() => {
    updateSlidesToShow();

    const handleResize = () => {
      updateSlidesToShow();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Slider
      {...settings}
      style={{
        display: "flex",
      }}
    >
      {items.map((item, index) => (
        <div key={index} className="carousel-item">
          {item}
        </div>
      ))}
    </Slider>
  );
};

export default DynamicCarousel2;
