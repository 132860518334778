import React from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";
import { HiMiniBellAlert } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

function ClientMasterLayout() {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="text-center text-white "
        style={{
          backgroundColor: "#c6a34f",
        }}
      >
        <p>
          <HiMiniBellAlert
            style={{
              height: "20px",
              width: "20px",
              marginRight: "5px",
            }}
          />
          Explore our new website
          <Link
            to="https://plagit.com/"
            target="_blank"
            rel="noreferrer"
            className="btn btn-sm"
          >
            <span
              style={{
                color: "#ffffff",
                textDecoration: "underline",
                fontWeight: "bold",
                // marginLeft: "5px",
              }}
            >
              {t("visit_here")}
            </span>
          </Link>
        </p>
      </div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default ClientMasterLayout;
